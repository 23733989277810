import { DomClassList } from '@bryntum/schedulerpro';
import { addDays, addHours, startOfDay, subDays, subHours } from 'date-fns';

import { PauseEntity, WbsSectionEntity } from '@/common/types';
import { Collision } from '@/features/collisions';
import { getSectionChildren, getSectionIndentation } from '@/features/projectStructure';
import { getNextWeekDay } from '@/helpers/utils/dates';
import { NodeName } from '@/repositories/utils/cache';
import { HolidayFragment } from '@/services/store/holiday/types';
import {
  getCollisionResourceId,
  getEmptyResourceId,
  getMainResourceId,
  schedulerClassConfig,
} from '@/services/store/schedule/parsers/base';
import { PauseEventParser } from '@/services/store/schedule/parsers/pause';
import { SchedulerRecurrentTimeRange } from '@/services/store/schedule/types';

export class TimeRangeParser {
  public parseRecurringWeekends(): SchedulerRecurrentTimeRange {
    const saturday = getNextWeekDay(startOfDay(new SchedulingDate(0)), 6);
    const monday = startOfDay(addDays(saturday, 2));
    const recurringWeekend = {
      id: 'weekends-recurring',
      startDate: saturday,
      endDate: monday,
      name: '',
      recurrenceRule: 'FREQ=WEEKLY',
      cls: new DomClassList([this.nonWorkingTimeRangeClass]),
    };

    return recurringWeekend;
  }

  public parseFromPause(pause: PauseEntity): SchedulerRecurrentTimeRange {
    const pauseParser = new PauseEventParser();
    return {
      ...pauseParser.entityToModel(pause),
      resourceId: '',
      name: '',
      cls: new DomClassList([this.nonWorkingTimeRangeClass]),
    };
  }

  public parseFromHoliday(holiday: HolidayFragment): SchedulerRecurrentTimeRange {
    return {
      id: holiday.id,
      name: new SchedulingDate(holiday.date).getDate().toString(),
      startDate: new SchedulingDate(holiday.date),
      endDate: addDays(new SchedulingDate(holiday.date), 1),
      cls: new DomClassList([schedulerClassConfig[NodeName.HOLIDAY]]),
    };
  }

  public parseFromTodayDate(): SchedulerRecurrentTimeRange {
    return {
      id: 'today-date',
      name: startOfDay(new Date()).getDate().toString(),
      startDate: startOfDay(new SchedulingDate()),
      endDate: addDays(startOfDay(new SchedulingDate()), 1),
      cls: new DomClassList(['today-time-range']),
    };
  }

  public parseFromCollision(
    collision: Collision,
    isHourly: boolean = false,
  ): SchedulerRecurrentTimeRange {
    let startDate: Date;
    let endDate: Date;

    if (collision.type === 'start') {
      startDate = collision.date;
      endDate = isHourly ? addHours(collision.date, 1) : addDays(collision.date, 1);
    } else {
      // Collision date is exclusive, when an end date is affected
      startDate = isHourly ? subHours(collision.date, 1) : subDays(collision.date, 1);
      endDate = collision.date;
    }
    return {
      id: collision.dependencyId,
      name: '',
      startDate,
      endDate,
      resourceId: collision.wbsSectionId || getMainResourceId(),
      cls: new DomClassList(['schedule-collision-cell']),
    };
  }

  public parseFromScheduleContext(
    sections: WbsSectionEntity[] | Map<string, WbsSectionEntity>,
    markerData: { resourceId: string; startDate: Date; endDate: Date },
  ): SchedulerRecurrentTimeRange | null {
    let cls: string;
    if (
      markerData.resourceId === getCollisionResourceId() ||
      markerData.resourceId === getEmptyResourceId()
    ) {
      return null;
    }
    if (markerData.resourceId === getMainResourceId()) {
      cls = 'schedule-context-marker --light';
    } else {
      const isMidLevel1 =
        markerData.resourceId &&
        getSectionChildren(sections, markerData.resourceId).length &&
        getSectionIndentation(sections, markerData.resourceId) === 1;
      const isBottomLevel = !getSectionChildren(sections, markerData.resourceId).length;

      cls = `schedule-context-marker ${isMidLevel1 ? '--dark' : ''} ${
        isBottomLevel ? '--light' : ''
      }`;
    }

    return {
      id: this.scheduleContextMarkerId,
      resourceId: markerData.resourceId,
      startDate: markerData.startDate,
      endDate: markerData.endDate,
      name: '',
      cls,
    };
  }

  private nonWorkingTimeRangeClass = 'non-working-time-range';

  public scheduleContextMarkerId = 'schedule-context-marker';
}
