import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class CollisionCausedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.COLLISION_CAUSED;

  public constructor(
    public properties: {
      type: 'milestone' | 'order';
      user: 'contributor' | 'project_management';
      isVisible: boolean;
      openCollisionCount: number;
      successorIsFixed: boolean;
    },
  ) {
    super();
  }
}

export class CollisionResolvedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.COLLISION_RESOLVED;

  public constructor(
    public properties: {
      type: 'milestone' | 'order';
      user: 'contributor' | 'project_management';
      openCollisionCount: number;
      isOnlyHidden: boolean;
      successorIsFixed: boolean;
      method:
        | 'predecessor_move'
        | 'predecessor_delete'
        | 'successor_move'
        | 'successor_delete'
        | 'successor_mark_done'
        | 'dependency_delete'
        | 'dependency_update';
    },
  ) {
    super();
  }
}

export class CollisionViewedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.COLLISION_VIEWED;

  public constructor(
    public properties: {
      type: 'milestone' | 'order';
      source: 'row' | 'schedule_assistant' | 'notification';
    },
  ) {
    super();
  }
}

export class CollisionRowScrollButtonClickedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.COLLISION_ROW_SCROLL_BUTTON_CLICKED;

  public constructor(public properties: { value: 'previous' | 'next' }) {
    super();
  }
}

export class ScheduleAssistantOpenedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_ASSISTANT_OPENED;
}
