import { AllowedComponentProps, VNodeProps } from 'vue';

import type { ProjectTradeSequenceEditor } from '@/features/projectTradeSequences';
import { TradeSequenceV2Fragment } from '@/graphql/__generated__/graphql';
import { NodeName } from '@/repositories/utils/cache';

type BaseVueProps = VNodeProps & AllowedComponentProps;
// Utility type to extract props from a Vue component
type ComponentProps<T extends { new (...args: any[]): any }> =
  InstanceType<T>['$props'] extends infer P ? Omit<P, keyof BaseVueProps> : never;

export enum SchedulerPopupComponent {
  SCHEDULE_CLICK,
  SCHEDULE_MAIN_CLICK,
  SCHEDULE_SUMMARY_SECTION_CLICK,
  SCHEDULE_CONTEXT_MENU,
  NON_WORKING_TIME_RESOLUTION,
  PROJECT_TRADE_SEQUENCE_CREATE,
  PROJECT_TRADE_SEQUENCE_EDIT,
  EVENT_HOVER,
  PROJECT_ADDRESS_UPDATE,
  LOADING_CONTENT,
  COLLISION_DETAILS,
  COLLISION_JUMP_LIST,
}

interface BasicSchedulerPopupPayload {
  mouseEvent?: MouseEvent;
}

interface CollisionDetailsPayload extends BasicSchedulerPopupPayload {
  toId: string;
  date: SchedulingDate;
}

interface CollisionJumpListPayload extends BasicSchedulerPopupPayload {
  date: SchedulingDate;
}

interface ScheduleClickPayload extends BasicSchedulerPopupPayload {
  schedulingDate: SchedulingDate;
  resource: string;
}

interface ScheduleMainClickPayload extends BasicSchedulerPopupPayload {
  schedulingDate: SchedulingDate;
}

interface SummarySectionClickPayload extends BasicSchedulerPopupPayload {
  schedulingDate: SchedulingDate;
  wbsSectionId: string;
}

interface ScheduleContextMenuPayload extends BasicSchedulerPopupPayload {
  schedulingDate: SchedulingDate;
  resource: string;
  valid: boolean;
}

interface EventHoverPayload extends BasicSchedulerPopupPayload {
  entity: NodeName;
  id: string;
}

interface ProjectAddressUpdatePayload extends BasicSchedulerPopupPayload {}

export interface NonWorkingTimeResolutionEvent {
  id: string;
  newDate: SchedulingDate;
  newResourceId: string;
  originalStartDate: SchedulingDate;
  originalEndDate: SchedulingDate;
  duration?: number;
  calendarId: string;
  type: 'start' | 'end';
}

export interface NonWorkingTimeResolutionPayload extends BasicSchedulerPopupPayload {
  events: NonWorkingTimeResolutionEvent[];
}

export interface SchedulerOrderGenerationContext {
  schedulingDate: SchedulingDate;
  resourceId: string;
}

export interface TradeSequenceCreatePayload extends BasicSchedulerPopupPayload {
  index?: number;
  generationContext?: SchedulerOrderGenerationContext;
}

export enum TradeSequenceLoadingProgress {
  TRADE_SEQUENCE,
  ORDERS,
  DEPENDENCIES,
}

export interface TradeSequenceEditPayload extends BasicSchedulerPopupPayload {
  tradeSequence: TradeSequenceV2Fragment;
}

export type ProjectTradeSequenceProps = ComponentProps<typeof ProjectTradeSequenceEditor>;

type PayloadForComponent<T> = T extends keyof SchedulerPopupState ? SchedulerPopupState[T] : never;

export type OpenSchedulerPopupOptions<T extends keyof SchedulerPopupState> = {
  component: T;
  payload: PayloadForComponent<T>;
  patient?: boolean;
  submissive?: boolean;
};

export interface SchedulerPopupState {
  [SchedulerPopupComponent.SCHEDULE_CLICK]?: ScheduleClickPayload;
  [SchedulerPopupComponent.SCHEDULE_MAIN_CLICK]?: ScheduleMainClickPayload;
  [SchedulerPopupComponent.SCHEDULE_SUMMARY_SECTION_CLICK]?: SummarySectionClickPayload;
  [SchedulerPopupComponent.SCHEDULE_CONTEXT_MENU]?: ScheduleContextMenuPayload;
  [SchedulerPopupComponent.NON_WORKING_TIME_RESOLUTION]?: NonWorkingTimeResolutionPayload;
  [SchedulerPopupComponent.PROJECT_TRADE_SEQUENCE_CREATE]?: ProjectTradeSequenceProps;
  [SchedulerPopupComponent.PROJECT_TRADE_SEQUENCE_EDIT]?: ProjectTradeSequenceProps;
  [SchedulerPopupComponent.EVENT_HOVER]?: EventHoverPayload;
  [SchedulerPopupComponent.PROJECT_ADDRESS_UPDATE]?: ProjectAddressUpdatePayload;
  [SchedulerPopupComponent.LOADING_CONTENT]?: BasicSchedulerPopupPayload;
  [SchedulerPopupComponent.COLLISION_DETAILS]?: CollisionDetailsPayload;
  [SchedulerPopupComponent.COLLISION_JUMP_LIST]?: CollisionJumpListPayload;
}
