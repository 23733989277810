import { ApolloCache, NormalizedCacheObject, Reference, StoreObject } from '@apollo/client/core';
import { fromBase64, toBase64 } from 'js-base64';

import { Edge } from '@/graphql/types';

/**
 * Turns a list of nodes into a list of edges
 */
export function toNodeList<T, TEdge>(arr: T[], nodeName: NodeName): Edge<T, TEdge>[] {
  return arr.map((obj) => ({ node: obj, __typename: getNodeEdgeTypeName<TEdge>(nodeName) }));
}

/**
 * Turns a list of nodes into a full connection
 */
export function createConnection<T, TConnection = string, TEdge = string>(
  data: T[],
  nodeName: NodeName,
): {
  edges: Edge<T, TEdge>[];
  __typename?: TConnection;
} {
  return {
    edges: toNodeList<T, TEdge>(data, nodeName),
    __typename: getNodeConnectionTypeName<TConnection>(nodeName),
  };
}

export function getNodeEdgeTypeName<TEdge = string>(nodeName: NodeName): TEdge {
  return `${nodeName}Edge` as unknown as TEdge;
}

export function getNodeConnectionTypeName<TConnection = string>(nodeName: NodeName): TConnection {
  return `${nodeName}Connection` as unknown as TConnection;
}

export function getMutationTypeName(): {
  __typename: string;
} {
  return { __typename: 'Mutation' };
}

export interface CacheObject {
  node: { __ref: string };
}

/** Replaces an object with temp id with a real object of a new connection */
export function getReplacedEdges(
  existing: {
    edges: CacheObject[];
  },
  tmpIds: string | string[],
  newConnection: {
    edges: Edge<Reference | undefined>[];
  },
): Edge<Reference | undefined>[] {
  const tmpObjects = existing.edges.filter((edge) =>
    (Array.isArray(tmpIds) ? tmpIds : [tmpIds]).some((tmpId) => edge.node?.__ref?.includes(tmpId)),
  );

  return [
    // filter tmp added object out again
    ...existing.edges.filter((edge: CacheObject) => !tmpObjects.includes(edge)),
    ...newConnection.edges,
  ];
}

/** Filters object by id of exisiting edges */
export function filterObjectOfExisting(
  existing: { edges: CacheObject[] },
  id: string,
  node: NodeName,
): { edges: CacheObject[] } {
  const edges = (existing?.edges ?? []).filter(
    (edge: CacheObject) => edge.node?.__ref !== getDataId(node, id ?? ''),
  );
  return { ...existing, edges };
}

/** Retrieves the referenced object ID */
export function getReferencedId(object: { __ref: string }): string {
  if (!object.__ref.includes(':')) return '';

  return object.__ref.split(':')[1];
}

export function makeReference(id: string): { __ref: string } {
  return { __ref: id };
}

/**
 * Extracts all objects from cahce by given node and id
 * @param cache cache object
 * @param node node name of object
 * @param filter id of object or custom filter function
 */
export function extractObjectsFromCache(
  cache: ApolloCache<NormalizedCacheObject>,
  node: NodeName,
  filter: string | ((entry: Dictionary) => boolean),
): (StoreObject | undefined)[] {
  // extract all data from cache
  const cacheData = cache.extract();
  // find the entries that belong to the given node and id or filter
  const cachedKeys = Object.keys(cacheData).filter((entry) =>
    typeof filter === 'string' ? entry === getDataId(node, filter) : filter(cacheData[entry] ?? {}),
  );
  return cachedKeys.map((key) => cacheData[key]);
}

/**
 * Returns node if found in edges
 * @param existing edges to filter from
 * @param node node name of object
 * @param id id of object
 */
export function findNodeInEdges(
  existing: {
    edges: CacheObject[];
  },
  node: NodeName,
  id: string,
): CacheObject | undefined {
  return (existing?.edges ?? []).find(
    (edge: CacheObject) => edge.node?.__ref === getDataId(node, id ?? ''),
  );
}

/**
 * Finds all queries in cache, that are related to the object, which should be deleted from cache.
 * Either removes this object, if the query returns multiple objects, or removes the whole query data if not.
 * This is used to clean the whole cache, if one object was deleted
 * @param cache cache object
 * @param node node name of object
 * @param id id of object
 */
export function removeObjectFromAllRelatedQueries(
  cache: ApolloCache<NormalizedCacheObject>,
  node: NodeName,
  id: string,
  broadcast = true,
): void {
  const objectId = getDataId(node, id);
  const cacheData = cache.extract();
  const queries = Object.entries(cacheData.ROOT_QUERY as Dictionary);

  const relatedQueries = queries.reduce(
    (related, [query, result]) => {
      const fetchOneQuery = result?.__ref === objectId;
      const fetchAllQuery = !!result?.edges?.find(
        (edge: CacheObject) => edge.node?.__ref === objectId,
      );
      if (fetchOneQuery || fetchAllQuery) {
        // queries are stored in combination with their current variables,
        // but we want to remove the object from any variables config with this query
        related.push({ name: query.split('(')[0], multiple: fetchAllQuery });
      }
      return related;
    },
    [] as { name: string; multiple: boolean }[],
  );

  relatedQueries.forEach((query) => {
    cache.modify({
      fields: {
        [query.name]: (existing) => {
          // remove the object from the query, if it has multiple connections
          // remove the whole query data if it is the only object returned from this query
          return query.multiple
            ? filterObjectOfExisting(existing as { edges: CacheObject[] }, id, node)
            : null;
        },
      },
      broadcast,
    });
  });
}

export enum NodeName {
  ACCEPTANCE_CRITERION = 'ProjectMilestoneAcceptanceCriterionNode',
  ADDRESS = 'AddressNode',
  ATTACHMENT = 'AttachmentNode',
  CALENDAR = 'CalendarNode',
  CALENDAR_EXCEPTION = 'CalendarExceptionNode',
  // NOTE: Not an actual node, but better alignment with usage in other places
  COLLISION = 'CollisionNode',
  CONTACT = 'OrderContactNode',
  // NOTE: Not an actual node, but better alignment with usage in other places
  DRYING_BREAK = 'DryingBreakNode',
  FLOOR = 'FloorNode',
  HOLIDAY = 'HolidayNode',
  LINK = 'ExternalLinkNode',
  MILESTONE = 'ProjectMilestoneNode',
  ORDER = 'OrderNode',
  ORDER_DEPENDENCY = 'OrderDependencyNode',
  ORDER_PHOTO = 'OrderPhotoNode',
  ORDER_DOCUMENT = 'OrderDocumentNode',
  ORDER_TASK = 'OrderTaskNode',
  ORDER_TASK_TEMPLATE = 'OrderTaskTemplateNode',
  PAUSE = 'LeanProjectPauseNode',
  PROJECT = 'ProjectNode',
  TICKET = 'TicketNode',
  TRADE = 'TradeNode',
  TRADE_SEQUENCE = 'TradeSequenceNode',
  TRADE_SEQUENCE_TEMPLATE = 'TradeSequenceTemplateNode',
  TRADE_SEQUENCE_ACTIVITY = 'TradeSequenceActivityNode',
  TRADE_SEQUENCE_DEPENDENCY = 'TradeSequenceActivityDependencyNode',
  TRADE_VARIATION = 'TenantTradeVariationNode',
  SECTION = 'WBSSectionNode',
  SECTION_BASEPLAN = 'WBSSectionBaseplanNode',
  STATUS_REPORT = 'OrderStatusReportNode',
  USER = 'UserNode',
  TENANT = 'TenantNode',
  WORKING_DAY = 'WorkingDayNode',
  WORKING_TIME = 'WorkingTimeNode',
  PROJECT_VERSION = 'ProjectVersionNode',
  PROJECT_CONTRIBUTOR_GROUP = 'ProjectContributorGroupNode',
}

export enum FragmentName {
  CALENDAR = 'Calendar',
  CONTACT = 'Contact',
  LEAN_PROJECT = 'LeanProject',
  MILESTONE = 'ProjectMilestone',
  ORDER_DEPENDENCY = 'OrderDependency',
  ORDER_PHOTO = 'OrderPhoto',
  ORDER_TASK = 'OrderTask',
  ORDER_TASK_TEMPLATE = 'OrderTaskTemplate',
  PAUSE = 'LeanProjectPause',
  TRADE_VARIATION = 'LegacyTenantTradeVariation',
  STATUS_REPORT = 'OrderStatusReport',
  USER = 'User',
  TENANT = 'Tenant',
  OWN_TENANT = 'OwnTenant',
  PROJECT_VERSION = 'ProjectVersion',
}

/** Returns data id of the object, which consists of the type of the node and the database id */
export function getDataId(node: NodeName, id: string): string {
  return `${node}:${id}`;
}

/** Encodes given raw id with node name to base64 string */
export function toGlobalId(node: NodeName, rawId: string): string {
  return toBase64(getDataId(node, rawId));
}

/** Encodes given raw id with node name to base64 string */
export function fromGlobalId(id: string): NodeName {
  return fromBase64(id).split(':')[0] as NodeName;
}

export function getOptimisticResponse<
  TMutation extends {
    __typename?: string;
  },
>(getData: () => TMutation): TMutation {
  return {
    ...getMutationTypeName(),
    ...getData(),
  };
}
