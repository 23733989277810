import { App } from 'vue';
import { createRouter, createWebHistory, Router } from 'vue-router';

import { isEqual } from '@/helpers/utils/objects';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { useBeforeEach } from '@/router/beforeEach';
import { useRouteNames } from '@/router/helpers/routeNames';
import { useCompanyGuard } from '@/router/modules/company';
import { useProjectGuard, useProjectRoutes } from '@/router/modules/leanProjects';
import { useTenantsGuard } from '@/router/modules/tenants';
import {
  useUnauthenticatedGuard,
  useUnauthenticatedRoutes,
} from '@/router/modules/unauthenticated';
import { useUserRoutes } from '@/router/modules/users';
import { BASE_URL } from '@/utils/config';
import { ENABLE_TEM5 } from '@/utils/featureToggles';

import { RouterDependencies } from './types';

const ThePublicLayout = () => import('@/layouts/ThePublicLayout.vue');
const TheDefaultLayout = () => import('@/layouts/TheDefaultLayout.vue');
const TheLeanProjectLayout = () => import('@/layouts/TheLeanLayout.vue');

export function installRouter(
  app: App,
  dependencies: RouterDependencies,
  {
    authenticationService,
    loggingService,
  }: { authenticationService: AuthenticationService; loggingService: LoggingService },
): Router {
  /** Encapsulates service, repos, and store */
  const metaEntities = {
    authenticationService,
    loggingService,
    ...dependencies,
  };

  const { Routes } = useRouteNames();

  const router = createRouter({
    history: createWebHistory(BASE_URL),
    scrollBehavior(_to, _from, savedPosition): ScrollToOptions {
      return savedPosition || { left: 0, top: 0 };
    },
    routes: [
      {
        path: '',
        component: ThePublicLayout,
        children: useUnauthenticatedRoutes(),
        beforeEnter: useUnauthenticatedGuard(metaEntities),
        meta: {
          isAuthenticationRequired: false,
        },
      },
      {
        path: '/:tenantId?/users',
        component: TheDefaultLayout,
        children: useUserRoutes(metaEntities),
        meta: {
          isAuthenticationRequired: true,
        },
      },
      {
        path: '/tenants',
        name: Routes.Base.PickTenant,
        component: () => import('@/views/PickTenant.vue'),
        beforeEnter: useTenantsGuard(metaEntities),
        meta: {
          isAuthenticationRequired: true,
        },
      },
      {
        path: '/:tenantId/projects/:id',
        component: TheLeanProjectLayout,
        children: useProjectRoutes(),
        beforeEnter: useProjectGuard(metaEntities),
        meta: {
          isAuthenticationRequired: true,
        },
      },
      {
        path: '/:tenantId',
        component: TheDefaultLayout,
        redirect: { name: Routes.Lean.All },
        meta: {
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: 'company',
            name: Routes.Tenant.Details,
            component: () => import('@/views/company/Details.vue'),
            beforeEnter: useCompanyGuard(metaEntities),
          },
          {
            path: 'trades',
            name: Routes.Tenant.TenantTrades,
            component: () => import('@/features/tenantTemplates/pages/TenantTrades.vue'),
          },
          {
            path: 'trade-sequences',
            name: Routes.Tenant.TenantTradeSequences,
            component: () => import('@/features/tenantTemplates/pages/TenantTradeSequences.vue'),
            meta: {
              group: 'TenantTrades',
            },
          },
          {
            path: 'project-structure',
            name: Routes.Tenant.ProjectStructureTemplates,
            component: () =>
              import('@/features/tenantTemplates/pages/ProjectStructureTemplates.vue'),
          },
          ...(ENABLE_TEM5
            ? [
                {
                  path: 'project-templates',
                  name: Routes.Tenant.ProjectTemplates,
                  component: () => import('@/features/tenantTemplates/pages/ProjectTemplates.vue'),
                },
              ]
            : []),
          {
            path: 'projects',
            name: Routes.Lean.All,
            component: () => import('@/views/leanProjects/All.vue'),
            meta: {
              group: 'LeanProjects',
            },
          },
        ],
      },
      {
        path: '/:pathMatch(.*)*',
        meta: {
          isAuthenticationRequired: true,
        },
        redirect: (to) => {
          if (to.path.includes('leanProjects')) {
            return { path: to.path.replace('leanProjects', 'projects') };
          }
          return to;
        },
      },
    ],
  });

  router.beforeEach((to, from) => {
    // Log the page change to the logger
    if (from.name !== to.name) {
      loggingService.setPage(
        to.name as string,
        to.params.tenantId as string,
        to.params.id as string,
      );
    }
    if (!isEqual(to.query, from.query)) {
      loggingService.setAdditionalProperties(to.query);
    }
    return useBeforeEach(metaEntities)(to, from);
  });

  app.use(router);
  return router;
}
