import '@/features/sections/jsx/sectionItemStyle.css';

import { Composer } from 'vue-i18n';

import { sectionSelectors } from '@/common/selectors/section';
import { WbsSectionEntity } from '@/common/types';
import SectionToolbar from '@/features/sections/jsx/SectionToolbar';
import { useDisplayedDateDependentToTime } from '@/helpers/utils/dates';
import { OrderedTree, TreeNode } from '@/helpers/utils/orderedTree';
import { isMacOS } from '@/helpers/utils/os';
import Icon from '@/jsx/components/Icon';
import Tooltip from '@/jsx/components/Tooltip';
import { removeOrphanToolbars } from '@/jsx/components/util';
import { h } from '@/jsx/jsxFactory';
import { USERBACK_IGNORE_CLASS } from '@/plugins/userback';
import { SchedulerEvent } from '@/services/store/schedule/types';

export type SectionItemConfiguration = {
  addSection: (section: WbsSectionEntity) => void;
  collapseToggled: (
    id: string,
    isCollapsed: boolean,
    isAltModifier: boolean,
    isShiftAltModifier: boolean,
  ) => void;
  copySection: (section: WbsSectionEntity) => void;
  deleteSection: (section: WbsSectionEntity) => void;
  getCollapsedRowIds: () => string[];
  getIsMouseDown: () => boolean;
  getOpenToolbarSectionId: () => string | null;
  getSectionSummaryEventById?: (id: string) => SchedulerEvent | undefined;
  getShowEditActions: () => boolean;
  getWbsSectionById: (id: string) => WbsSectionEntity | undefined;
  getWbsSectionsTree: () => OrderedTree<WbsSectionEntity>;
  hideSectionSummary?: () => void;
  i18n: Composer;
  indentSection: (section: WbsSectionEntity) => void;
  isReadOnly: boolean;
  hideActions: boolean;
  outdentSection: (section: WbsSectionEntity) => void;
  setOpenToolbarSectionId: (id: string | null) => void;
  shouldShowSectionSummary: boolean;
  showSectionSummary?: (section: WbsSectionEntity, event: MouseEvent) => void;
  updateSection: (section: WbsSectionEntity, name: string) => void;
};

type SectionItemProps = {
  section: TreeNode<WbsSectionEntity>;
  config: SectionItemConfiguration;
};

export default function SectionItem({ section, config }: SectionItemProps) {
  let hoverTimeout: number | undefined;

  const sectionItemId = `section-item-${section.id}`;
  const hoverContainerId = `hover-container-${section.id}`;
  const collapseTooltipId = `collapse-tooltip-${section.id}`;
  const collapseTooltipContentId = `collapse-tooltip-content-${section.id}`;
  const sectionToolbarId = `section-toolbar-${section.id}`;

  const hasScheduleSummaryEvents = config.getSectionSummaryEventById !== undefined;
  const sectionSummaryEvent = config.getSectionSummaryEventById
    ? config.getSectionSummaryEventById(section.id)
    : undefined;

  const setIsHovering = (isHovering: boolean) => {
    if (config.hideActions) return;

    if (!isHovering) {
      clearTimeout(hoverTimeout);
      setTeleporterItemHoverableClass(isHovering);
      setToolbarVisibility(isHovering);
      setDragIndicatorVisibility(isHovering);
      config.setOpenToolbarSectionId(null);
      handleHoverEnd(section.id);
      return;
    }

    hoverTimeout = window.setTimeout(() => {
      setTeleporterItemHoverableClass(isHovering);
      setToolbarVisibility(isHovering);
      setDragIndicatorVisibility(isHovering);
      config.setOpenToolbarSectionId(section.id);
      handleHoverStart(section.id);
    }, 500);
  };

  const setTeleporterItemHoverableClass = (isHovering: boolean, id = sectionItemId) => {
    const sectionTeleporterItemElement = document.getElementById(id);
    if (sectionTeleporterItemElement && config.getShowEditActions() && isHovering) {
      sectionTeleporterItemElement.classList.add('section-teleporter--hoverable');
    } else if (sectionTeleporterItemElement) {
      sectionTeleporterItemElement.classList.remove('section-teleporter--hoverable');
    }
  };

  const setToolbarVisibility = (isVisible: boolean) => {
    const parent = document.getElementById(sectionItemId);
    if (!parent) return;
    if (isVisible) {
      removeOrphanToolbars();
      const sectionToolbar = SectionToolbar({
        section,
        sectionTree: config.getWbsSectionsTree(),
        sectionSummaryEvent,
        shouldShowSectionSummary: config.shouldShowSectionSummary,
        hasScheduleSummaryEvents,
        showSectionSummary: config.showSectionSummary,
        hideSectionSummary: config.hideSectionSummary,
        addSection: config.addSection,
        copySection: config.copySection,
        deleteSection: config.deleteSection,
        indentSection: config.indentSection,
        outdentSection: config.outdentSection,
        i18n: config.i18n,
        isReadOnly: config.isReadOnly,
      });
      parent.appendChild(sectionToolbar);
    } else {
      document.getElementById(sectionToolbarId)?.remove();
    }
  };

  const canBeDragged = section.siblings.length > 1;
  const setDragIndicatorVisibility = (isVisible: boolean, id = hoverContainerId) => {
    let child: HTMLElement;
    if (config.getShowEditActions() && canBeDragged && isVisible) {
      child = (
        <div>
          <div class="tw-flex tw-justify-center tw-items-center tw-invisible group-hover:tw-visible tw-cursor-grab tw-text-grey-200">
            <Icon icon="drag_indicator" outlined={false} />
          </div>
        </div>
      );
    } else {
      child = <div class="tw-min-w-[20px]" />;
    }
    const hoverContainerElement = document.getElementById(id);
    hoverContainerElement?.replaceChildren(child);
  };

  const getCollapseTooltipContent = (id: string) => {
    const isCollapsed = config.getCollapsedRowIds().includes(id);

    config.i18n.warnHtmlMessage = false;

    const allOnLevelText = config.i18n.t(
      'objects.section.collapseAllOnLevelTooltip',
      isCollapsed ? 1 : 0,
    );
    const allOnLevelShortcut = config.i18n.t(
      isMacOS() ? `keyboard.mac.toggleCollapseLevel` : `keyboard.rest.toggleCollapseLevel`,
    );
    const allContentText = config.i18n.t(
      'objects.section.collapseAllContentTooltip',
      isCollapsed ? 1 : 0,
    );
    const allContentShortcut = config.i18n.t(
      isMacOS() ? `keyboard.mac.toggleCollapseContent` : `keyboard.rest.toggleCollapseContent`,
    );

    return [
      { text: allOnLevelText, shortcut: allOnLevelShortcut },
      { text: allContentText, shortcut: allContentShortcut },
    ];
  };

  const onToggle = (event: MouseEvent) => {
    const newCollapsedState = !config.getCollapsedRowIds().includes(section.id);
    config.collapseToggled(
      section.id,
      newCollapsedState,
      event.altKey,
      event.shiftKey && event.altKey,
    );
  };

  /** mouse and focus handlers */
  const handleHoverStart = (entityId: string) => {
    const sectionRow = document.querySelector<HTMLElement>(`.b-grid-row[data-id='${entityId}']`);
    if (!sectionRow) return;

    // NOTE: We set the z-index one higher than the highest default z-index for rows (998), otherwise the toolbar would be hidden behind other rows.
    // See layers.css for specification of z-indexes in the schedule.
    sectionRow.style.zIndex = '999';
  };

  const handleHoverEnd = (entityId: string) => {
    const sectionRow = document.querySelector<HTMLElement>(`.b-grid-row[data-id='${entityId}']`);
    if (!sectionRow) return;

    sectionRow.style.zIndex = '998';
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById(sectionSelectors.sectionName(section.id))!.blur();
    }
    if (event.key === 'Tab') {
      setTimeout(() => {
        const selection = window.getSelection();
        if (!selection) return;
        const range = document.createRange();
        range.selectNodeContents(document.activeElement as Node);
        selection.removeAllRanges();
        selection.addRange(range);
      });
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      document.getElementById(sectionSelectors.sectionName(section.id))!.blur();
    }
  };

  const handleKeyDownCapturing = (event: KeyboardEvent) => {
    if (event.key === 'Space') {
      event.stopPropagation();
    }
  };

  const getDisplayedTime = useDisplayedDateDependentToTime(config.i18n);

  const resetOpenToolbarSection = () => {
    const sectionId = config.getOpenToolbarSectionId();
    if (!sectionId) return;

    setTeleporterItemHoverableClass(false, `section-item-${sectionId}`);
    setDragIndicatorVisibility(false, `hover-container-${sectionId}`);
    handleHoverEnd(sectionId);
  };

  const onMouseEnter = () => {
    if (isInitiallyHovering) return;
    if (config.getIsMouseDown()) return;
    resetOpenToolbarSection();
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
    isInitiallyHovering = false;
  };

  let isInitiallyHovering = config.getOpenToolbarSectionId() === section.id;
  if (isInitiallyHovering) {
    removeOrphanToolbars();
    handleHoverStart(section.id);
  }

  const SectionNameDiv = () => (
    <div
      contentEditable={config.getShowEditActions()}
      id={sectionSelectors.sectionName(section.id)}
      class="tw-inline-block tw-cursor-text tw-min-w-24 tw-outline-none tw-pr-4"
      data-testid={sectionSelectors.sectionName(section.id)}
      role="button"
      tabindex={0}
      onKeydown={handleKeyDown}
      onKeyup={handleKeyUp}
      onKeydownCapturing={handleKeyDownCapturing}
      onClick={(event: MouseEvent) => event.stopPropagation()}
      onBlur={(event: FocusEvent) => {
        if (event.target === null) return;
        config.updateSection(section, (event.target as HTMLElement).textContent!);
      }}
    >
      {section.name}
    </div>
  );

  const onDragstart = () => {
    setToolbarVisibility(false);

    const tooltipTriggerContainerElement = document.getElementById(collapseTooltipId);
    if (tooltipTriggerContainerElement) {
      tooltipTriggerContainerElement.classList.remove('tw-cursor-pointer');
    }

    const tooltipTriggerContentElement = document.getElementById(collapseTooltipContentId);
    if (tooltipTriggerContentElement) {
      tooltipTriggerContentElement.classList.add('--disabled');
    }
  };

  const onDragend = () => {
    const tooltipTriggerContainerElement = document.getElementById(collapseTooltipId);
    if (tooltipTriggerContainerElement) {
      tooltipTriggerContainerElement.classList.add('tw-cursor-pointer');
    }

    const tooltipTriggerContentElement = document.getElementById(collapseTooltipContentId);
    if (tooltipTriggerContentElement) {
      tooltipTriggerContentElement.classList.remove('--disabled');
    }
  };

  document.removeEventListener('dragstart', onDragstart);
  document.removeEventListener('dragend', onDragend);
  document.addEventListener('dragstart', onDragstart);
  document.addEventListener('dragend', onDragend);

  return (
    <div
      id={sectionItemId}
      class={`section-teleporter tw-group indent-${section.indentation}
       ${isInitiallyHovering && config.getShowEditActions() ? 'section-teleporter--hoverable' : ''}`}
      role="presentation"
      onFocus={() => {}}
      onMouseenter={onMouseEnter}
      onMouseleave={onMouseLeave}
      onClick={(event: MouseEvent) => onToggle(event)}
    >
      <div id={hoverContainerId}>
        {isInitiallyHovering && config.getShowEditActions() && canBeDragged ? (
          <div>
            <div class="tw-flex tw-justify-center tw-items-center tw-invisible group-hover:tw-visible tw-cursor-grab tw-text-grey-200">
              <Icon icon="drag_indicator" outlined={false} />
            </div>
          </div>
        ) : (
          <div class="tw-min-w-[20px]" />
        )}
      </div>
      {...[...Array(section.indentation)].map(() => <div class="tw-min-w-[20px]" />)}
      {section.children.length ? (
        <Tooltip
          id={collapseTooltipId}
          position="top-left"
          disabled={false}
          content={getCollapseTooltipContent(section.id)}
        >
          <div id={collapseTooltipContentId} class={`section-teleporter-collapse-icon`}>
            <Icon
              icon={
                config.getCollapsedRowIds().includes(section.id)
                  ? 'keyboard_arrow_down'
                  : 'keyboard_arrow_up'
              }
              outlined={false}
            />
          </div>
        </Tooltip>
      ) : (
        ''
      )}
      <div class={`tw-grow ${USERBACK_IGNORE_CLASS}`}>
        <SectionNameDiv />
        {sectionSummaryEvent ? (
          <p class="-tw-mt-1 tw-ds-text-xs--medium">
            {sectionSummaryEvent && sectionSummaryEvent.startDate && (
              <span>
                {getDisplayedTime(
                  sectionSummaryEvent.startDate,
                  sectionSummaryEvent.endDate ?? sectionSummaryEvent.startDate,
                )}
              </span>
            )}
          </p>
        ) : (
          ''
        )}
      </div>
      {isInitiallyHovering ? (
        <SectionToolbar
          section={section}
          sectionTree={config.getWbsSectionsTree()}
          sectionSummaryEvent={sectionSummaryEvent}
          shouldShowSectionSummary={config.shouldShowSectionSummary}
          hasScheduleSummaryEvents={hasScheduleSummaryEvents}
          showSectionSummary={config.showSectionSummary}
          hideSectionSummary={config.hideSectionSummary}
          addSection={config.addSection}
          copySection={config.copySection}
          deleteSection={config.deleteSection}
          indentSection={config.indentSection}
          outdentSection={config.outdentSection}
          i18n={config.i18n}
          isReadOnly={config.isReadOnly}
        />
      ) : (
        ''
      )}
    </div>
  );
}
