import { DomClassList } from '@bryntum/schedulerpro';
import { differenceInMilliseconds } from 'date-fns';

import { Entity } from '@/common/types';
import { MilestoneType } from '@/features/milestones/types';
import { DEFAULT_MILESTONE_COLOR, isAboveMinimumContrast } from '@/helpers/utils/colors';
import { isEqual } from '@/helpers/utils/objects';
import { NodeName } from '@/repositories/utils/cache';
import { ScheduleStore } from '@/services/store/schedule';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import {
  getPlaceholderEventId,
  schedulerClassConfig,
  schedulerIconConfig,
} from '@/services/store/schedule/parsers/base';
import {
  DurationUnit,
  SchedulerEvent,
  SchedulerPlaceholderEventData,
} from '@/services/store/schedule/types';

export function addPlaceholderEvent(
  store: ScheduleStore,
  data: SchedulerPlaceholderEventData,
): SchedulerEvent {
  const event = createPlaceholderEvent(data);
  syncEvent(store, event);
  const dryingBreakEvent = createDryingBreakPlaceholderEvent(store, data);
  if (dryingBreakEvent) {
    syncEvent(store, dryingBreakEvent);
  }
  return event;
}

export function updatePlaceholderEvent(
  store: ScheduleStore,
  data: SchedulerPlaceholderEventData,
): SchedulerEvent | null {
  const id = getPlaceholderEventId();
  const oldPlaceholderEvent = store.entities.events.get(id);
  if (!oldPlaceholderEvent) return null;
  const event = createPlaceholderEvent(data);

  if (!isEqual(oldPlaceholderEvent, event)) {
    syncEvent(store, event);
  }

  const dryingBreakId = DryingBreakEventParser.orderIdToDryingBreakId(id);
  const oldDryingBreakEvent = store.entities.events.get(dryingBreakId);
  const dryingBreakEvent = createDryingBreakPlaceholderEvent(store, data);
  if (oldDryingBreakEvent && !dryingBreakEvent) {
    store.entities.events.delete(oldDryingBreakEvent.id);
  }
  if (dryingBreakEvent && !isEqual(oldDryingBreakEvent, dryingBreakEvent)) {
    syncEvent(store, dryingBreakEvent);
  }
  return event;
}

export function removePlaceholderEvent(store: ScheduleStore): void {
  const id = getPlaceholderEventId();
  if (!store.entities.events.has(id)) return;

  store.entities.events.delete(id);
  const dryingBreakId = DryingBreakEventParser.orderIdToDryingBreakId(id);
  store.entities.events.delete(dryingBreakId);
}

function createPlaceholderEvent(data: SchedulerPlaceholderEventData): SchedulerEvent {
  const id = getPlaceholderEventId();
  const classList: string[] = ['hide-dependency-terminals'];
  classList.push(
    data.entity === NodeName.ORDER ? getPlaceholderOrderClass() : schedulerClassConfig[data.entity],
  );
  if (data.type === MilestoneType.FIXED) classList.push('--fixed');
  if (
    data.entity === NodeName.MILESTONE &&
    !isAboveMinimumContrast(data.eventColor ?? DEFAULT_MILESTONE_COLOR, '#FFFFFF')
  ) {
    classList.push('--dark');
  }

  const startDate = new SchedulingDate(data.startDate);
  const endDate = new SchedulingDate(data.endDate);

  return {
    id,
    name: '',
    startDate,
    endDate,
    durationUnit: DurationUnit.MILLISECOND,
    duration: differenceInMilliseconds(endDate, startDate),
    resourceId: data.resourceId,
    calendarId: data.calendarId,
    cls: new DomClassList(classList),
    iconCls: schedulerIconConfig[data.entity],
    entity: data.entity,
    isSelectable: false,
    isFixed: data.isFixed ?? false,
    ...(data.entity === NodeName.MILESTONE
      ? {
          endDate: data.startDate,
          eventColor: data.eventColor ?? DEFAULT_MILESTONE_COLOR,
          milestoneWidth: 60, // give enough space to show the date
          type: data.type,
          isFixed: data.type === MilestoneType.FIXED,
        }
      : {}),
    collisionCounter: null,
  };
}
function createDryingBreakPlaceholderEvent(
  store: ScheduleStore,
  data: SchedulerPlaceholderEventData,
): SchedulerEvent | null {
  const id = getPlaceholderEventId();
  if (!data.dryingBreak) return null;

  const startDate = data.endDate;

  const parser = new DryingBreakEventParser();
  const event: SchedulerEvent = {
    ...parser.getDryingBreakBaseEventData(id, startDate, data.dryingBreak.duration, [
      getPlaceholderOrderClass(),
      'hide-dependency-terminals',
    ]),
    name: data.dryingBreak.name ?? store.i18n.t('objects.dryingBreak.defaultName'),
    resourceId: data.resourceId,
    collisionCounter: null,
  };

  return event;
}

function getPlaceholderOrderClass(): string {
  return 'placeholder-order-event';
}

function syncEvent(store: ScheduleStore, event: SchedulerEvent): void {
  store.entities.events.set(event.id, event);
}

export function isPlaceholderOrDryingBreakPlaceholder(event: Entity): boolean {
  return (
    event.id === getPlaceholderEventId() ||
    event.id === DryingBreakEventParser.orderIdToDryingBreakId(getPlaceholderEventId())
  );
}
