import { Composer } from 'vue-i18n';

import { useGlobalStore } from '@/common/globalStore';
import { Entity, WbsSectionEntity } from '@/common/types';
import { useNotificationStore } from '@/features/notifications';
import { subtract } from '@/helpers/utils/arrays';
import { copy } from '@/helpers/utils/objects';
import { getRandomId } from '@/helpers/utils/strings';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';
import { useLoggingService } from '@/services/logging/composable';

import {
  getSectionAnalyticsType,
  SectionCreatedEvent,
  SectionDeletedEvent,
  SectionDuplicatedEvent,
  SectionEditedEvent,
  SectionHierarchyAddedEvent,
  SectionHierarchyRemovedEvent,
} from '../analytics';
import { ComponentsHelpPage } from '../constants';
import { useWbsSectionStore } from '../store';
import { indentSection, outdentSections } from './sectionIndentOutdent';
import { useSectionNameGeneration } from './sectionName';
import {
  getFlatSortedSections,
  getSectionChildren,
  getSectionDescendants,
  SectionsInput,
} from './sectionTreeUtils';

export function getSectionOperations(i18n: Composer) {
  const { generateNewSectionName } = useSectionNameGeneration(i18n);

  const generateNewSection = (siblingSection: WbsSectionEntity, allSections: SectionsInput) => {
    const isTopLevel = !siblingSection.parentId;
    const isMidLevel =
      siblingSection.parentId && !!getSectionChildren(allSections, siblingSection).length;

    const getLevel = () => {
      if (isTopLevel) return 'top';
      if (isMidLevel) return 'mid';
      return 'bottom';
    };

    const newSections: WbsSectionEntity[] = [
      {
        id: toGlobalId(NodeName.SECTION, getRandomId()),
        name: generateNewSectionName(siblingSection, allSections, {
          level: getLevel(),
        }),
        position: siblingSection.position + 1,
        parentId: siblingSection.parentId ?? null,
      },
    ];

    if (isTopLevel || isMidLevel) {
      newSections.push({
        id: toGlobalId(NodeName.SECTION, getRandomId()),
        name: generateNewSectionName(siblingSection, allSections, {
          level: 'bottom',
        }),
        position: 0,
        parentId: newSections[newSections.length - 1].id,
      });
    }
    return { newSections, root: newSections[0] };
  };

  const getToBeDeletedSections = (section: WbsSectionEntity, sections: SectionsInput) => {
    return getSectionDescendants(sections, section);
  };

  const parseSectionUpdate = (
    section: WbsSectionEntity,
    sections: SectionsInput,
    newName: string | null,
  ) => {
    const parsedName = parseSectionName(section, newName);

    if (!parsedName) return undefined;

    const oldSections = copy(getFlatSortedSections(sections));
    const newSections = oldSections.map((s) =>
      s.id === section.id ? { ...s, name: parsedName } : s,
    );
    return { oldSections, newSections };
  };

  const parseSectionName = (section: WbsSectionEntity, newName: string | null) => {
    // clear text selection
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }

    if (!newName) {
      const temp = section.name;
      section.name = '';
      section.name = temp;
      return undefined;
    }

    const trimmedNewName = newName.trim();

    return trimmedNewName === section.name ? undefined : trimmedNewName;
  };

  const parseSectionCopy = (section: WbsSectionEntity, sections: SectionsInput) => {
    const descendants = getSectionDescendants(sections, section);

    const idMapping = Object.fromEntries(
      descendants.map((s) => [s.id, toGlobalId(NodeName.SECTION, getRandomId())]),
    );

    const copiedSections: WbsSectionEntity[] = descendants.map((s) => ({
      id: idMapping[s.id],
      name: s.id === section.id ? generateNewSectionName(s, sections, { copyName: true }) : s.name,
      position: s.id === section.id ? s.position + 1 : s.position,
      parentId: (s.id === section.id ? s.parentId : idMapping[s.parentId ?? '']) ?? null,
    }));
    return { copiedSections, idMapping };
  };

  const parseSectionIndentation = (section: WbsSectionEntity, sections: SectionsInput) => {
    const oldSections = copy(getFlatSortedSections(sections));
    const newSections = indentSection(section, copy(oldSections), (_section, _sections) =>
      generateNewSectionName(_section, _sections, { level: 'mid' }),
    );

    const added = subtract(newSections, oldSections);
    const sanitizedNewSections = [
      ...oldSections.map((s) => newSections.find((ns) => ns.id === s.id)!),
      ...added,
    ];
    return { oldSections, sanitizedNewSections, added, newSections };
  };

  const parseSectionOutdentation = (section: WbsSectionEntity, sections: SectionsInput) => {
    const oldSections = copy(getFlatSortedSections(sections));
    const sectionChanges = outdentSections(oldSections, section);

    const newSections: WbsSectionEntity[] = subtract<Entity>(
      oldSections,
      sectionChanges.deletedSections,
    ).map((s) => {
      const update = sectionChanges.updatedSections?.find((us) => us.id === s.id);
      if (update) {
        return {
          ...(s as unknown as WbsSectionEntity),
          ...update,
        } as WbsSectionEntity;
      }
      return s as unknown as WbsSectionEntity;
    });

    const otherOutdentedSections = subtract(oldSections, newSections)
      .filter((s) => s.id !== section.id)
      .filter((s) => getSectionChildren(sections, s).length);

    const sanitizedNewSections = oldSections.map(
      (s) => newSections.find((ns) => ns.id === s.id) ?? null,
    );
    return { oldSections, newSections, sanitizedNewSections, otherOutdentedSections };
  };

  return {
    generateNewSection,
    getToBeDeletedSections,
    parseSectionUpdate,
    parseSectionCopy,
    parseSectionIndentation,
    parseSectionOutdentation,
    parseSectionName,
  };
}

export function useScheduleSectionActions(i18n: Composer) {
  const globalStore = useGlobalStore();
  const sectionStore = useWbsSectionStore();
  const loggingService = useLoggingService();

  const { generateNewSection, parseSectionCopy, parseSectionName } = getSectionOperations(i18n);

  const onAdd = (section: WbsSectionEntity) => {
    const { newSections, root } = generateNewSection(section, sectionStore.wbsSections);

    sectionStore.create(newSections, root);

    loggingService.trackEvent(
      new SectionCreatedEvent({
        type: getSectionAnalyticsType([...sectionStore.wbsSectionsList, ...newSections], section),
      }),
    );
  };

  const onDelete = (section: WbsSectionEntity) => {
    loggingService.trackEvent(
      new SectionDeletedEvent({
        type: getSectionAnalyticsType(sectionStore.wbsSections, section),
      }),
    );

    return sectionStore.delete([section]);
  };

  const onUpdate = (section: WbsSectionEntity, newName: string) => {
    const parsedSectionName = parseSectionName(section, newName);
    if (!parsedSectionName) return;

    sectionStore.update([
      {
        id: section.id,
        name: parsedSectionName,
      },
    ]);

    loggingService.trackEvent(
      new SectionEditedEvent({
        type: getSectionAnalyticsType(sectionStore.wbsSections, {
          ...section,
          name: parsedSectionName,
        }),
      }),
    );
  };

  const onCopy = (section: WbsSectionEntity) => {
    if (!globalStore.scheduleProjectId) return;

    const { copiedSections, idMapping } = parseSectionCopy(section, sectionStore.wbsSections);

    const root = copiedSections.find((s) => s.id === idMapping[section.id]);

    if (!root) throw new Error('Section copy failed. Root section not found.');

    sectionStore.create(copiedSections, root);

    loggingService.trackEvent(
      new SectionDuplicatedEvent({
        type: getSectionAnalyticsType(
          [...sectionStore.wbsSectionsList, ...copiedSections],
          section,
        ),
      }),
    );
  };

  const onIndent = (section: WbsSectionEntity) => {
    sectionStore.indent(section);

    loggingService.trackEvent(
      new SectionHierarchyAddedEvent({
        type: getSectionAnalyticsType(sectionStore.wbsSections, section),
      }),
    );
  };

  const onOutdent = async (section: WbsSectionEntity) => {
    const oldSections = new Map(sectionStore.wbsSections);
    const type = getSectionAnalyticsType(sectionStore.wbsSections, section);

    const { changes } = await sectionStore.outdent(section);

    let otherOutdentedSections: WbsSectionEntity[] = [];

    if (changes.delete?.wbsSections) {
      otherOutdentedSections = changes.delete.wbsSections
        .map(({ id }) => (id === section.id ? null : oldSections.get(id)))
        .filter(Boolean) as WbsSectionEntity[];
    }

    loggingService.trackEvent(
      new SectionHierarchyRemovedEvent({
        type,
        hasPropagatedDeletions: !!otherOutdentedSections.length,
      }),
    );

    if (otherOutdentedSections.length) {
      const notificationStore = useNotificationStore();

      notificationStore.push({
        titleI18nKey: 'objects.section.outdentNotificationTitle',
        bodyI18nKey: 'objects.section.outdentNotificationText',
        bodyI18nKeyVariables: {
          rows: otherOutdentedSections.map((s) => s.name).join(', '),
        },
        primaryAction: {
          callback: () => {
            notificationStore.closeCurrentNotification();
            window.open(ComponentsHelpPage, '_blank');
          },
          i18nKey: 'objects.section.outdentNotificationAction',
        },
        iconProps: { icon: 'info-circle' },
        type: 'blue',
        timeout: 10000,
      });
    }
  };

  return { onAdd, onCopy, onDelete, onUpdate, onIndent, onOutdent };
}
