import { OrderDependencyEntity } from '@/common/types';
import { getDependencyIdOfTypeName } from '@/helpers/orders/dependencies';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import { SchedulerDependency } from '@/services/store/schedule/types';

import { DryingBreakEventParser } from './dryingBreak';

export class OrderDependencyParser
  implements ScheduleModelParser<OrderDependencyEntity, never, SchedulerDependency>
{
  public entityToModel(dependency: OrderDependencyEntity): SchedulerDependency {
    const fromId = dependency.from?.id ?? '';
    const toId = dependency.to?.id ?? '';

    return {
      id: dependency.id,
      from: dependency.useDryingBreak
        ? DryingBreakEventParser.orderIdToDryingBreakId(fromId)
        : fromId,
      to: toId,
      type: getDependencyIdOfTypeName(dependency.type),
      lag: dependency.lagInMinutes + dependency.bufferInMinutes,
      lagUnit: 'm',
    };
  }
}
