export function getZoomLevelFromPreset(currentPresets: string[], currentPreset: string): number {
  const level = currentPresets.findIndex((preset) => currentPreset === preset);
  return Math.min(Math.max(0, level), currentPresets.length - 1);
}

export function getDefaultExportPreset(): string {
  return 'my-weekAndDay-2';
}

export function getDefaultZoomPreset(): string {
  return 'my-weekAndDayLetter';
}
