import { defineStore } from 'pinia';
import { Ref } from 'vue';

import { useResendMembershipInvitation } from '@/features/memberships/composables/resendInvitation';
import {
  MembershipStore,
  OwnProjectContributorGroupMembership,
  useMembershipStore,
} from '@/features/memberships/membershipStore';
import { PartialContributorGroup } from '@/features/realTimeCollaboration/rtcController/utils/convertChangeOperation';
import {
  CreateProjectContributorGroupInput,
  CreateProjectContributorGroupMembershipInput,
  DeleteProjectContributorGroupInput,
  DeleteProjectContributorGroupMembershipInput,
  ProjectContributorGroupFragment,
  ProjectContributorGroupMembershipFragment,
  ProjectContributorGroupsSearchQueryVariables,
  ProjectContributorGroupType,
  ProjectPermission,
  TradeFragment,
  UpdateProjectContributorGroupInput,
  UpdateProjectContributorGroupMembershipInput,
  UserNode,
} from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { useLoggingService } from '@/services/logging/composable';
import { useUserStore } from '@/services/store/user';
import { UserInvitationResendType } from '@/utils/analyticsEvents/eventCategories/memberships';
import { HIDDEN_MEMBERSHIP_EMAILS } from '@/utils/config';
import { AppErrorCode } from '@/utils/errors';

import {
  createProjectContributorGroupMembershipMutation,
  createProjectContributorGroupMutation,
  deleteProjectContributorGroupMembershipMutation,
  deleteProjectContributorGroupMutation,
  fetchContributorGroupsSearch,
  fetchQuery,
  updateProjectContributorGroupMembershipMutation,
  updateProjectContributorGroupMutation,
} from './projectContributorGql';

type ContributorGroupMembershipInput = {
  id: string;
  email: string;
  isNew?: boolean;
};

export const useProjectContributorStore = defineStore('project-contributor-store', () => {
  const userStore = useUserStore();
  const loggingService = useLoggingService();
  const membershipStore = useMembershipStore();
  const contributorGroups = ref(new Map<string, ProjectContributorGroupFragment>());
  const { onMembershipCreated, resendInvitation } = useResendMembershipInvitation();

  const contributorGroupList = computed(() => Array.from(contributorGroups.value.values()));
  const loading = ref(true);

  const contributorGroupSearchResults = ref<ProjectContributorGroupFragment[]>([]);

  const managementGroup = computed<ProjectContributorGroupFragment | null>(() => {
    return contributorGroupList.value.find((g) => g.isTenantOwnerGroup) ?? null;
  });

  const userIsManagementGroupMember = (userId: string | undefined) => {
    return computed(() => {
      return (
        !!userId &&
        !!managementGroupMemberships.value.find((membership) => membership.user.id === userId)
      );
    });
  };

  const internalContributorGroups = computed<ProjectContributorGroupFragment[]>(() => {
    return contributorGroupList.value.filter(
      (g) => g.type === ProjectContributorGroupType.Internal && !g.isTenantOwnerGroup,
    );
  });

  const externalContributorGroups = computed<ProjectContributorGroupFragment[]>(() => {
    return contributorGroupList.value.filter(
      (g) => g.type === ProjectContributorGroupType.External,
    );
  });

  const nonManagementContributorGroups = computed<ProjectContributorGroupFragment[]>(() =>
    contributorGroupList.value.filter((g) => !g.isTenantOwnerGroup),
  );

  const managementGroupMemberships = computed<ProjectContributorGroupMembershipFragment[]>(() => {
    return managementGroup.value?.memberships ?? [];
  });

  const internalContributorGroupMemberships = computed<ProjectContributorGroupMembershipFragment[]>(
    () => {
      return internalContributorGroups.value.flatMap((g) => g.memberships);
    },
  );

  const externalContributorGroupMemberships = computed<ProjectContributorGroupMembershipFragment[]>(
    () => {
      return externalContributorGroups.value.flatMap((g) => g.memberships);
    },
  );

  const allMemberships = computed<ProjectContributorGroupMembershipFragment[]>(() => {
    return contributorGroupList.value.flatMap((g) => g.memberships);
  });

  const fetchAllPromise: Ref<Promise<ProjectContributorGroupFragment[]> | null> = ref(null);

  const fetchAll = async (projectId: string): Promise<ProjectContributorGroupFragment[]> => {
    loading.value = true;
    const client = useApolloClient();

    fetchAllPromise.value = client
      .query({
        query: fetchQuery,
        variables: {
          project: projectId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const projectContributorGroups: ProjectContributorGroupFragment[] = flattenNodeConnection(
          result.data.projectContributorGroups,
        );
        contributorGroups.value = new Map(
          projectContributorGroups.map((contributorGroup) => [
            contributorGroup.id,
            contributorGroup,
          ]),
        );

        return projectContributorGroups;
      })
      .finally(() => {
        loading.value = false;
      });
    return fetchAllPromise.value;
  };

  const fetchAllSearchResults = (
    variables: ProjectContributorGroupsSearchQueryVariables,
  ): Promise<ProjectContributorGroupFragment[]> => {
    const client = useApolloClient();

    return client
      .query({
        query: fetchContributorGroupsSearch,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const projectContributorGroupsSearch = flattenNodeConnection(
          result.data.projectContributorGroupsSearch,
        );
        contributorGroupSearchResults.value = projectContributorGroupsSearch;
        return projectContributorGroupsSearch;
      });
  };

  const setPartialState = async (
    partialGroups: Map<string, PartialContributorGroup>,
    projectId: string,
  ) => {
    const previousContributorGroups = new Map(
      Array.from(contributorGroups.value.values()).map((entity) => [entity.id, entity]),
    );

    const updatedGroups = new Map<string, ProjectContributorGroupFragment>();
    const ownUserId = userStore.ownUser?.id;

    const existingUsers = getExistingUsers(membershipStore);

    partialGroups.forEach((partialGroup, groupId) => {
      const existingGroup = previousContributorGroups.get(groupId);

      updatedGroups.set(groupId, {
        ...(existingGroup ?? {}),
        ...partialGroup,
        memberships: getPartialGroupMemberships(partialGroup, existingUsers),
      });
    });

    contributorGroups.value = updatedGroups;

    updateOwnMemberships({ projectId, ownUserId, updatedGroups, membershipStore });
  };

  const updateOwnMemberships = (props: {
    projectId: string;
    ownUserId: string | undefined;
    updatedGroups: Map<string, ProjectContributorGroupFragment>;
    membershipStore: MembershipStore;
  }) => {
    const { projectId, ownUserId, updatedGroups, membershipStore } = props;

    membershipStore.clearOwnContributorGroupProjectMemberships(projectId);

    for (const group of updatedGroups.values()) {
      for (const membership of group.memberships) {
        if (membership.user.id === ownUserId) {
          const newMembership: OwnProjectContributorGroupMembership = {
            ...membership,
            contributorGroup: { ...group, project: { id: projectId } },
            projectId,
            isProjectMembership: group.isTenantOwnerGroup,
          };
          membershipStore.addOwnContributorGroupMembership(newMembership);
        }
      }
    }
  };

  const getExistingUsers = (membershipStore: MembershipStore) => {
    const displayableUsers = new Map(
      membershipStore.displayableTenantMemberships.map((membership) => [
        membership.user.id,
        membership.user as UserNode,
      ]),
    );

    allMemberships.value.forEach((membership) => {
      displayableUsers.set(membership.user.id, membership.user as UserNode);
    });

    return displayableUsers;
  };

  const getPartialGroupMemberships = (
    partialGroup: PartialContributorGroup,
    existingUsers: Map<string, UserNode>,
  ) => {
    return (
      partialGroup.memberships.map((newMembership) => {
        return {
          id: newMembership.id,
          permission: newMembership.permission,
          user: existingUsers.get(newMembership.userId) || {
            id: newMembership.userId,
            email: '',
            isActive: false,
          },
        };
      }) || []
    );
  };

  const createProjectContributorGroup = async ({
    name,
    trades,
    project,
    tenant,
    users,
    permission,
  }: {
    name: string;
    trades: TradeFragment[];
    project: string;
    tenant: string | null;
    users: ContributorGroupMembershipInput[];
    permission: ProjectPermission;
  }) => {
    const client = useApolloClient();
    const tradesWithDefaultContributor = getTradesWithDefaultContributor().value;

    const input: CreateProjectContributorGroupInput = {
      name,
      permission,
      project,
      tradeAssignments: trades.map((trade) => ({
        isDefaultContributorGroup: !tradesWithDefaultContributor.has(trade.id),
        tenantTradeVariation: trade.id,
      })),
      tenant,
    };
    return client
      .mutate({
        mutation: createProjectContributorGroupMutation,
        variables: {
          input,
        },
      })
      .then((result) => {
        const newContributorGroup =
          result.data?.createProjectContributorGroup?.projectContributorGroup;
        if (!newContributorGroup) {
          return null;
        }
        contributorGroups.value.set(newContributorGroup.id, newContributorGroup);
        return createContributorGroupMembers(users, newContributorGroup.id);
      });
  };

  const updateProjectContributorGroup = async ({
    id,
    name,
    permission,
    trades,
    users,
  }: {
    id: string;
    name?: string;
    permission?: ProjectPermission;
    trades: TradeFragment[];
    users?: {
      added: ContributorGroupMembershipInput[];
      deleted: ContributorGroupMembershipInput[];
    };
  }) => {
    const client = useApolloClient();
    const tradesWithDefaultContributor = getTradesWithDefaultContributor().value;
    const existingGroup = contributorGroups.value.get(id);
    if (!existingGroup) return;

    const input: UpdateProjectContributorGroupInput = {
      id,
      name,
      permission,
      tradeAssignments: trades.map((trade) => {
        const existingTradeAssignment = existingGroup.tenantTradeVariationAssignments.find(
          (assignment) => assignment.tenantTradeVariation.id === trade.id,
        );
        if (existingTradeAssignment) {
          return {
            tenantTradeVariation: trade.id,
            isDefaultContributorGroup: existingTradeAssignment.isDefaultContributor,
          };
        }

        return {
          isDefaultContributorGroup: !tradesWithDefaultContributor.has(trade.id),
          tenantTradeVariation: trade.id,
        };
      }),
    };

    const updateContributorGroup = client
      .mutate({
        mutation: updateProjectContributorGroupMutation,
        variables: {
          input,
        },
      })
      .then((result) => {
        const updatedProjectContributorGroup =
          result.data?.updateProjectContributorGroup?.projectContributorGroup;
        if (!updatedProjectContributorGroup) {
          return null;
        }
        const oldContributorGroup = contributorGroups.value.get(updatedProjectContributorGroup.id);
        if (!oldContributorGroup) {
          return null;
        }
        contributorGroups.value.set(oldContributorGroup.id, {
          ...updatedProjectContributorGroup,
          memberships: oldContributorGroup.memberships,
        });
      });

    return Promise.all([
      updateContributorGroup,
      ...(users
        ? [
            createContributorGroupMembers(users.added, id),
            deleteContributorGroupMembers(users.deleted, id),
          ]
        : []),
    ]);
  };

  const setProjectContributorDefaultTrade = (args: {
    contributorGroupId: string;
    tradeId: string;
    isDefaultContributor: boolean;
  }) => {
    contributorGroups.value = new Map(
      Array.from(contributorGroups.value.values()).map((contributorGroup) =>
        contributorGroup.id === args.contributorGroupId
          ? [
              args.contributorGroupId,
              {
                ...contributorGroup,
                tenantTradeVariationAssignments:
                  contributorGroup.tenantTradeVariationAssignments.map((t) => ({
                    ...t,
                    isDefaultContributor:
                      t.tenantTradeVariation.id === args.tradeId
                        ? args.isDefaultContributor
                        : t.isDefaultContributor,
                  })),
              },
            ]
          : [contributorGroup.id, contributorGroup],
      ),
    );
  };

  const updateProjectContributorDefaultTrade = async (args: {
    contributorGroupId: string;
    tradeId: string;
    isDefaultContributor: boolean;
  }) => {
    const { contributorGroupId, tradeId, isDefaultContributor } = args;
    const group = contributorGroups.value.get(contributorGroupId);
    if (!group) return;
    // Optimistic toggling
    setProjectContributorDefaultTrade(args);

    try {
      const client = useApolloClient();
      const input: UpdateProjectContributorGroupInput = {
        id: contributorGroupId,
        tradeAssignments: group.tenantTradeVariationAssignments.map((t) => ({
          isDefaultContributorGroup:
            t.tenantTradeVariation.id === tradeId ? isDefaultContributor : t.isDefaultContributor,
          tenantTradeVariation: t.tenantTradeVariation.id,
        })),
      };

      await client
        .mutate({
          mutation: updateProjectContributorGroupMutation,
          variables: {
            input,
          },
        })
        .then((result) => {
          const updatedProjectContributorGroup =
            result.data?.updateProjectContributorGroup?.projectContributorGroup;
          if (!updatedProjectContributorGroup) {
            return null;
          }
        });
    } catch (e) {
      // undo optimistic change
      setProjectContributorDefaultTrade({
        ...args,
        isDefaultContributor: !args.isDefaultContributor,
      });
      loggingService.error(e as Error, { code: 'Setting default trade failed' });
    }
  };

  const updateProjectContributorGroupTrades = async (id: string, trades: TradeFragment[]) => {
    return updateProjectContributorGroup({ id, trades });
  };

  const updateProjectContributorGroupMembers = (
    users: {
      added: ContributorGroupMembershipInput[];
      deleted: ContributorGroupMembershipInput[];
    },
    contributorGroupId: string,
  ) => {
    return Promise.all([
      createContributorGroupMembers(users.added, contributorGroupId),
      deleteContributorGroupMembers(users.deleted, contributorGroupId),
    ]);
  };

  const deleteProjectContributorGroup = (input: DeleteProjectContributorGroupInput) => {
    const client = useApolloClient();
    return client.mutate({
      mutation: deleteProjectContributorGroupMutation,
      variables: {
        input,
      },
    });
  };

  const createContributorGroupMembers = (
    users: ContributorGroupMembershipInput[],
    contributorGroupId: string,
  ) => {
    return Promise.all(
      users.map(async (user) => {
        return createContributorGroupMember({
          contributorGroup: contributorGroupId,
          ...(user.isNew
            ? {
                newUser: { email: user.email },
              }
            : { user: user.id }),
          permission: ProjectPermission.ProgressReporter,
        }).catch((error) => {
          loggingService.error(error, { code: AppErrorCode.OPERATION_FAILED });
        });
      }),
    );
  };

  const createContributorGroupMember = async (
    input: CreateProjectContributorGroupMembershipInput,
  ) => {
    const client = useApolloClient();
    await client
      .mutate({
        mutation: createProjectContributorGroupMembershipMutation,
        variables: {
          input,
        },
      })
      .then((result) => {
        const newMembership =
          result.data?.createProjectContributorGroupMembership?.projectContributorGroupMembership;
        if (!newMembership) {
          return null;
        }
        onMembershipCreated(newMembership);

        const contributorGroup = contributorGroups.value.get(input.contributorGroup);
        if (!contributorGroup) {
          return null;
        }
        contributorGroups.value.set(contributorGroup.id, {
          ...contributorGroup,
          memberships: [...contributorGroup.memberships, newMembership],
        });
      });
  };

  const updateContributorGroupMember = async (
    input: UpdateProjectContributorGroupMembershipInput,
    contributorGroupId: string,
  ) => {
    const client = useApolloClient();
    await client
      .mutate({
        mutation: updateProjectContributorGroupMembershipMutation,
        variables: {
          input,
        },
      })
      .then((result) => {
        const updatedMembershipResult =
          result.data?.updateProjectContributorGroupMembership?.projectContributorGroupMembership;
        if (!updatedMembershipResult) {
          return null;
        }
        const contributorGroup = contributorGroups.value.get(contributorGroupId);
        if (!contributorGroup) {
          return null;
        }
        const membershipIndex = contributorGroup.memberships.findIndex((membership) => {
          return membership.id === updatedMembershipResult.id;
        });
        if (membershipIndex === -1) {
          return null;
        }
        const updatedMembership = {
          ...contributorGroup.memberships[membershipIndex]!,
          ...updatedMembershipResult,
        };
        contributorGroups.value.set(contributorGroup.id, {
          ...contributorGroup,
          memberships: [
            ...contributorGroup.memberships.slice(0, membershipIndex),
            updatedMembership,
            ...contributorGroup.memberships.slice(membershipIndex + 1),
          ],
        });
      });
  };

  const deleteContributorGroupMembers = async (
    users: ContributorGroupMembershipInput[],
    contributorGroupId: string,
  ) => {
    const contributorGroup = contributorGroups.value.get(contributorGroupId);
    if (!contributorGroup) return;

    const membershipIds: string[] = users
      .map(
        (user) =>
          contributorGroup.memberships.find((membership) => membership.user.email === user.email)
            ?.id,
      )
      .filter(Boolean) as string[];

    return Promise.all(
      membershipIds.map(async (membershipId) => {
        return deleteContributorGroupMember(
          {
            id: membershipId,
          },
          contributorGroupId,
        ).catch((error) => {
          loggingService.error(error, { code: AppErrorCode.OPERATION_FAILED });
        });
      }),
    );
  };

  const deleteContributorGroupMember = async (
    input: DeleteProjectContributorGroupMembershipInput,
    contributorGroupId: string,
  ) => {
    const client = useApolloClient();
    await client
      .mutate({
        mutation: deleteProjectContributorGroupMembershipMutation,
        variables: {
          input,
        },
      })
      .then(() => {
        const contributorGroup = contributorGroups.value.get(contributorGroupId);
        if (!contributorGroup) {
          return null;
        }
        const updatedMemberships = contributorGroup.memberships.filter((membership) => {
          return membership.id !== input.id;
        });
        contributorGroups.value.set(contributorGroup.id, {
          ...contributorGroup,
          memberships: updatedMemberships,
        });
      });
  };

  const reset = () => {
    fetchAllPromise.value = null;
    contributorGroups.value = new Map();
  };

  const copyState = () => new Map(contributorGroups.value);

  const checkMemberWithEmailExists = (email: string) => {
    return allMemberships.value.some(({ user }) => user.email === email);
  };

  const getTradesWithDefaultContributor = () =>
    computed(
      () =>
        new Set<string>(
          contributorGroupList.value.flatMap((contributorGroup) =>
            contributorGroup.tenantTradeVariationAssignments
              .filter((assignment) => assignment.isDefaultContributor)
              .map((assignment) => assignment.tenantTradeVariation.id),
          ),
        ),
    );

  const resendMembershipInvitation = async (membershipId: string) => {
    const membershipGroup = contributorGroupList.value.find((group) =>
      group.memberships.some((m) => m.id === membershipId),
    );
    if (!membershipGroup) return;

    let invitationType: UserInvitationResendType = 'project_management';
    if (membershipGroup.type === ProjectContributorGroupType.External) {
      invitationType = 'external_cg';
    } else {
      if (!membershipGroup.isTenantOwnerGroup) invitationType = 'internal_cg';
    }
    const result = await resendInvitation(membershipId, invitationType);
    if (result?.user?.isActive) {
      //Search again in case group was deleted in the mean time
      const updatedGroup = contributorGroups.value.get(membershipGroup.id);
      if (!updatedGroup) return;

      const newMemberships = updatedGroup.memberships.map((m) =>
        m.id === membershipId ? { ...m, user: { ...m.user, ...result.user } } : m,
      );
      contributorGroups.value.set(membershipGroup.id, {
        ...updatedGroup,
        memberships: newMemberships,
      });
    }
  };

  return {
    contributorGroups,
    contributorGroupList,
    contributorGroupSearchResults,
    managementGroup,
    userIsManagementGroupMember,
    tradeHasDefaultContributor: (tradeId: string) => {
      return getTradesWithDefaultContributor().value.has(tradeId);
    },
    displayableProjectMembers: computed(() => {
      return (
        managementGroup.value?.memberships?.filter(
          (membership) => !HIDDEN_MEMBERSHIP_EMAILS.has(membership.user.email),
        ) || []
      );
    }),
    internalContributorGroups,
    externalContributorGroups,
    nonManagementContributorGroups,
    managementGroupMemberships,
    internalContributorGroupMemberships,
    externalContributorGroupMemberships,
    allMemberships,
    loading,
    fetchAll,
    fetchAllSearchResults,
    createProjectContributorGroup,
    updateProjectContributorGroup,
    updateProjectContributorDefaultTrade,
    updateProjectContributorGroupTrades,
    updateProjectContributorGroupMembers,
    deleteProjectContributorGroup,
    createContributorGroupMembers,
    createContributorGroupMember,
    updateContributorGroupMember,
    deleteContributorGroupMember,
    reset,
    copyState,
    setPartialState,
    checkMemberWithEmailExists,
    getTradesWithDefaultContributor,
    resendMembershipInvitation,
  };
});

export type ProjectContributorStore = ReturnType<typeof useProjectContributorStore>;
