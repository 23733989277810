<template>
  <div class="tw-absolute tw-place-self-center tw-top-0 tw-flex tw-justify-center">
    <DsChip :custom-style="{ color: '#F04438' }" :blend="false" closable @close="exitPlanningMode">
      {{ $t('objects.projectAlternatives.title') }}
    </DsChip>
  </div>
</template>
<script setup lang="ts">
import { useProjectAlternativeStore } from '../store/projectAlternativeStore';

const alternativeStore = useProjectAlternativeStore();

const exitPlanningMode = () => {
  alternativeStore.exitPlanningMode();
};
</script>
