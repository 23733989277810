import { defineStore } from 'pinia';

import { ScheduleConstants } from '@/common/bryntum/constants';

import { getOpenPopupComponent } from './state';
import { OpenSchedulerPopupOptions, SchedulerPopupComponent, SchedulerPopupState } from './types';

export const useSchedulePopupStore = defineStore('schedule-popup-store', () => {
  const popup = ref<null | SchedulerPopupState>(null);

  // Returns true if the provided component is currently visible as popup.
  const isOpenPopup = (component: SchedulerPopupComponent) => Boolean(popup.value?.[component]);

  const openPopup = <T extends SchedulerPopupComponent>(data: OpenSchedulerPopupOptions<T>) => {
    const { component, payload, patient, submissive } = data;

    if (popup.value && getOpenPopupComponent(popup.value) !== component && submissive) {
      return;
    }

    if (popup.value && patient) {
      closePopup();
      return;
    }

    popup.value = { [component]: payload };
  };
  const closePopup = (componentToClose?: SchedulerPopupComponent) => {
    const isOrderPopup =
      Boolean(popup.value?.[0]?.schedulingDate) && componentToClose === undefined;
    const timeout = isOrderPopup ? ScheduleConstants.DOUBLE_CLICK_TIMEOUT : 0;

    setTimeout(() => {
      if (
        componentToClose === undefined ||
        getOpenPopupComponent(popup.value) === componentToClose
      ) {
        popup.value = null;
      }
    }, timeout);
  };

  const reset = () => {
    popup.value = null;
  };

  return { popup, isOpenPopup, openPopup, closePopup, reset };
});

export type SchedulePopupStore = ReturnType<typeof useSchedulePopupStore>;
