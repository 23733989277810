// rendered header size is a hardcoded value
// and required for the pdf export to work properly
export const basePresets = {
  'my-year': {
    id: 'my-year' as const,
    name: 'Years',
    base: 'year',
    tickWidth: 100,
    renderedHeaderSize: 75.5,
  },
  'my-monthAndYear': {
    id: 'my-monthAndYear' as const,
    name: 'Months',
    base: 'monthAndYear',
    tickWidth: 110,
    renderedHeaderSize: 75.5,
  },
  'my-weekDateAndMonth': {
    id: 'my-weekDateAndMonth' as const,
    name: 'Months/weeks',
    base: 'weekDateAndMonth',
    tickWidth: 30,
    renderedHeaderSize: 75.5,
  },
  'my-weekDateAndMonth-2': {
    id: 'my-weekDateAndMonth-2' as const,
    name: 'Months/weeks-2',
    base: 'weekDateAndMonth',
    tickWidth: 60,
    renderedHeaderSize: 75.5,
  },
  'my-weekAndMonth': {
    id: 'my-weekAndMonth' as const,
    name: 'Weeks',
    base: 'weekAndMonth',
    tickWidth: 100,
    renderedHeaderSize: 75.5,
  },
  'my-weekAndMonth-2': {
    id: 'my-weekAndMonth-2' as const,
    name: 'Weeks-2',
    base: 'weekAndMonth',
    tickWidth: 150,
    renderedHeaderSize: 75.5,
  },
  'my-weekAndDayLetter': {
    id: 'my-weekAndDayLetter' as const,
    name: 'Weeks/weekdays',
    base: 'weekAndDayLetter',
    tickWidth: 40,
    renderedHeaderSize: 105.5,
  },
  'my-weekAndDay': {
    id: 'my-weekAndDay' as const,
    name: 'Week',
    base: 'weekAndDay',
    tickWidth: 60,
    renderedHeaderSize: 105.5,
  },
  'my-weekAndDay-2': {
    id: 'my-weekAndDay-2' as const,
    name: 'Week-2',
    base: 'weekAndDay',
    tickWidth: 120,
    renderedHeaderSize: 105.5,
  },
  'my-hourAndDay': {
    id: 'my-hourAndDay' as const,
    name: 'Day',
    base: 'hourAndDay',
    tickWidth: 30,
    renderedHeaderSize: undefined, // never used in PDF export
  },
  'my-hourAndDay-2': {
    id: 'my-hourAndDay-2' as const,
    name: 'Day-2',
    base: 'hourAndDay',
    tickWidth: 70,
    renderedHeaderSize: undefined, // never used in PDF export
  },
};
