import { InvokedConstraint } from '@koppla-tech/scheduling-engine';

import { useLoggingService } from '@/services/logging/composable';

import { useNotificationStore } from '../notifications';
import { DependencyViolationOverruledEvent } from './orderDependencyAnalytics';
import { useOrderDependencyStore } from './orderDependencyStore';

export function showOverruleExistingDependenciesNotification(
  invokedConstraints: InvokedConstraint[],
): void {
  const notificationStore = useNotificationStore();
  const loggingService = useLoggingService();

  const affectedOrderIds = new Set(invokedConstraints.map((constraint) => constraint.toId));
  const dependenciesToOverrule = invokedConstraints.map((constraint) => ({
    id: constraint.dependencyId,
    lagInMinutes: constraint.lagToOverrule,
  }));

  notificationStore.push({
    titleI18nKey: 'orderDependencies.overruleExistingLagNotificationTitle',
    titleI18nKeyVariables: {
      count: affectedOrderIds.size,
    },
    bodyI18nKey: 'orderDependencies.overruleExistingLagNotificationDescription',
    bodyI18nKeyVariables: {
      count: affectedOrderIds.size,
    },
    primaryAction: {
      i18nKey: 'orderDependencies.overruleExistingLagNotificationAction',
      callback: () => {
        const dependencyStore = useOrderDependencyStore();
        dependencyStore.update(dependenciesToOverrule);
        loggingService.trackEvent(new DependencyViolationOverruledEvent({ source: 'schedule' }));
      },
    },
    type: 'blue',
    timeout: 5000,
  });
}

export function showOverruleNewDependencyNotification(data: {
  dependencyId: string;
  lagInMinutes: number;
}): void {
  const notificationStore = useNotificationStore();
  const loggingService = useLoggingService();

  notificationStore.push({
    titleI18nKey: 'orderDependencies.overruleNewLagNotificationTitle',
    bodyI18nKey: 'orderDependencies.overruleNewLagNotificationDescription',
    primaryAction: {
      i18nKey: 'orderDependencies.overruleNewLagNotificationAction',
      callback: () => {
        const dependencyStore = useOrderDependencyStore();
        dependencyStore.update([
          {
            id: data.dependencyId,
            lagInMinutes: data.lagInMinutes,
          },
        ]);
        loggingService.trackEvent(new DependencyViolationOverruledEvent({ source: 'schedule' }));
      },
    },
    type: 'blue',
    timeout: 5000,
  });
}
