import { differenceInMilliseconds } from 'date-fns';

import { Entity } from '@/interfaces/repositories/base';
import { NodeName } from '@/repositories/utils/cache';
import { DurationUnit, SchedulerEvent } from '@/services/store/schedule/types';

export class BaseScheduleEventParser {
  private readonly selectableEvents = new Set<NodeName>([
    NodeName.MILESTONE,
    NodeName.PAUSE,
    NodeName.ORDER,
  ]);

  public parseSharedInput<T extends Entity & { name?: string | null }>(
    event: T,
    startKey: keyof T,
    endKey: keyof T,
    entity: NodeName,
  ): Omit<SchedulerEvent, 'resourceIds' | 'eventColor'> {
    const startDate = new SchedulingDate(event[startKey] as never);
    const endDate = new SchedulingDate(event[endKey] as never);
    return {
      id: event.id,
      name: event.name ?? '',
      startDate,
      endDate,
      duration: differenceInMilliseconds(endDate, startDate),
      durationUnit: DurationUnit.MILLISECOND,
      entity,
      isSelectable: this.selectableEvents.has(entity),
      isFixed: false,
      collisionCounter: null,
    };
  }
}

export const schedulerIconConfig: Record<string, string> = {
  [NodeName.PAUSE]: 'b-fa b-fa-coffee',
};

export const schedulerClassConfig: Record<string, string> = {
  [NodeName.PAUSE]: 'pause-event',
  [NodeName.MILESTONE]: 'milestone-event',
  [NodeName.COLLISION]: 'collision-event',
  [NodeName.ORDER]: 'order-event',
  [NodeName.HOLIDAY]: 'holiday-event',
  [NodeName.SECTION]: 'section-summary-event',
  [NodeName.SECTION_BASEPLAN]: 'section-baseplan-summary-event',
  [NodeName.DRYING_BREAK]: 'drying-break-event',
};

export function getMainResourceId(): string {
  return 'project-main-resource-slot';
}

export function getCollisionResourceId(): string {
  return 'project-collision-resource-slot';
}

export function getEmptyResourceId(): string {
  return 'project-empty-resource-slot';
}

export function getPlaceholderEventId(): string {
  return 'placeholder-event';
}
