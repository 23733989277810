import { Composer } from 'vue-i18n';

import { WbsSection } from '../types';
import { getSectionSiblings } from './sectionTreeUtils';

export function useSectionNameGeneration({ t }: Composer) {
  const generateNewSectionName = (
    section: WbsSection,
    allSections: WbsSection[] | Map<string, WbsSection>,
    {
      level = 'bottom',
      copyName = false,
    }: {
      level?: 'top' | 'mid' | 'bottom';
      copyName?: boolean;
    } = {},
  ): string => {
    const siblingSectionNames = getSectionSiblings(allSections, section).map((s) => s.name);

    let newSectionName = '';
    if (copyName) {
      newSectionName = section.name;
    } else if (level === 'top') {
      newSectionName = t('objects.section.placeHolderTopLevel');
    } else if (level === 'mid') {
      newSectionName = t('objects.section.placeHolderMidLevel');
    } else {
      newSectionName = t('objects.section.placeHolderBottomLevel');
    }

    if (!siblingSectionNames.includes(newSectionName)) return newSectionName;

    const generateNameWithAppendix = (appendixNumber: number) =>
      `${newSectionName} - ${appendixNumber}`;

    let numberToUse = 1;
    while (siblingSectionNames.includes(generateNameWithAppendix(numberToUse))) {
      numberToUse++;
    }
    return generateNameWithAppendix(numberToUse);
  };

  return {
    generateNewSectionName,
  };
}
