import { Composer } from 'vue-i18n';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { FeatureAccessStore, useFeatureAccessStore } from '@/common/featureAccessStore';
import { createMilestone, createOrder } from '@/components/schedule/utils';
import { MilestoneStore, useMilestoneStore } from '@/features/milestones';
import {
  checkAreEventsInResourceStacked,
  overlapEventsInResource,
  stackEventsInResource,
} from '@/features/schedule/bryntum/resources/layout';
import { debounce } from '@/helpers/utils/functions';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import {
  DependencyListeners,
  getDependencyListeners,
} from '@/services/store/schedule/config/listeners/dependencies';
import {
  EventListeners,
  getAdjustedPopupMouseEvent,
  getEventListeners,
} from '@/services/store/schedule/config/listeners/events';
import { getRenderingListeners } from '@/services/store/schedule/config/listeners/rendering';
import { getRowReorderListeners } from '@/services/store/schedule/config/listeners/rowReorder';
import {
  getScrollListeners,
  ScrollListeners,
} from '@/services/store/schedule/config/listeners/scroll';
import {
  getCollisionResourceId,
  getEmptyResourceId,
  getMainResourceId,
} from '@/services/store/schedule/parsers/base';
import {
  SchedulerResource,
  SchedulerSidebarComponent,
  ScheduleScrollHelper,
} from '@/services/store/schedule/types';
import { isCreateSidebar, isEditSidebar } from '@/services/store/schedule/utils/sidebar';
import {
  SchedulePopupStore,
  SchedulerPopupComponent,
  useSchedulePopupStore,
} from '@/services/store/schedulePopup';

import { getOpenSidebarComponent } from '../../actions/sidebar';
import { eventHasValidResource } from '../../utils/eventResourceAssignment';
import { openPopupWithTimeout } from '../../utils/popup';

interface ScheduleListeners extends DependencyListeners, EventListeners, ScrollListeners {
  scheduleClick: (data: {
    event: MouseEvent;
    date: Date;
    resourceRecord: SchedulerResource;
    tickStartDate: Date;
    tickEndDate: Date;
  }) => void;
  scheduleDblClick: (data: { date: Date; resourceRecord: SchedulerResource }) => void;
  scheduleContextMenu: (data: {
    event: MouseEvent;
    resourceRecord: SchedulerResource;
    tickStartDate: Date;
    tickEndDate: Date;
  }) => void;
  cellmouseover: () => void;
  cellmousedown: (data: { event: MouseEvent }) => void;
  mouseover: (data: { event: MouseEvent }) => void;
  visibleDateRangeChange: (data: {
    new: { startDate: Date; endDate: Date };
    old: { startDate: Date; endDate: Date };
  }) => void;
}

// Keep track of scroll state outside of reactive property as these currently
// cause performance issues during scrolling.
const scrollHelper: ScheduleScrollHelper = { isScrolling: false };

const lastCellMouseDownPosition = { clientX: 0, clientY: 0 };
/**
 * Determine if the click event is an actual click or the final mouse release
 * after a selection was made. Bryntum uses the same `scheduleClick` callback in both cases.
 * Consequently, we can only approximate based on the distance the mouse traveled since the last mousedown event.
 * When dragging over multiple rows, the `scheduleClick` event is not triggered and we can correctly distinguish.
 */
function checkDragSelectIsMoreLikelyThanClick(event: MouseEvent): boolean {
  return (
    Math.abs(event.clientX - lastCellMouseDownPosition.clientX) > 10 ||
    Math.abs(event.clientY - lastCellMouseDownPosition.clientY) > 10
  );
}

export function getScheduleListeners(
  store: ScheduleStore,
  i18n: Composer,
  loggingService: LoggingService,
): ScheduleListeners {
  const isDoubleClick = ref(false);
  const schedulePopupStore = useSchedulePopupStore();
  const featureAccessStore = useFeatureAccessStore();
  const milestoneStore = useMilestoneStore();

  return {
    scheduleClick(data): void {
      const { event, date: localDate, resourceRecord, tickStartDate, tickEndDate } = data;
      const schedulingDate = new SchedulingDate(localDate);
      const schedulingTickStart = new SchedulingDate(tickStartDate);
      const schedulingTickEnd = new SchedulingDate(tickEndDate);

      if (checkDragSelectIsMoreLikelyThanClick(event)) return;
      if (
        resourceRecord.id === getEmptyResourceId() ||
        resourceRecord.id === getCollisionResourceId() ||
        store.readonly
      ) {
        store.closeSidebar();
        return;
      }
      if (store.clipboard) {
        handleClipboard({
          event,
          schedulingDate,
          startDate: schedulingTickStart,
          endDate: schedulingTickEnd,
          resourceRecord,
          store,
          schedulePopupStore,
        });

        return;
      }

      const currentOpenSidebar = getOpenSidebarComponent(store.sidebar);

      if (currentOpenSidebar && currentOpenSidebar === SchedulerSidebarComponent.ORDER_MULTI_EDIT) {
        store.filterAndSetMultiSelectedEvents();
      }

      if (
        currentOpenSidebar &&
        (isEditSidebar(currentOpenSidebar) || isCreateSidebar(currentOpenSidebar))
      ) {
        return;
      }

      if (
        currentOpenSidebar &&
        !(isEditSidebar(currentOpenSidebar) || isCreateSidebar(currentOpenSidebar))
      ) {
        store.closeSidebar();
        return;
      }

      if (store.utils.hasEventSelection) {
        store.closeSidebar();
        return;
      }

      if (store.isMultiSelectToolbarOpen) {
        store.utils.allowEventDeselection = true;
        return;
      }

      if (resourceRecord) {
        handleResourceRecordClick({
          event,
          resourceRecord,
          schedulingDate,
          isDoubleClick,
          loggingService,
          featureAccessStore,
          milestoneStore,
        });
        return;
      }
    },
    scheduleDblClick: ({ resourceRecord, date }) => {
      const schedulingDate = new SchedulingDate(date);
      const currentOpenSidebar = getOpenSidebarComponent(store.sidebar);
      const isReadonlyOrDisabled =
        store.readonly ||
        (currentOpenSidebar &&
          !isCreateSidebar(currentOpenSidebar) &&
          store.utils.disableEventInteractions);

      if (
        resourceRecord.id === getEmptyResourceId() ||
        resourceRecord.id === getCollisionResourceId() ||
        isReadonlyOrDisabled
      ) {
        return;
      }

      isDoubleClick.value = true;

      if (resourceRecord.isBottomLevelRow && featureAccessStore.hasWriteAccessToScheduleData) {
        createOrder(schedulingDate, resourceRecord.id, 'double_click');
      } else if (
        !resourceRecord.isBottomLevelRow &&
        featureAccessStore.hasWriteAccessToPausesAndMilestones
      ) {
        createMilestone(schedulingDate, resourceRecord.id, 'double_click');
      }

      setTimeout(() => {
        isDoubleClick.value = false;
      }, ScheduleConstants.DOUBLE_CLICK_TIMEOUT);
    },
    visibleDateRangeChange: debounce((event) => {
      store.updateVisibleDateRange(event.new);
    }, 100),
    scheduleContextMenu: ({ event, resourceRecord, tickStartDate, tickEndDate }) => {
      const schedulingTickStart = new SchedulingDate(tickStartDate);
      const schedulingTickEnd = new SchedulingDate(tickEndDate);
      event.preventDefault();
      if (
        [getEmptyResourceId(), getCollisionResourceId()].includes(resourceRecord.id) ||
        store.readonly
      ) {
        return;
      }

      if (store.clipboard) {
        const isValid = eventHasValidResource(
          {
            entity: NodeName.ORDER,
          },
          resourceRecord.id,
        );

        store.updateClipboardContext(
          isValid
            ? {
                startDate: schedulingTickStart,
                endDate: schedulingTickEnd,
                resourceId: resourceRecord.id,
              }
            : null,
        );

        schedulePopupStore.openPopup({
          component: SchedulerPopupComponent.SCHEDULE_CONTEXT_MENU,
          payload: {
            mouseEvent: event,
            schedulingDate: schedulingTickStart,
            resource: resourceRecord.id,
            valid: isValid,
          },
        });
      }
    },
    cellmouseover(): void {
      if (scrollHelper.isScrolling) {
        return;
      }
      schedulePopupStore.closePopup(SchedulerPopupComponent.EVENT_HOVER);
    },
    cellmousedown({ event }): void {
      lastCellMouseDownPosition.clientX = event.clientX;
      lastCellMouseDownPosition.clientY = event.clientY;
    },
    mouseover: ({ event }) => {
      if (scrollHelper.isScrolling || store.utils.showDragCursor) {
        return;
      }
      const path = event.composedPath() || (event as Dictionary).path;
      if (!path.length) return;
      const targetElement = path[0] as HTMLElement;
      const gridHeaderEntered = targetElement?.classList?.contains('b-grid-headers-normal');
      const timeRangeHeaderEventEntered = targetElement?.classList?.contains('b-sch-timerange');

      if (gridHeaderEntered) {
        schedulePopupStore.closePopup(SchedulerPopupComponent.EVENT_HOVER);
      } else if (timeRangeHeaderEventEntered) {
        const scheduler = getScheduler();
        if (scheduler?.selectedEvents.length) {
          return;
        }
        const eventId = targetElement?.getAttribute('data-id') ?? null;
        if (!eventId) return;

        schedulePopupStore.openPopup({
          component: SchedulerPopupComponent.EVENT_HOVER,
          payload: {
            entity: NodeName.HOLIDAY,
            id: eventId,
            mouseEvent: getAdjustedPopupMouseEvent(new MouseEvent('custom'), targetElement),
          },
          submissive: true,
        });
      }
    },
    ...getDependencyListeners(store, loggingService),
    ...getEventListeners({
      featureAccessStore,
      i18n,
      loggingService,
      milestoneStore,
      schedulePopupStore,
      scrollHelper,
      store,
    }),
    ...getScrollListeners(store, scrollHelper),
    ...getRowReorderListeners(store, loggingService, featureAccessStore),
    ...getRenderingListeners(),
  };
}

interface HandleClipboardParams {
  event: MouseEvent;
  schedulingDate: SchedulingDate;
  startDate: SchedulingDate;
  endDate: SchedulingDate;
  resourceRecord: SchedulerResource;
  store: ScheduleStore;
  schedulePopupStore: SchedulePopupStore;
}

function handleClipboard({
  event,
  schedulingDate,
  startDate,
  endDate,
  resourceRecord,
  store,
  schedulePopupStore,
}: HandleClipboardParams): void {
  event.preventDefault();

  const isValid = eventHasValidResource(
    {
      entity: NodeName.ORDER,
    },
    resourceRecord.id,
  );

  store.updateClipboardContext(
    isValid
      ? {
          startDate,
          endDate,
          resourceId: resourceRecord.id,
        }
      : null,
  );

  schedulePopupStore.openPopup({
    component: SchedulerPopupComponent.SCHEDULE_CONTEXT_MENU,
    payload: {
      mouseEvent: event,
      schedulingDate,
      resource: resourceRecord.id,
      valid: isValid,
    },
  });
}

interface HandleResourceRecordParams {
  event: MouseEvent;
  featureAccessStore: FeatureAccessStore;
  isDoubleClick: Ref<boolean>;
  loggingService: LoggingService;
  milestoneStore: MilestoneStore;
  resourceRecord: SchedulerResource;
  schedulingDate: SchedulingDate;
}

function handleResourceRecordClick(handleResourceRecordParams: HandleResourceRecordParams): void {
  const { resourceRecord } = handleResourceRecordParams;
  if (resourceRecord.id === getMainResourceId()) {
    handleMainResourceClick(handleResourceRecordParams);
  } else if (resourceRecord.isTopLevelRow || resourceRecord.isMidLevelRow) {
    handleTopAndMidLevelResourceClick(handleResourceRecordParams);
  } else {
    handleDefaultResourceClick(handleResourceRecordParams);
  }
}

function handleMainResourceClick({
  featureAccessStore,
  schedulingDate,
  isDoubleClick,
}: HandleResourceRecordParams) {
  if (featureAccessStore.hasWriteAccessToPausesAndMilestones) {
    openPopupWithTimeout(
      SchedulerPopupComponent.SCHEDULE_MAIN_CLICK,
      { mouseEvent: event, schedulingDate },
      isDoubleClick,
    );
  }
}

function handleTopAndMidLevelResourceClick(handleResourceRecordParams: HandleResourceRecordParams) {
  const {
    event,
    resourceRecord,
    schedulingDate,
    isDoubleClick,
    loggingService,
    featureAccessStore,
    milestoneStore,
  } = handleResourceRecordParams;

  const hasOverlappingMilestones = milestoneStore.checkDateHasOverlappingMilestones(
    schedulingDate,
    resourceRecord,
  );

  if (checkAreEventsInResourceStacked(resourceRecord)) {
    overlapEventsInResource(resourceRecord, loggingService);
  } else if (!hasOverlappingMilestones) {
    if (featureAccessStore.hasWriteAccessToPausesAndMilestones) {
      openPopupWithTimeout(
        SchedulerPopupComponent.SCHEDULE_SUMMARY_SECTION_CLICK,
        { mouseEvent: event, schedulingDate, wbsSectionId: resourceRecord.id },
        isDoubleClick,
      );
    }
  } else {
    stackEventsInResource(resourceRecord, loggingService);
  }
}

function handleDefaultResourceClick({
  featureAccessStore,
  isDoubleClick,
  resourceRecord,
  schedulingDate,
}: HandleResourceRecordParams) {
  if (featureAccessStore.hasWriteAccessToScheduleData) {
    openPopupWithTimeout(
      SchedulerPopupComponent.SCHEDULE_CLICK,
      { mouseEvent: event, schedulingDate, resource: resourceRecord.id },
      isDoubleClick,
    );
  }
}
