import { SchedulerProConfig } from '@bryntum/schedulerpro';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { sortOverlappingEvents } from '@/common/bryntum/sortOverlappingEvents';
import { EVENT_LAYOUT_STACK } from '@/features/schedule/bryntum/resources/const';
import { SchedulerEvent } from '@/services/store/schedule/types';

export const baseSchedulerProConfig: Partial<SchedulerProConfig> = {
  autoAdjustTimeAxis: false,
  barMargin: ScheduleConstants.MARGIN.BAR,
  enableEventAnimations: true,
  eventLayout: {
    type: EVENT_LAYOUT_STACK,
  },
  milestoneLayoutMode: 'data',
  milestoneTextPosition: 'always-outside',
  overlappingEventSorter(a, b) {
    return sortOverlappingEvents(a as SchedulerEvent, b as SchedulerEvent);
  },
  preserveScrollOnDatasetChange: true,
  projectProgressReporting: null,
  resourceMargin: ScheduleConstants.MARGIN.RESOURCE,
  rowHeight: ScheduleConstants.ROW_HEIGHT,
};
