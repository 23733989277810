import {
  InteractiveEntityChanges,
  InteractiveMilestoneStore,
  MilestoneEntity,
} from '@/common/types';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';
import { SchedulerResource } from '@/services/store/schedule/types';

export interface MilestoneFormInput {
  acceptanceCriteria: ProjectMilestoneAcceptanceCriterion[];
  name: string;
  type: MilestoneType;
  date: SchedulingDate | null;
  color: string;
  isFixed?: boolean; // should be deleted when removed from Scheduler Engine
}

export type MilestoneFormVariables = Partial<MilestoneFormInput> & {
  date: string | Date | null;
  id?: string;
};

export enum MilestoneType {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
}

export type ProjectMilestoneAcceptanceCriterion = MilestoneEntity['acceptanceCriteria'][0];

export interface MilestoneStore extends InteractiveMilestoneStore {
  milestoneList: MilestoneEntity[];
  overlappingMilestonesByDateAndResource: Record<string, number>;
  updateMilestoneStatus: (
    vars: OperationInputType<'UpdateMilestoneStatus'>,
    context?: ProjectChangeEventContext,
  ) => Promise<InteractiveEntityChanges>;
  checkDateHasOverlappingMilestones: (date: Date, resource: SchedulerResource) => boolean;
  getNumberOfOverlappingMilestonesForDate: (date: Date, resource: SchedulerResource) => number;
}
