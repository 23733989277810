import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

import { useCalendarStore } from '@/features/calendars';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { useProjectAlternativeStore } from '@/features/projectAlternatives';
import { useProjectContributorStore } from '@/features/projectContributors/store/projectContributorStore';
import { useProjectStore } from '@/features/projects/projectStore';
import { useWbsSectionStore } from '@/features/projectStructure/store/sectionStore';
import { useProjectTradeSequenceStore } from '@/features/projectTradeSequences';
import {
  installLocalOrderSchedulingEngine,
  useLocalOrderSchedulingEngine,
} from '@/features/schedule';
import { UndoRedoQueue } from '@/features/undoRedo';
import { installUndoRedoQueue, useUndoRedoQueue } from '@/features/undoRedo/install';
import { AuthenticationService, LoggingService } from '@/interfaces/services';

import { useMultiSelectActionsStore } from '../multiSelectActions/multiSelectActionsStore';
import { useProjectTradeStore } from '../projectTrades';
import { useTenantTradeStore } from '../tenantTemplates/tenantTradeStore';
import { useTradeSequenceTemplatesStore } from '../tenantTemplates/tradeSequenceTemplatesStore';
import { installRTCClient, RTCClient, useRTCClient } from './rtcClient';
import { installOneShotRTCClient } from './rtcClient/install';
import { RTCMessageParser } from './rtcClient/messageParser';
import { OneShotRTCClient } from './rtcClient/oneShotClient';
import { installRTCController, RTCController } from './rtcController';

export const installRealTimeCollaboration = (
  authenticationService: AuthenticationService,
  loggingService: LoggingService,
  endpoint: string,
  messageParser?: RTCMessageParser,
) => {
  installRTCClient(new RTCClient(authenticationService, endpoint, messageParser, loggingService));
  installOneShotRTCClient(
    new OneShotRTCClient(authenticationService, endpoint, messageParser, loggingService),
  );
  const entityStores = {
    calendarStore: useCalendarStore,
    orderStore: useOrderStore,
    pauseStore: usePauseStore,
    wbsSectionStore: useWbsSectionStore,
    milestoneStore: useMilestoneStore,
    projectTradeSequenceStore: useProjectTradeSequenceStore,
    tenantTradeSequenceStore: useTradeSequenceTemplatesStore,
    orderDependencyStore: useOrderDependencyStore,
    projectContributorStore: useProjectContributorStore,
    projectStore: useProjectStore,
    projectAlternativeStore: useProjectAlternativeStore,
    multiSelectActionsStore: useMultiSelectActionsStore,
    projectTradeStore: useProjectTradeStore,
    tenantTradeStore: useTenantTradeStore,
  };
  installUndoRedoQueue(new UndoRedoQueue(entityStores));
  installLocalOrderSchedulingEngine(new OrderSchedulingEngine());
  const rtcController = new RTCController(
    useRTCClient,
    useUndoRedoQueue,
    useLocalOrderSchedulingEngine,
    entityStores,
    loggingService,
  );
  installRTCController(rtcController);
};
