import { DomClassList } from '@bryntum/schedulerpro';

import { PauseEntity } from '@/common/types';
import { NodeName } from '@/repositories/utils/cache';
import {
  BaseScheduleEventParser,
  getMainResourceId,
  schedulerClassConfig,
  schedulerIconConfig,
} from '@/services/store/schedule/parsers/base';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import { SchedulerEvent } from '@/services/store/schedule/types';

export class PauseEventParser
  extends BaseScheduleEventParser
  implements ScheduleModelParser<PauseEntity>
{
  public entityToModel(pause: PauseEntity): SchedulerEvent {
    return {
      ...this.parseSharedInput(pause, 'start', 'end', NodeName.PAUSE),
      cls: new DomClassList([schedulerClassConfig[NodeName.PAUSE], 'hide-dependency-terminals']),
      iconCls: schedulerIconConfig[NodeName.PAUSE],
      resourceId: getMainResourceId(),
      calendarId: null,
    };
  }
}
