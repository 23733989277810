import { SchedulerDependencyModel } from '@bryntum/schedulerpro';

import { subtract } from '@/helpers/utils/arrays';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { SchedulerDependency } from '@/services/store/schedule/types';

export async function redrawDependencies(store: ScheduleStore): Promise<void> {
  // When deleting an order / milestone all dependencies are deleted in backend. When restoring the order / milestone, the dependencies will be recreated
  // and should be visible again. As they were not deleted locally, we just trigger a change to redraw the dependencies.
  const allDependencyRecords = (getScheduler()?.dependencyStore.allRecords ??
    []) as SchedulerDependency[];
  const dependenciesToAdd = subtract(
    Array.from(store.entities.dependencies.values()),
    allDependencyRecords,
  );
  getScheduler()?.dependencyStore.add(dependenciesToAdd as SchedulerDependencyModel[]);
}
