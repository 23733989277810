import { DryingBreak } from '@/graphql/__generated__/graphql';

export interface DryingBreakFormInput {
  name: string | null;
  duration: number;
}

export function dryingBreakToFormInput(
  dryingBreak: DryingBreak | DryingBreakFormInput | null,
): DryingBreakFormInput | null {
  if (!dryingBreak) return null;
  return {
    name: dryingBreak.name ?? null,
    duration: dryingBreak.duration,
  };
}
