export type DataType = 'Boolean' | 'String' | 'Number' | 'Object' | 'Array' | 'File';

/** Returns object type for prop validation, depending on data type */
export function getPropType(type: DataType): unknown {
  if (type === 'Boolean') return Boolean;
  if (type === 'String') return String;
  if (type === 'Number') return Number;
  if (type === 'Object') return Object;
  if (type === 'Array') return Array;
  if (type === 'File') return File;

  return undefined;
}

/** Returns prop default value, depending on data type and default value */
export function getPropDefault<T>(type: DataType, defaultValue: T): T | (() => T) {
  return ['Object', 'Array'].includes(type) ? () => defaultValue : defaultValue;
}
