import { SchedulerDependencyModel, TimeSpan } from '@bryntum/schedulerpro';
import { DependencyType } from '@koppla-tech/scheduling-engine';
import { addMinutes } from 'date-fns';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { MilestoneEntity, OrderDependencyEntity, OrderEntity } from '@/common/types';
import { useMilestoneStore } from '@/features/milestones';
import {
  DependencyCreatedEvent,
  DependencyOpenedEvent,
  showOverruleNewDependencyNotification,
  useOrderDependencyStore,
} from '@/features/orderDependencies';
import { containsDependencyCycle } from '@/features/orderDependencies/dependencyCycle';
import { getDependentEntities } from '@/features/orderDependencies/orderDependencyUtils';
import { useOrderStore } from '@/features/orders';
import { useLocalOrderSchedulingEngine } from '@/features/schedule';
import {
  getEndDependingOnDependency,
  getStartDependingOnDependency,
} from '@/helpers/orders/dependencies';
import { getRandomId } from '@/helpers/utils/strings';
import { LoggingService } from '@/interfaces/services';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';
import { useLoggingService } from '@/services/logging/composable';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { getPlaceholderEventId } from '@/services/store/schedule/parsers/base';
import {
  SchedulerDependency,
  SchedulerEvent,
  SchedulerResource,
} from '@/services/store/schedule/types';
import { useSchedulePopupStore } from '@/services/store/schedulePopup';

export interface DependencyListeners {
  dependencyCreateDragStart: () => void;
  beforeDependencyCreateFinalize: (data: {
    source: TimeSpan;
    target: TimeSpan;
    fromSide: 'start' | 'end' | 'top' | 'bottom';
    toSide: 'start' | 'end' | 'top' | 'bottom';
    valid: boolean;
  }) => void;
  dependencyClick: (data: { dependency: SchedulerDependency }) => void;
  dependenciesDrawn: () => void;
}

export function getDependencyListeners(
  store: ScheduleStore,
  loggingService: LoggingService,
): DependencyListeners {
  return {
    dependencyCreateDragStart: (): void => {
      const schedulePopupStore = useSchedulePopupStore();
      schedulePopupStore.closePopup();
      store.utils.isDraggingDependency = true;
    },
    beforeDependencyCreateFinalize: (data): void => {
      // Make sure bryntum doesn't create a placeholder within the store itself, but let us handle it
      data.valid = false;
      store.utils.isDraggingDependency = false;
      const { source: sourceSpan, target: targetSpan, toSide, fromSide } = data;
      const source = getScheduler()?.eventStore.getById(sourceSpan.id) as SchedulerEvent;
      const target = getScheduler()?.eventStore.getById(targetSpan.id) as SchedulerEvent;

      if (!source || !target) return;

      if (source.id === getPlaceholderEventId() || target.id === getPlaceholderEventId()) {
        return;
      }

      const featureAccessStore = useFeatureAccessStore();
      const canCreateDependency = featureAccessStore.features.DEPENDENCY_PLANNING(target.id).value
        .write;
      if (!canCreateDependency) return;
      const orderDependencyStore = useOrderDependencyStore();

      const type = getDependencyTypeFromSide(fromSide, toSide);
      const workingTimeInMinutes = getWorkingTimeBetweenEvents(type, source, target);
      const newDependency = setupNewDependency(type, source, target, workingTimeInMinutes);

      orderDependencyStore.create([newDependency], true, {
        orderStore: useOrderStore,
        milestoneStore: useMilestoneStore,
      });

      // inject doesn't work here and since this is an intermediate solution we just call it 'manually'
      window.jimo?.push([
        'do',
        'boosted:trigger',
        [{ evolutionId: 'f314874f-6753-4e26-bdb0-afffed512c79' }],
      ]);
      loggingService.trackEvent(
        new DependencyCreatedEvent({
          causeRescheduling: workingTimeInMinutes < 0,
          source: 'schedule',
        }),
      );
    },
    dependencyClick: ({ dependency }): void => {
      if (store.readonly || dependency.fake || store.utils.disableEventInteractions) {
        return;
      }
      store.openSidebar({ dependency });
      loggingService.trackEvent(new DependencyOpenedEvent());
    },
    // create custom arrow end violated dependencies
    // https://forum.bryntum.com/viewtopic.php?t=12541
    dependenciesDrawn: (): void => {
      const violatedArrowEndId = 'violatedArrowEnd';
      if (document.getElementById(violatedArrowEndId)) return;
      const markerEnd = document.getElementById('arrowEnd');
      if (!markerEnd) {
        useLoggingService().error('Could not find markerEnd element', {
          code: 'No dependency marker exists',
        });
        return;
      }
      const violatedMarker = markerEnd.cloneNode(true) as HTMLElement;
      violatedMarker.id = violatedArrowEndId;
      markerEnd.parentNode?.appendChild(violatedMarker);
    },
  };
}

function getWorkingTimeBetweenEvents(
  type: DependencyType,
  source: SchedulerEvent,
  target: SchedulerEvent,
) {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
  const useDryingBreak = source.entity === NodeName.DRYING_BREAK;
  const fromId = useDryingBreak
    ? DryingBreakEventParser.dryingBreakIdToOrderId(source.id)
    : source.id;

  const { from, fromType, to, toType } = getDependentEntities(
    {
      orderStore: useOrderStore,
      orderDependencyStore: useOrderDependencyStore,
      milestoneStore: useMilestoneStore,
    },
    { fromId, toId: target.id },
  );

  if (!from || !to) {
    throw new Error('Could not find dependent entities:' + fromId + ' ' + target.id);
  }

  const isOrder = (
    entity: OrderEntity | MilestoneEntity,
    entityType: NodeName.ORDER | NodeName.MILESTONE,
  ): entity is OrderEntity => {
    return entityType === NodeName.ORDER;
  };

  const getStartDate = (
    entity: OrderEntity | MilestoneEntity,
    entityType: NodeName.ORDER | NodeName.MILESTONE,
  ) => {
    if (isOrder(entity, entityType)) {
      return entity.startAt;
    }
    return entity.date;
  };
  const getEndDate = (
    entity: OrderEntity | MilestoneEntity,
    entityType: NodeName.ORDER | NodeName.MILESTONE,
  ) => {
    if (isOrder(entity, entityType)) {
      return addMinutes(entity.finishAt, entity.dryingBreak?.duration ?? 0);
    }
    return entity.date;
  };

  const realSourceStart = new SchedulingDate(
    getStartDependingOnDependency(type, getStartDate(from, fromType), getEndDate(from, fromType)),
  );
  const realTargetEnd = new SchedulingDate(
    getEndDependingOnDependency(type, getStartDate(to, toType), getEndDate(to, toType)),
  );

  return localOrderSchedulingEngine.utils.computeWorkingTimeBetween(
    realTargetEnd,
    realSourceStart,
    target.calendarId ?? null,
  );
}

function getDependencyTypeFromSide(
  fromSide: 'start' | 'end' | 'top' | 'bottom',
  toSide: 'start' | 'end' | 'top' | 'bottom',
): DependencyType {
  if (fromSide === 'start') {
    return toSide === 'start' ? DependencyType.SS : DependencyType.SF;
  }
  return toSide === 'start' ? DependencyType.FS : DependencyType.FF;
}

function setupNewDependency(
  type: DependencyType,
  source: SchedulerEvent,
  target: SchedulerEvent,
  workingTimeInMinutes: number = 0,
): OrderDependencyEntity {
  const newId = toGlobalId(NodeName.ORDER_DEPENDENCY, getRandomId());

  let sourceIsOrder = source.entity === NodeName.ORDER;
  let sourceId = source.id;
  const targetIsOrder = target.entity === NodeName.ORDER;
  const targetId = target.id;
  let useDryingBreak = false;

  if (source.entity === NodeName.DRYING_BREAK) {
    sourceId = DryingBreakEventParser.dryingBreakIdToOrderId(source.id);
    sourceIsOrder = true;
    useDryingBreak = true;
  }

  if (workingTimeInMinutes < 0) {
    showOverruleNewDependencyNotification({
      dependencyId: newId,
      lagInMinutes: workingTimeInMinutes,
    });
  }

  return {
    id: newId,
    from: {
      __typename: sourceIsOrder ? NodeName.ORDER : NodeName.MILESTONE,
      id: sourceId,
    },
    to: {
      __typename: targetIsOrder ? NodeName.ORDER : NodeName.MILESTONE,
      id: targetId,
    },
    lagInMinutes: 0,
    bufferInMinutes: Math.max(workingTimeInMinutes, 0),
    type,
    useDryingBreak,
  };
}

/**
 * Bryntum uses interval `isValidDependency` function to check if a dependency between specific events can be created.
 * Otherwise, they provide no option to pass in a custom implementation. Therefore, we override
 * the dependency store and inject our custom check. This function represents this custom
 * `isValidDependency` function.
 */
export function getCustomIsValidDependency(
  checkPermissions = false,
  getFnScheduler = getScheduler,
): (
  dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
  toId?: TimeSpan | number | string,
  type?: number,
) => boolean {
  const validCases: Partial<Record<NodeName, NodeName[]>> = {
    [NodeName.ORDER]: [NodeName.ORDER, NodeName.MILESTONE],
    [NodeName.MILESTONE]: [NodeName.ORDER],
    [NodeName.DRYING_BREAK]: [NodeName.ORDER, NodeName.MILESTONE],
  };

  const featureAccessStore = useFeatureAccessStore();
  const orderDependencyStore = useOrderDependencyStore();
  const milestoneStore = useMilestoneStore();

  return (
    dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
    toId?: TimeSpan | number | string,
    type?: number,
  ): boolean => {
    const scheduler = getFnScheduler();
    /**
     * If no end or type given, return true, such that only the default check is respected.
     * Here, an existing dependency is checked for validity, which we don't
     * want to cover
     */
    if (!toId || type == null) return true;

    // make sure the given params are objects
    if (typeof dependencyOrFromId !== 'object' || typeof toId !== 'object') {
      throw new Error('The given dependency is not an object');
    }

    // There is a type issue with the parameters. Not only ids but also the whole event is returned
    const from = dependencyOrFromId as unknown as SchedulerEvent;
    const to = toId as unknown as SchedulerEvent;

    let fromId = from.id;

    // in case of drying breaks and orders, we don't want to be able to create a dependency between
    // order and drying break at the same time, thus, we check if one of them already exists and return false if so
    if (from.entity === NodeName.DRYING_BREAK) {
      const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(fromId);
      fromId = orderId;
      if (orderDependencyStore.checkHasRelation(orderId, to.id, false)) {
        return false;
      }
    }
    if (from.entity === NodeName.ORDER) {
      const dryingBreakId = DryingBreakEventParser.orderIdToDryingBreakId(from.id);
      if (orderDependencyStore.checkHasRelation(dryingBreakId, to.id, false)) {
        return false;
      }
    }

    // False for trade sequence editor
    if (checkPermissions) {
      const hasPermission = featureAccessStore.features.DEPENDENCY_PLANNING(to.id).value.write;
      if (!hasPermission) return false;
    }

    const existingDependencies: { from: string; to: string }[] = [
      ...orderDependencyStore.dependencies.values(),
    ].map((d) => ({ from: d.from.id, to: d.to.id }));

    const newTestDependency = { from: fromId, to: to.id };

    existingDependencies.push(newTestDependency);
    const createsCycle = containsDependencyCycle(existingDependencies);

    if (createsCycle) {
      return false;
    }

    if (to.entity === NodeName.MILESTONE) {
      const resource = scheduler?.resourceStore.getById(to.resourceId ?? '') as
        | SchedulerResource
        | undefined;
      if (!resource) return false;
      return !milestoneStore.checkDateHasOverlappingMilestones(
        new SchedulingDate(to.startDate),
        resource,
      );
    }

    return validCases[from.entity]?.includes(to.entity) ?? false;
  };
}
