import { DomClassList } from '@bryntum/schedulerpro';

import { MilestoneEntity } from '@/common/types';
import { BaseActualStatus, getBaseActualColor, useBaseMilestone } from '@/features/basePlan';
import { getCollisionCounterForEntity } from '@/features/collisions/collisionUtils';
import { MilestoneType } from '@/features/milestones/types';
import { getDisplayedTextWidth } from '@/helpers/inputs/main';
import { getHexFromCssVariable } from '@/helpers/utils/colors';
import { clamp } from '@/helpers/utils/numbers';
import { NodeName } from '@/repositories/utils/cache';
import {
  BaseScheduleEventParser,
  getMainResourceId,
  schedulerClassConfig,
} from '@/services/store/schedule/parsers/base';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import {
  SchedulerEvent,
  SchedulerEventAppearance,
  SchedulerEventColorType,
} from '@/services/store/schedule/types';

const LABEL_OFFSET = 50; // for shown date and padding
const LABEL_MIN_WIDTH = 70;
const LABEL_RENDER_SPACING = 20;
const LABEL_MAX_WIDTH = 150;

export class MilestoneEventParser
  extends BaseScheduleEventParser
  implements ScheduleModelParser<MilestoneEntity>
{
  public static getActualMilestoneLabelWidth(event: SchedulerEvent): number {
    return event.milestoneWidth ? event.milestoneWidth - LABEL_RENDER_SPACING : LABEL_MAX_WIDTH;
  }

  public entityToModel(milestone: MilestoneEntity): SchedulerEvent {
    const classList = [schedulerClassConfig[NodeName.MILESTONE]];
    if (milestone.isFixed) classList.push('--fixed');

    // NOTE: we can't use a pure CSS solution for this because bryntum uses the width for measuring overlaps
    const milestoneTextWidth = getDisplayedTextWidth(milestone.name, '11px Montserrat') / 2;
    const milestoneWidth =
      clamp(milestoneTextWidth + LABEL_OFFSET, [LABEL_MIN_WIDTH, LABEL_MAX_WIDTH]) +
      LABEL_RENDER_SPACING;

    return {
      ...this.parseSharedInput(milestone, 'date', 'date', NodeName.MILESTONE),
      cls: new DomClassList(classList),
      resourceId: milestone.wbsSection?.id ?? getMainResourceId(),
      isFixed: milestone.type === MilestoneType.FIXED,
      appearance: this.getAppearance(milestone),
      milestoneWidth,
      manuallyScheduled: true,
      calendarId: null,
      collisionCounter: getCollisionCounterForEntity(milestone.id),
    };
  }

  private getBaseActualEventColor(milestone: MilestoneEntity): string {
    const milestoneStatus =
      useBaseMilestone(ref(milestone.id)).value?.status ?? BaseActualStatus.UNAVAILABLE;
    const stateColor = getBaseActualColor(milestoneStatus);
    const hexColor = getHexFromCssVariable(stateColor);
    return hexColor;
  }

  private getAppearance(milestone: MilestoneEntity): Record<number, SchedulerEventAppearance> {
    return {
      [SchedulerEventColorType.TRADE]: {
        cls: [],
        eventColor: milestone.color,
      },
      [SchedulerEventColorType.STATUS]: {
        cls: [],
        eventColor: getHexFromCssVariable(
          milestone.completedAt ? '--color-blue-500' : '--color-grey-300',
        ),
      },
      [SchedulerEventColorType.BASE_ACTUAL]: {
        cls: [],
        eventColor: this.getBaseActualEventColor(milestone),
      },
    };
  }
}
