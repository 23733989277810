import { watch, WatchStopHandle } from 'vue';

import { MapDifferences } from '@/helpers/utils/objects';
import { Entity } from '@/interfaces/repositories/base';

export function extendMap<T>(state: Map<string, T>, data: [string, T][]): Map<string, T> {
  return new Map([...Array.from(state.entries()), ...data]);
}

function updateMapItem<TMap extends Entity>(map: Map<string, TMap>, value: TMap | null): void {
  if (value === null) {
    return;
  }
  map.set(value.id, value);
}

// updates the map in-place (for performance reasons) instead of returning a new map
export function updateMap<TMap extends Entity>(
  map: Map<string, TMap>,
  differences: MapDifferences,
  parse: (key: string) => TMap | null | (TMap | null)[],
): void {
  differences.deleted.forEach((key) => {
    map.delete(key);
  });
  [...differences.added, ...differences.updated].forEach((key) => {
    const parsed = parse(key);
    if (Array.isArray(parsed)) {
      parsed.forEach((value) => {
        updateMapItem(map, value);
      });
      return;
    }
    updateMapItem(map, parsed);
  });
}

export function watchMap<TMap extends Entity>(
  getMap: () => Map<string, TMap>,
  onUpdate: (next: Map<string, TMap>, prev: Map<string, TMap>) => void,
  additionalDependencies: () => unknown = () => [],
): WatchStopHandle {
  let oldState = new Map();
  const unwatch = watch(
    () => {
      additionalDependencies();
      return getMap().entries();
    },
    (next) => {
      const newState = new Map(next);
      onUpdate(newState, oldState);
      oldState = newState;
    },
    {
      deep: true,
      immediate: true,
    },
  );
  return unwatch;
}
