import { defineStore } from 'pinia';

import { copy } from '@/helpers/utils/objects';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { getDefaultZoomPreset } from '@/services/store/schedule/utils/zoom';
import { getBrowserLanguage, Language } from '@/utils/languages';

import { refreshScheduleUI } from '../../../features/schedule/bryntum/schedulerInteractions';
import { SchedulerEventColorType } from '../schedule/types';

const STORE_NAME = 'schedule-appearance-store';

type ProjectSettings = {
  collapsedRowIds: Set<string>;
  eventColorType: SchedulerEventColorType;
  scrollDate: number | null;
  scrollRowIdx: number | null;
  showDependencies: boolean;
  splitterWidth: number;
};

export interface ScheduleAppearanceState {
  currentZoomPreset: string;
  eventToHighlightRelationsFor: string | null;
  locale: Language;
  projectId: string;
  projectsSettings: Record<string, ProjectSettings>;
  storeVersion: number;
  zoomPresets: string[];
}

function getInitialScheduleAppearanceState(): ScheduleAppearanceState {
  try {
    const storedState = JSON.parse(localStorage.getItem(STORE_NAME) || '{}');

    if (storedState.storeVersion !== 1) {
      // Remove the previous persisted data due to store structure change
      localStorage.removeItem(STORE_NAME);
    }
  } catch {
    // LocalStorage is not available
  }

  return {
    currentZoomPreset: getDefaultZoomPreset(),
    eventToHighlightRelationsFor: null,
    locale: getBrowserLanguage(),
    projectId: '',
    projectsSettings: {},
    storeVersion: 1,
    zoomPresets: [],
  };
}

const getDefaultProjectSettings = (): ProjectSettings => ({
  collapsedRowIds: new Set(),
  eventColorType: SchedulerEventColorType.TRADE,
  scrollDate: null,
  scrollRowIdx: null,
  showDependencies: true,
  splitterWidth: 315,
});

export const useScheduleAppearanceStore = defineStore(STORE_NAME, {
  state: (): ScheduleAppearanceState => getInitialScheduleAppearanceState(),
  actions: {
    init(projectId: string) {
      if (!this.projectsSettings[projectId]) {
        this.projectsSettings[projectId] = getDefaultProjectSettings();
      }
    },
    reset(projectId: string) {
      const preservedZoomPreset = this.currentZoomPreset;
      const preservedProjectsSettings = this.projectsSettings;
      const preservedStoreVersion = this.storeVersion;
      this.$state = getInitialScheduleAppearanceState();
      this.currentZoomPreset = preservedZoomPreset;
      this.projectsSettings = preservedProjectsSettings;
      this.storeVersion = preservedStoreVersion;
      this.projectId = projectId;
    },
    collapseStateForRows(rowIds: string[]) {
      rowIds.forEach((rowId) => {
        this.projectsSettings[this.projectId].collapsedRowIds.add(rowId);
      });
    },
    expandStateForRows(rowIds: string[]) {
      rowIds.forEach((rowId) => {
        this.projectsSettings[this.projectId].collapsedRowIds.delete(rowId);
      });
    },
    setEventColorType(eventColorType: SchedulerEventColorType) {
      this.projectsSettings[this.projectId].eventColorType = eventColorType;
    },
    setEventIdToHighlightRelationsFor(eventId: string | null): void {
      const scheduler = getScheduler();
      if (!scheduler) return;

      if (this.eventToHighlightRelationsFor === eventId) return;

      this.eventToHighlightRelationsFor = eventId;
      refreshScheduleUI(scheduler);
    },
    setScrollDate(scrollDate: number) {
      this.projectsSettings[this.projectId].scrollDate = scrollDate;
    },
    setScrollRowIdx(scrollRowIdx: number) {
      this.projectsSettings[this.projectId].scrollRowIdx = scrollRowIdx;
    },
    setShowDependencies(showDependencies: boolean) {
      this.projectsSettings[this.projectId].showDependencies = showDependencies;
    },
    setSplitterWidth(width: number) {
      this.projectsSettings[this.projectId].splitterWidth = width;
    },
  },
  getters: {
    collapsedRowIds(state) {
      // required for backward compatibility
      if (!(state.projectsSettings[state.projectId].collapsedRowIds instanceof Set)) {
        state.projectsSettings[state.projectId].collapsedRowIds = new Set();
      }
      return state.projectsSettings[state.projectId].collapsedRowIds;
    },
    eventColorType(state) {
      return state.projectsSettings[state.projectId].eventColorType;
    },
    scrollDate(state) {
      return state.projectsSettings[state.projectId].scrollDate;
    },
    scrollRowIdx(state) {
      return state.projectsSettings[state.projectId].scrollRowIdx;
    },
    showDependencies(state) {
      return state.projectsSettings[state.projectId].showDependencies;
    },
    splitterWidth(state) {
      return state.projectsSettings[state.projectId].splitterWidth;
    },
  },
  persist: {
    serializer: {
      serialize: (v) => {
        const state = v as ScheduleAppearanceState;
        // Copy to remove proxies from state, reassigments without copy cause infinite recursion
        const copiedState = copy(state);
        Object.entries(state.projectsSettings).forEach(([projectId, settings]) => {
          copiedState.projectsSettings[projectId] = {
            ...settings,
            // @ts-expect-error-next-line
            collapsedRowIds: [...settings.collapsedRowIds.values()],
          };
        });
        return JSON.stringify(copiedState);
      },
      deserialize: (v) => {
        try {
          const parsed = JSON.parse(v) as ScheduleAppearanceState;
          Object.entries(parsed.projectsSettings).forEach(([key, value]) => {
            const { collapsedRowIds } = value;
            parsed.projectsSettings[key] = {
              ...value,
              collapsedRowIds: Array.isArray(collapsedRowIds)
                ? new Set(collapsedRowIds)
                : new Set(),
            };
          });

          return parsed;
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
          return getInitialScheduleAppearanceState();
        }
      },
    },
  },
});
