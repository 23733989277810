export const scheduleSelectors = {
  detailsBar: {
    closeButton: 'details-bar-close-button',
    deleteButton: 'details-bar-delete-button',
    editButton: 'details-bar-edit-button',
  },
  collisionDetailsPopup: {
    card: 'collision-details-popup-card',
  },
  collisionsIndicator: {
    button: 'collisions-indicator-button',
  },
  collisionJumpListPopup: {
    card: 'collision-jump-list-popup-card',
    item: (id: string) => `collision-jump-list-popup-item-${id}`,
  },
  collisionsRow: {
    jumpToPreviousButton: 'collisions-jump-to-previous-button',
    jumpToNextButton: 'collisions-jump-to-next-button',
  },
  scheduleClickPopup: {
    card: 'schedule-click-popup-card',
    addOrder: 'schedule-click-popup-add-order-option',
  },
  scheduleMainClickPopup: {
    card: 'schedule-main-click-popup-card',
    addPause: 'schedule-main-click-popup-add-pause-option',
    addFixedMilestone: 'schedule-main-click-popup-add-fixed-milestone-option',
    addFlexibleMilestone: 'schedule-main-click-popup-add-flexible-milestone-option',
  },
  scheduleContextMenuPopup: {
    pasteButton: 'schedule-click-popup-paste-button',
  },
  statusBanner: {
    noNetworkConnectionBanner: 'status-banner-no-network-connection',
    noServerConnectionBanner: 'status-banner-no-server-connection',
  },
  summarySectionClickPopup: {
    card: 'section-summary-click-popup-card',
    addFixedMilestone: 'section-summary-click-popup-add-fixed-milestone-option',
    addFlexibleMilestone: 'section-summary-click-popup-add-flexible-milestone-option',
  },
  toolbar: {
    moreMenu: {
      enterPlanningMode: 'schedule-toolbar-more-menu-enter-planning-mode',
      openButton: 'schedule-toolbar-more-menu-open',
      openExportButton: 'schedule-toolbar-more-menu-open-export',
    },
    undoRedo: {
      undoButton: 'schedule-toolbar-undo-button',
      redoButton: 'schedule-toolbar-redo-button',
    },
    filterButton: 'schedule-toolbar-filter-button',
    loadingIndicator: 'schedule-toolbar-loading-indicator',
  },
};
