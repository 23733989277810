import { DomConfig, SchedulerPro } from '@bryntum/schedulerpro';

import { EventRendererData, RenderData } from '@/common/bryntum/types';
import {
  BaseActualStatus,
  getBaseActualColor,
  useBaseMilestone,
  useBaseOrder,
  useBaseSection,
} from '@/features/basePlan';
import { useBaseDryingTime } from '@/features/basePlan/composables';
import { BaseActualEntities } from '@/features/basePlan/types';
import { useCollisionStore } from '@/features/collisions';
import { isCollisionFiltered } from '@/features/collisions/collisionUtils';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useProjectStore } from '@/features/projects';
import { getHexFromCssVariable } from '@/helpers/utils/colors';
import { escapeCharacters } from '@/helpers/utils/escapeCharacters';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { renderMilestoneWithCollisions } from '@/services/store/schedule/config/renderers/milestoneRendererUtils';
import { SchedulerEvent, SchedulerEventColorType } from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';

import { DryingBreakEventParser } from '../../parsers';
import { CollisionEventShape } from '../../parsers/collision';

const MIN_EVENT_WIDTH_TO_SHOW_COLLISION = 35;

export function getEventRenderer(store: ScheduleStore): (data: EventRendererData) => string {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const orderDependencyStore = useOrderDependencyStore();
  const collisionStore = useCollisionStore();
  const projectStore = useProjectStore();

  return ({ eventRecord: record, resourceRecord, renderData }): string => {
    const eventRecord = record as SchedulerEvent;
    const scheduler = getScheduler();
    if (!scheduler) return '';

    setClsAndEventColorToRenderData(eventRecord, renderData);

    if (eventRecord.entity === NodeName.COLLISION) {
      renderData.cls['--filtered'] = isCollisionFiltered(eventRecord.id, collisionStore);
      renderData.cls['--circle'] = eventRecord.shape === CollisionEventShape.CIRCLE;
      renderData.cls['--diamond'] = eventRecord.shape === CollisionEventShape.DIAMOND;
      if (!projectStore.currentProject?.hourlyPlanningEnabled) {
        centerEventWithinTick(renderData, getEventTickCenterData(scheduler));
      }
    }

    if (eventRecord.entity === NodeName.MILESTONE) {
      centerEventWithinTick(renderData, getEventTickCenterData(scheduler));
      renderMilestoneWithCollisions({ eventRecord: record, renderData, resourceRecord });
    }

    renderData.cls['highlighted-event'] = orderDependencyStore.checkHasRelation(
      eventRecord.id,
      scheduleAppearanceStore.eventToHighlightRelationsFor ?? '',
    );
    renderData.cls['show-event-cut-out'] = store.clipboard?.data.includes(eventRecord.id) ?? false;
    return escapeCharacters(eventRecord.name);
  };
}

function setClsAndEventColorToRenderData(eventRecord: SchedulerEvent, renderData: RenderData) {
  const { eventColorType } = useScheduleAppearanceStore();
  if (
    eventRecord.collisionCounter !== null &&
    [NodeName.ORDER, NodeName.DRYING_BREAK].includes(eventRecord.entity)
  ) {
    const collisionClassSuffix = [
      ...(eventRecord.collisionCounter.start > 0 ? ['start'] : []),
      ...(eventRecord.collisionCounter.end > 0 ? ['end'] : []),
    ].join('-');
    renderData.cls['--colliding'] = true;
    renderData.cls['--light'] = true;
    renderData.eventColor = 'var(--color-white)';
    if (renderData.width > MIN_EVENT_WIDTH_TO_SHOW_COLLISION) {
      renderData.cls[`--colliding-${collisionClassSuffix}`] = true;
      renderData.children = getStartAndEndCollisionBubblesForOrderWithCollision(
        eventRecord,
        renderData,
      );
    }
  } else if (eventRecord.appearance) {
    Object.entries(eventRecord.appearance).forEach(([appearanceKey, appearance]) => {
      appearance.cls.forEach((cls) => {
        renderData.cls[cls] = appearanceKey === eventColorType.toString();
      });
    });
    renderData.eventColor = eventRecord.appearance[eventColorType].eventColor;
  }

  /**
   * We need to set the base/actual color here as well because event parsers are not
   * called when the base plan changes, causing only the initial base/actual
   * color to be displayed.
   */
  if (
    eventRecord.collisionCounter === null &&
    eventColorType === SchedulerEventColorType.BASE_ACTUAL &&
    BaseActualEntities.has(eventRecord.entity)
  ) {
    renderData.eventColor = getBaseActualColorOfEvent(eventRecord);
  }
}

function getStartAndEndCollisionBubblesForOrderWithCollision(
  eventRecord: SchedulerEvent,
  renderData: RenderData,
): DomConfig[] {
  // drying breaks cannot have direct collisions
  if (eventRecord.entity === NodeName.DRYING_BREAK || !eventRecord.collisionCounter) {
    return [];
  }
  const startBubble =
    eventRecord.collisionCounter.start > 0
      ? [getOrderCollisionBubble(renderData, eventRecord.collisionCounter.start)]
      : [];
  const endBubble =
    eventRecord.collisionCounter.end > 0
      ? [
          getOrderCollisionBubble(renderData, eventRecord.collisionCounter.end, {
            position: 'absolute',
            right: '0',
            zIndex: 2,
          }),
        ]
      : [];
  return [...startBubble, ...endBubble];
}

function getOrderCollisionBubble(renderData: RenderData, count: number, style: Dictionary = {}) {
  const hasCounter = count > 1;
  return {
    tag: 'div',
    className: `order-event-collision-content`,
    style: {
      flex: 'none',
      height: `${renderData.height}px`,
      width: `${renderData.height}px`,
      ...style,
    },
    children: [
      {
        tag: 'div',
        className: `order-event-collision-content-bubble ${!hasCounter ? '--has-icon' : ''}`,
        style: {
          borderRadius: '9999px', // fully rounded
          height: `${renderData.height}px`,
          width: `${renderData.height}px`,
        },
        children: hasCounter
          ? [
              {
                tag: 'span',
                className: 'order-event-collision-content-bubble-count',
                text: `${count}`,
              },
            ]
          : [],
      },
    ],
  };
}

export function getBaseActualColorOfEvent(record: SchedulerEvent): string {
  const { entity } = record;
  let status: BaseActualStatus | undefined;
  if (entity === NodeName.ORDER) {
    status = useBaseOrder(ref(record.id)).value?.status;
  } else if (entity === NodeName.MILESTONE) {
    status = useBaseMilestone(ref(record.id)).value?.status;
  } else if (entity === NodeName.SECTION) {
    status = useBaseSection(ref(record.id)).value?.status;
  } else if (entity === NodeName.DRYING_BREAK) {
    const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(record.id);
    status = useBaseDryingTime(ref(orderId)).value?.status;
  }

  const stateColor = getBaseActualColor(status ?? BaseActualStatus.UNAVAILABLE);
  return getHexFromCssVariable(stateColor);
}

function getEventTickCenterData(scheduler?: SchedulerPro): {
  tickSize: number;
  resourceMargin: number;
  shouldCenterHorizontally: boolean;
} {
  const tickSize = scheduler?.tickSize ?? 0;

  const viewPresetId: string =
    typeof scheduler?.viewPreset === 'string'
      ? scheduler?.viewPreset
      : (scheduler?.viewPreset?.id ?? '').toString();
  const parsedResourceMargin = +(scheduler?.resourceMargin ?? 0);
  const resourceMargin = Number.isNaN(parsedResourceMargin) ? 0 : parsedResourceMargin;
  const shouldCenterHorizontally = viewPresetId.toLowerCase().includes('weekandday');

  return { tickSize, resourceMargin, shouldCenterHorizontally };
}

export function centerEventWithinTick(
  renderData: EventRendererData['renderData'],
  {
    tickSize,
    resourceMargin,
    shouldCenterHorizontally,
  }: { tickSize: number; resourceMargin: number; shouldCenterHorizontally: boolean },
): void {
  renderData.left += shouldCenterHorizontally ? tickSize / 2 : 0;
  renderData.height += resourceMargin / 2;
  if (renderData.top) {
    renderData.top -= resourceMargin / 4;
  }
}
