import { ResourceTimeRangeStore } from '@bryntum/schedulerpro';

import { clearSchedulerReference, getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore, Sidebars } from '@/services/store/schedule';
import { useSchedulePopupStore } from '@/services/store/schedulePopup';

import { getInitialEntityState } from '../store';

export function destroy(store: ScheduleStore): void {
  store.utils.lastProjectId = store.projectId;
  store.utils.hasEventSelection = false;
  store.utils.multiSelectedEvents = new Map();
  store.utils.unwatchers.forEach((unwatch) => unwatch());
  store.utils.unwatchers = [];
  store.sidebar = null;
  store.currentSidebar = Sidebars.CLOSED;
  store.clearClipboard();
  useSchedulePopupStore().reset();

  // clear all entities from schedule store to prevent state inconsistenties, as
  // entity stores should be responsible for data synchronization
  store.entities = getInitialEntityState();

  removeListenersAndDestroyScheduler();
  store.initialized = false;
}

// eslint-disable-next-line complexity
function removeListenersAndDestroyScheduler(): void {
  const scheduler = getScheduler();
  if (!scheduler) return;

  scheduler.project?.removeAllListeners();
  scheduler.eventStore?.removeAllListeners();
  scheduler.eventStore?.removeAll();
  scheduler.eventStore?.destroy();
  scheduler.resourceStore?.removeAllListeners();
  scheduler.resourceStore?.removeAll();
  scheduler.resourceStore?.destroy();
  scheduler.assignmentStore?.removeAllListeners();
  scheduler.assignmentStore?.removeAll();
  scheduler.assignmentStore?.destroy();
  scheduler.dependencyStore?.removeAllListeners();
  scheduler.dependencyStore?.removeAll();
  scheduler.dependencyStore?.destroy();
  (scheduler.timeRangeStore as ResourceTimeRangeStore)?.removeAllListeners();
  (scheduler.timeRangeStore as ResourceTimeRangeStore)?.removeAll();
  (scheduler.timeRangeStore as ResourceTimeRangeStore)?.destroy();
  (scheduler.resourceTimeRangeStore as ResourceTimeRangeStore)?.removeAllListeners();
  (scheduler.resourceTimeRangeStore as ResourceTimeRangeStore)?.removeAll();
  (scheduler.resourceTimeRangeStore as ResourceTimeRangeStore)?.destroy();

  scheduler.removeAllListeners();
  scheduler.destroy();
  clearSchedulerReference();
}
