import { isEqual } from 'lodash';

import { DryingBreak } from '@/graphql/__generated__/graphql';
import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

import { DryingBreakFormInput, dryingBreakToFormInput } from './types';

export class DryingBreakCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DRYING_BREAK_CREATED;

  public constructor() {
    super();
  }
}

export class DryingBreakDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DRYING_BREAK_DELETED;

  public constructor(
    public properties: {
      method: 'order_details' | 'keyboard';
    },
  ) {
    super();
  }
}
export class DryingBreakEditedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DRYING_BREAK_EDITED;

  public constructor(
    public properties: {
      method: 'order_details' | 'resize';
    },
  ) {
    super();
  }
}

export function getDryingBreakAnalyticsEventFromStates(
  oldState: DryingBreak | DryingBreakFormInput | null,
  newState: DryingBreakFormInput | null,
): AnalyticsEvent | null {
  oldState = dryingBreakToFormInput(oldState);
  if (!oldState && newState) {
    return new DryingBreakCreatedEvent();
  }
  if (oldState && !newState) {
    return new DryingBreakDeletedEvent({ method: 'order_details' });
  }
  if (!isEqual(oldState, newState)) {
    return new DryingBreakEditedEvent({ method: 'order_details' });
  }
  return null;
}
