import { isBefore } from 'date-fns';

import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders';
import { orderIsDoneOrReportedDone, orderIsImmutable } from '@/helpers/orders/status';
import { getRandomId } from '@/helpers/utils/strings';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import {
  calculateEndAndKeepPriorDuration,
  calculateResourceAndKeepPriorDistance,
  calculateStartAndKeepPriorDistance,
  calculateStartRelativeToMostLeftEvent,
} from '@/services/store/schedule/actions/utils/eventCalculations';
import { getPlaceholderEventId } from '@/services/store/schedule/parsers/base';
import { ClipboardType, SchedulerEvent } from '@/services/store/schedule/types';

import { refreshScheduleUI } from '../../../../features/schedule/bryntum/schedulerInteractions';

export function copyEvents(store: ScheduleStore): Partial<Record<NodeName, number>> {
  return handleCutAndCopy(store, ClipboardType.COPY);
}

export function cutEvents(store: ScheduleStore): Partial<Record<NodeName, number>> {
  return handleCutAndCopy(store, ClipboardType.CUT);
}

const handleCutAndCopy = (store: ScheduleStore, clipboardType: ClipboardType) => {
  const scheduler = getScheduler();
  if (!scheduler || store.readonly) return {};
  const orderStore = useOrderStore();

  const allSelectedEventIds = getSelectedEventIds();
  const allowedEventSelection =
    clipboardType === ClipboardType.COPY
      ? allSelectedEventIds
      : allSelectedEventIds.filter((eventId) => {
          const order = orderStore.orders.get(eventId);
          return order && !orderIsImmutable(order);
        });

  if (!allowedEventSelection.length) return {};
  if (
    store.isMultiSelectToolbarOpen &&
    allowedEventSelection.length !== allSelectedEventIds.length
  ) {
    return {};
  }

  // close sidebar, when starting to copy/cut
  store.closeSidebar({ preserveEventSelection: true });

  store.clipboard = {
    data: allowedEventSelection,
    type: clipboardType,
    context: null,
  };
  refreshScheduleUI(getScheduler());
  store.utils.allowEventDeselection = true;
  getScheduler()?.clearEventSelection();

  return { [NodeName.ORDER]: allowedEventSelection.length };
};

function getSelectedEventIds(): string[] {
  const scheduler = getScheduler();
  if (!scheduler) return [];
  // NOTE: Right now we can only copy order events
  const selectedEvents = (scheduler.selectedEvents as SchedulerEvent[])
    .filter((event) => event.entity === NodeName.ORDER && event.id !== getPlaceholderEventId())
    .map((event) => event.id as string);

  return selectedEvents;
}

// NOTE: We can only paste order events right now
export function pasteEvents(store: ScheduleStore): Partial<Record<NodeName, number>> {
  const scheduler = getScheduler();
  if (!scheduler || store.readonly || !store.clipboard?.data.length || !store.clipboard?.context) {
    return {};
  }
  const orderStore = useOrderStore();
  const events = (
    store.clipboard.data
      .map((event) => store.entities.events.get(event))
      .filter((event) => !!event && event.entity === NodeName.ORDER) as SchedulerEvent[]
  ).map((event) => {
    const order = orderStore.orders.get(event.id)!;
    if (orderIsDoneOrReportedDone(order)) {
      return {
        ...event,
        endDate: new SchedulingDate(order.finishAt),
      };
    }
    return event;
  });
  if (!events.length) return {};

  if (store.clipboard.type === ClipboardType.CUT) {
    pasteCutOrders(store, events);
  } else if (store.clipboard.type === ClipboardType.COPY) {
    pasteCopiedOrders(store, events);
  }
  return { [NodeName.ORDER]: events.length };
}

function getMostLeftEvent(
  events: SchedulerEvent[],
  newStart: Date,
): { mostLeftEvent: SchedulerEvent; newStartOfMostLeftEvent: Date } | null {
  const mostLeftEvent = events.reduce(
    (mostLeft, event) => {
      if (!mostLeft || isBefore(event.startDate, mostLeft.startDate)) {
        return event;
      }
      return mostLeft;
    },
    null as SchedulerEvent | null,
  );

  if (!mostLeftEvent) return null;

  const newStartOfMostLeftEvent = calculateStartAndKeepPriorDistance(
    events,
    mostLeftEvent,
    newStart,
  );

  return {
    mostLeftEvent,
    newStartOfMostLeftEvent,
  };
}

function getNewDates(
  event: SchedulerEvent,
  mostLeftEvent: SchedulerEvent,
  newStartOfMostLeftEvent: Date,
): { newStart: SchedulingDate; newEnd: SchedulingDate } {
  const newStart =
    event.id === mostLeftEvent.id
      ? newStartOfMostLeftEvent
      : calculateStartRelativeToMostLeftEvent(mostLeftEvent, event, newStartOfMostLeftEvent);
  const newEnd = calculateEndAndKeepPriorDuration(event, newStart);

  return {
    newStart,
    newEnd,
  };
}

function pasteCutOrders(store: ScheduleStore, events: SchedulerEvent[]): void {
  const orderStore = useOrderStore();

  const contextStart = store.clipboard!.context!.startDate;
  const newResource = store.clipboard!.context!.resourceId ?? '';
  const { mostLeftEvent, newStartOfMostLeftEvent } = getMostLeftEvent(events, contextStart)!;

  const newEventValues = events.map((event) => {
    const { newStart, newEnd } = getNewDates(event, mostLeftEvent, newStartOfMostLeftEvent);
    const newResourceId = calculateResourceAndKeepPriorDistance(events, event, newResource).id;

    const datesChanged =
      newStart.getTime() !== event.startDate.getTime() ||
      newEnd.getTime() !== event.endDate.getTime();
    const resourceChanged = newResourceId !== event.resourceId;

    return {
      id: event.id,
      startDate: datesChanged ? newStart : undefined,
      endDate: datesChanged ? newEnd : undefined,
      resourceId: resourceChanged ? newResourceId : undefined,
    };
  });

  orderStore.update(
    newEventValues.map((event) => ({
      id: event.id,
      startAt: event.startDate,
      finishAt: event.endDate,
      wbsSection: event.resourceId ? { id: event.resourceId } : undefined,
    })),
  );

  store.clipboard = null;
}

async function pasteCopiedOrders(store: ScheduleStore, events: SchedulerEvent[]): Promise<void> {
  const orderStore = useOrderStore();
  const orderDependencyStore = useOrderDependencyStore();

  const contextStart = store.clipboard!.context!.startDate;
  const newResource = store.clipboard!.context!.resourceId ?? '';
  const { mostLeftEvent, newStartOfMostLeftEvent } = getMostLeftEvent(events, contextStart)!;

  const newEventValues = events.map((event) => {
    const { newStart, newEnd } = getNewDates(event, mostLeftEvent, newStartOfMostLeftEvent);
    const newResourceId = calculateResourceAndKeepPriorDistance(events, event, newResource).id;

    return {
      id: event.id,
      startDate: newStart,
      endDate: newEnd,
      resourceId: newResourceId,
    };
  });

  const originalOrderIdToCopiedOrderId = new Map<string, string>();

  const copiedOrders = newEventValues.map((event) => {
    const order = orderStore.orders.get(event.id)!;
    const newOrderId = toGlobalId(NodeName.ORDER, getRandomId());
    originalOrderIdToCopiedOrderId.set(order.id, newOrderId);
    return {
      newId: newOrderId,
      originalOrderId: order.id,
      startAt: event.startDate.toISOString(),
      finishAt: event.endDate.toISOString(),
      wbsSectionId: event.resourceId,
      dryingBreak: order.dryingBreak,
    };
  });

  const existingDependenciesBetweenOriginalOrders = Array.from(
    orderDependencyStore.dependencies.values(),
  ).filter(
    (dependency) =>
      originalOrderIdToCopiedOrderId.has(dependency.from.id) &&
      originalOrderIdToCopiedOrderId.has(dependency.to.id),
  );

  const dependencyIdMapping = existingDependenciesBetweenOriginalOrders.map((dependency) => ({
    fromOrderId: originalOrderIdToCopiedOrderId.get(dependency.from.id)!,
    toOrderId: originalOrderIdToCopiedOrderId.get(dependency.to.id)!,
    dependencyId: toGlobalId(NodeName.ORDER_DEPENDENCY, getRandomId()),
  }));

  orderStore.copy({
    orders: copiedOrders,
    idMapping: {
      dependencies: dependencyIdMapping,
    },
  });

  if (store.clipboard) {
    store.updateClipboardContext(null);
  }
}
