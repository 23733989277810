import { useMilestoneStore } from '@/features/milestones';
import { useMultiSelectActionsStore } from '@/features/multiSelectActions/multiSelectActionsStore';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { orderIsDoneOrReportedDone } from '@/helpers/orders/status';
import { Entity } from '@/interfaces/repositories/base';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { getPlaceholderEventId } from '@/services/store/schedule/parsers/base';
import { SchedulerEvent } from '@/services/store/schedule/types';

import { refreshScheduleUI } from '../../../../features/schedule/bryntum/schedulerInteractions';

export function deleteEvents(store: ScheduleStore): Partial<Record<NodeName, number>> {
  const scheduler = getScheduler();
  if (!scheduler || store.readonly) return {};

  const selectedEvents = (scheduler.selectedEvents as SchedulerEvent[]).filter(
    (event) => event.id !== getPlaceholderEventId(),
  );

  if (!selectedEvents.length) return {};

  const orderStore = useOrderStore();
  const pauseStore = usePauseStore();
  const milestoneStore = useMilestoneStore();
  const multiActionsStore = useMultiSelectActionsStore();

  const isDoneOrderOrMilestoneSelected = selectedEvents.some((event) => {
    const maybeOrder = orderStore.orders.get(event.id);
    if (maybeOrder) return orderIsDoneOrReportedDone(maybeOrder);
    const maybeMilestone = milestoneStore.milestones.get(event.id);
    if (maybeMilestone) return maybeMilestone.completedAt;
    return false;
  });

  if (isDoneOrderOrMilestoneSelected) return {};

  const numberOfDryingBreaksDeleted = selectedEvents.filter((event) => {
    if (event.entity !== NodeName.ORDER) return false;
    const order = orderStore.orders.get(event.id);
    if (!order) return false;
    return !!order.dryingBreak;
  }).length;

  const eventIdsByEntity = selectedEvents.reduce(
    (eventsById, event) => {
      if (!eventsById[event.entity]) {
        eventsById[event.entity] = [];
      }
      eventsById[event.entity].push({ id: event.id });
      return eventsById;
    },
    {} as Record<NodeName, Entity[]>,
  );

  multiActionsStore.removeEntities({
    orders: selectedEvents
      .filter((event) => orderStore.orders.has(event.id))
      .map((event) => orderStore.orders.get(event.id)!),
    milestones: selectedEvents
      .filter((event) => milestoneStore.milestones.has(event.id))
      .map((event) => milestoneStore.milestones.get(event.id)!),
    pauses: selectedEvents
      .filter((event) => pauseStore.pauses.has(event.id))
      .map((event) => pauseStore.pauses.get(event.id)!),
  });

  afterDelete(store);
  const deletedEntityGroups = Object.entries(eventIdsByEntity).reduce(
    (aggregate, [name, events]) => {
      aggregate[name] = events.length;
      return aggregate;
    },
    {},
  );
  if (numberOfDryingBreaksDeleted) {
    deletedEntityGroups[NodeName.DRYING_BREAK] = numberOfDryingBreaksDeleted;
  }
  return deletedEntityGroups;
}

function afterDelete(store: ScheduleStore): void {
  const scheduler = getScheduler();
  if (!scheduler) return;
  store.utils.allowEventDeselection = true;
  scheduler.clearEventSelection();
  // Fixes scroll issue when deleting events. See: https://github.com/bryntum/support/issues/2114
  scheduler.revertFocus(true);
  // Bryntum has an issue that events are not removed when they are the last in the schedule, a simple rerender fixes it
  setTimeout(() => {
    if (!store.entities.events.size) {
      refreshScheduleUI(scheduler);
    }
  });
}
