import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

import { WbsSection } from './types';
import { getSection, getSectionChildren, getSectionIndentation } from './utils/sectionTreeUtils';

export class SectionCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_CREATED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionDuplicatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_DUPLICATED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_DELETED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SECTION_EDITED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionArrangementEdited extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_ARRANGEMENT_EDITED;

  public constructor(
    public properties: {
      action: 'sort' | 'move';
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionVisibilityToggledEvent extends AnalyticsEvent {
  public readonly identification =
    AnalyticsEventCategories.SCHEDULE_COMPONENT_ROW_VISIBILITY_TOGGLED;

  public constructor(
    public properties: {
      isCollapsed: boolean;
      rowsAffected: 'one' | 'siblings' | 'children';
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionHierarchyAddedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_HIERARCHY_ADDED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}

export class SectionHierarchyRemovedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_HIERARCHY_REMOVED;

  public constructor(
    public properties: {
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
      hasPropagatedDeletions: boolean;
    },
  ) {
    super();
  }
}

export const getSectionAnalyticsType = (
  sectionsInput: WbsSection[] | Map<string, WbsSection>,
  sectionInput: WbsSection | string,
): 'phase' | 'mid1' | 'mid2' | 'activity' => {
  const section = getSection(sectionsInput, sectionInput);
  if (!section.parentId) return 'phase';
  if (!getSectionChildren(sectionsInput, section).length) return 'activity';

  const indentation = getSectionIndentation(sectionsInput, section);
  return `mid${indentation}` as 'mid1' | 'mid2';
};

export class SectionSummaryHoveredEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SECTION_SUMMARY_HOVERD;

  public constructor(
    public properties: {
      id: string;
      type: 'phase' | 'mid1' | 'mid2' | 'activity';
    },
  ) {
    super();
  }
}
