import { Composer } from 'vue-i18n';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { useOrderStore } from '@/features/orders';
import { orderIsImmutable } from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';
import { isPlaceholderOrDryingBreakPlaceholder } from '@/services/store/schedule/actions/placeholderEvent';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { SchedulerEvent } from '@/services/store/schedule/types';
import { SchedulePopupStore } from '@/services/store/schedulePopup';
import eventBus from '@/utils/eventBus';

import { showImmutableHint } from '../utils';

export interface BeforeEventResizeListener {
  beforeEventResize: ReturnType<typeof useBeforeEventResizeListener>;
}

export function useBeforeEventResizeListener(
  schedulePopupStore: SchedulePopupStore,
  i18n: Composer,
): (data: { eventRecord: SchedulerEvent }) => boolean {
  return ({ eventRecord }) => {
    if (isPlaceholderOrDryingBreakPlaceholder(eventRecord)) return true;

    schedulePopupStore.closePopup();
    const featureAccessStore = useFeatureAccessStore();
    if (
      eventRecord.entity === NodeName.PAUSE &&
      !featureAccessStore.hasWriteAccessToPausesAndMilestones
    ) {
      showImmutableHint(eventBus, i18n, 'assignment');
      return false;
    }

    if (eventRecord.entity === NodeName.ORDER || eventRecord.entity === NodeName.DRYING_BREAK) {
      const orderStore = useOrderStore();
      const order = orderStore.orders.get(eventRecord.id);

      if (order && orderIsImmutable(order)) {
        showImmutableHint(eventBus, i18n, 'completedFixed');
        return false;
      }

      let isOrderBelongingToOtherGroup =
        order && !featureAccessStore.features.ORDER_PLANNING(eventRecord.id).value.write;

      const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(eventRecord.id);

      if (orderId && !featureAccessStore.features.ORDER_PLANNING(orderId).value.write) {
        isOrderBelongingToOtherGroup = true;
      }

      if (isOrderBelongingToOtherGroup) {
        showImmutableHint(eventBus, i18n, 'assignment');
        return false;
      }
    }

    return true;
  };
}
