import { Ref, ref, watch } from 'vue';

import { debounce } from '@/helpers/utils/functions';

export interface UseSearch {
  isUserTyping: Ref<boolean>;
  searchTerm: Ref<string>;
  tmpSearchTerm: Ref<string>;
  onSearchInputUpdate: (newValue: string) => void;
}

/** Use helpers for searching for a specific item */
export function useSearch(onSearch?: (searchTerm: string) => void): UseSearch {
  const searchTerm = ref('');
  const tmpSearchTerm = ref('');
  const isUserTyping = ref(false);

  const debouncedVariableUpdate = debounce(() => {
    isUserTyping.value = false;
    searchTerm.value = tmpSearchTerm.value;
    if (onSearch) onSearch(searchTerm.value);
  }, 400);

  watch(tmpSearchTerm, () => {
    isUserTyping.value = true;
    debouncedVariableUpdate();
  });

  const onSearchInputUpdate = (newValue: string) => {
    tmpSearchTerm.value = newValue;
  };

  return {
    isUserTyping,
    searchTerm,
    tmpSearchTerm,
    onSearchInputUpdate,
  };
}

export interface SelectItem<T = Dictionary> {
  title: string;
  value: string | null;
  data?: T;
  group?: string;
}
