import { useWbsSectionStore } from '@/features/projectStructure';

import { TimeRangeParser } from '../../parsers';
import { ScheduleStore } from '../../store';

export function watchScheduleContextMarker(store: ScheduleStore) {
  const parser = new TimeRangeParser();
  const sectionStore = useWbsSectionStore();

  watch(
    () => store.utils.scheduleContextMarker,
    () => {
      if (store.utils.scheduleContextMarker) {
        const marker = parser.parseFromScheduleContext(
          sectionStore.wbsSections,
          store.utils.scheduleContextMarker,
        );
        if (marker) {
          store.entities.resourceTimeRanges.set(marker.id, marker);
        } else {
          store.entities.resourceTimeRanges.delete(parser.scheduleContextMarkerId);
        }
      } else {
        store.entities.resourceTimeRanges.delete(parser.scheduleContextMarkerId);
      }
    },
    { immediate: true },
  );
}
