import { getSectionChildren, getSectionIndentation, WbsSection } from '@/features/projectStructure';
import { EVENT_LAYOUT_NONE, EVENT_LAYOUT_STACK } from '@/features/schedule/bryntum/resources/const';
import {
  getCollisionResourceId,
  getEmptyResourceId,
  getMainResourceId,
} from '@/services/store/schedule/parsers/base';
import { SchedulerResource } from '@/services/store/schedule/types';

export class ResourceParser {
  public parseMainResource(id?: string): SchedulerResource {
    return {
      id: id ?? getMainResourceId(),
      name: ' ', // needs to include white space, otherwise bryntum fails to render
      cls: 'main-resource-row',
    };
  }

  public parseCollisionResource(): SchedulerResource {
    return {
      id: getCollisionResourceId(),
      name: ' ', // needs to include white space, otherwise bryntum fails to render
      cls: 'collision-resource-row',
      eventLayout: EVENT_LAYOUT_NONE,
    };
  }

  public parseEmptyResource(id?: string): SchedulerResource {
    return {
      id: id ?? getEmptyResourceId(),
      name: ' ', // needs to include white space, otherwise bryntum fails to render
      cls: 'empty-resource-row',
      rowHeight: 40,
    };
  }

  public parseSection(
    sections: WbsSection[] | Map<string, WbsSection>,
    section: WbsSection,
    collapsedRowIds: Set<string>,
  ): SchedulerResource {
    const children = getSectionChildren(sections, section);
    const sectionIndentation = getSectionIndentation(sections, section);

    const assignRowClass = (): string => {
      if (!section.parentId) {
        return 'top-level-row';
      }
      if (children.length) {
        return `mid-level-row-${sectionIndentation}`;
      }
      return 'bottom-level-row';
    };

    const getEventLayout = () => (children.length ? EVENT_LAYOUT_NONE : EVENT_LAYOUT_STACK);

    const assignRowType = (): Partial<SchedulerResource> => {
      if (!section.parentId) {
        return { isTopLevelRow: true };
      }
      if (children.length) {
        return { isMidLevelRow: true };
      }
      return { isBottomLevelRow: true };
    };

    return {
      id: section.id,
      name: section.name,
      cls: assignRowClass(),
      expanded: !collapsedRowIds.has(section.id),
      eventLayout: getEventLayout(),
      parentId: section.parentId ?? null,
      ...assignRowType(),
      sectionIndentation,
    };
  }
}
