export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 30;
export const DAYS_PER_WEEK = 7;
export const HOURS_PER_DAY = 24;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = HOURS_PER_DAY * MINUTES_PER_HOUR;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * DAYS_PER_WEEK;
export const MINUTES_PER_YEAR = MINUTES_PER_DAY * DAYS_PER_YEAR;
export const MILLISECONDS_PER_SECOND = 1000;

export const SEVEN_SECONDS_IN_MS = MILLISECONDS_PER_SECOND * 7;

export const FIVE_MINUTES_IN_MS = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * 5;
