import { Composer } from 'vue-i18n';

import { EventBus } from '@/utils/eventBus';

export function showImmutableHint(
  eventBus: EventBus,
  i18n: Composer,
  type: 'completedFixed' | 'assignment',
  overwrite = true,
): void {
  eventBus.emit(
    'new-notification',
    i18n.t(
      type === 'completedFixed'
        ? 'errors.scheduler.completedFixedImmutableHint'
        : 'errors.scheduler.assignmentImmutableHint',
    ),
    undefined,
    undefined,
    undefined,
    overwrite,
  );
}
