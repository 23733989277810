import { DomConfig } from '@bryntum/schedulerpro';
import tinycolor from 'tinycolor2';

import { EventRendererData, RenderData } from '@/common/bryntum/types';
import { useMilestoneStore } from '@/features/milestones';
import { getHexFromCssVariable } from '@/helpers/utils/colors';
import { escapeCharacters } from '@/helpers/utils/escapeCharacters';
import { USERBACK_IGNORE_CLASS } from '@/plugins/userback';
import { MilestoneEventParser } from '@/services/store/schedule/parsers';
import { SchedulerEvent, SchedulerResource } from '@/services/store/schedule/types';

type MilestoneStyle = {
  labelBackgroundColor: string;
  cls: string[];
};

const getMilestoneEventTextElement = (textContent: string) => {
  return {
    tag: 'span',
    className: 'milestone-event-text',
    text: textContent,
  };
};

export function renderMilestoneWithCollisions({
  eventRecord: record,
  renderData,
  resourceRecord,
}: EventRendererData) {
  const eventRecord = record as SchedulerEvent;

  if (eventRecord.collisionCounter !== null) {
    const style = getMilestoneStyleWhenColliding(renderData);
    const sumOfCollisions = eventRecord.collisionCounter.start + eventRecord.collisionCounter.end;

    renderMilestone(
      { eventRecord: record, renderData, resourceRecord },
      style,
      sumOfCollisions > 1 ? getMilestoneEventTextElement(sumOfCollisions.toString(10)) : null,
    );
    return;
  }

  const style = getMilestoneStyle(renderData);

  renderMilestone({ eventRecord: record, renderData, resourceRecord }, style);
}

export function renderMilestone(
  { eventRecord, renderData, resourceRecord }: EventRendererData,
  style: MilestoneStyle = getMilestoneStyle(renderData),
  milestoneTextElement: DomConfig | null = null,
) {
  const { labelBackgroundColor, cls } = style;

  cls.forEach((clsToAdd) => {
    renderData.cls[clsToAdd] = true;
  });

  const numOfOverlappingMilestones = useMilestoneStore().getNumberOfOverlappingMilestonesForDate(
    new SchedulingDate(eventRecord.startDate),
    resourceRecord as SchedulerResource,
  );

  let overlappingMilestoneTextElement: DomConfig | null = null;
  if (numOfOverlappingMilestones > 1) {
    renderData.cls['--overlapping'] = true;
    overlappingMilestoneTextElement = getMilestoneEventTextElement(
      `+${numOfOverlappingMilestones - 1}`,
    );
  }

  renderData.children = constructMilestoneContent(
    {
      renderData,
      eventRecord,
      resourceRecord,
    },
    labelBackgroundColor,
    overlappingMilestoneTextElement || milestoneTextElement,
  );
}

function getMilestoneStyle(renderData: RenderData) {
  const milestoneColor = tinycolor(renderData.eventColor ?? '#FFF');
  const cls: string[] = [];

  if (milestoneColor.isLight()) cls.push('--light');
  let labelBackgroundColor = milestoneColor.isDark()
    ? milestoneColor.brighten(30).desaturate(30).toHexString()
    : milestoneColor.brighten(5).desaturate(30).toHexString();
  if (tinycolor.equals(labelBackgroundColor, '#ffffff')) {
    labelBackgroundColor = milestoneColor.getOriginalInput() as string;
  }

  return {
    labelBackgroundColor,
    cls,
  };
}

function getMilestoneStyleWhenColliding(renderData: RenderData) {
  renderData.eventColor = getHexFromCssVariable('--color-attention-500');
  return {
    labelBackgroundColor: '#FFFFFF',
    cls: ['--light', '--colliding'],
  };
}

function constructMilestoneContent(
  { eventRecord, renderData }: EventRendererData,
  labelBackgroundColor: string,
  milestoneEventTextElement: DomConfig | null = null,
): DomConfig[] {
  const day = new SchedulingDate(eventRecord.startDate).getDate().toString().padStart(2, '0');
  const month = `${new SchedulingDate(eventRecord.startDate).getMonth() + 1}`.padStart(2, '0');
  const width = MilestoneEventParser.getActualMilestoneLabelWidth(eventRecord as SchedulerEvent);

  renderData.cls['--has-event-text'] = !!milestoneEventTextElement;

  return [
    ...(milestoneEventTextElement ? [milestoneEventTextElement] : []),
    {
      tag: 'div',
      className: 'milestone-event-label',
      style: {
        backgroundColor: labelBackgroundColor,
        left: `${renderData.height / 2}px`,
        paddingLeft: `${renderData.height / 2 + 4}px`,
        paddingRight: `2px`,
        position: 'absolute',
        width: `${width}px`,
        top: '50%',
        transform: 'translateY(-50%)',
      },
      html: `
        <div class="milestone-event-label-date">
            <p>${day}</p>
            <p>${month}</p>
        </div>
        ${
          eventRecord.name
            ? `
          <div class="milestone-event-label-divider"></div>
          <p class="milestone-event-label-text ${USERBACK_IGNORE_CLASS}">${escapeCharacters(eventRecord.name)}</p>
        `
            : ``
        }
      `,
    },
  ];
}
