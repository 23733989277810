import { ProjectContributorGroupChangePayload } from 'events.schema';

import { ProjectContributorGroupType, ProjectPermission } from '@/graphql/__generated__/graphql';

export interface PartialContributorGroup {
  permission: ProjectPermission;
  type: ProjectContributorGroupType;
  tenantTradeVariationAssignments: {
    tenantTradeVariation: {
      id: string;
    };
    isDefaultContributor: boolean;
  }[];
  id: string;
  name: string;
  isTenantOwnerGroup: boolean;
  memberships: {
    id: string;
    permission: string;
    userId: string;
  }[];
}

export function getPartialContributorGroupsMapFromPayload(
  payload: ProjectContributorGroupChangePayload,
): Map<string, PartialContributorGroup> {
  return new Map(
    payload.contributorGroups.map((group) => {
      const item = {
        ...group,
        permission: group.permission as ProjectPermission,
        type:
          group.type === 'INTERNAL'
            ? ProjectContributorGroupType.Internal
            : ProjectContributorGroupType.External,
        tenantTradeVariationAssignments: group.tenantTradeVariationAssignments.map((assignment) => {
          return {
            tenantTradeVariation: { id: assignment.tenantTradeVariationId },
            isDefaultContributor: assignment.isDefaultContributor,
          };
        }),
      };
      return [group.id, item];
    }),
  );
}
