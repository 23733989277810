import {
  EventStore,
  ResourceStore,
  ResourceTimeRangeStore,
  SchedulerProConfig,
  TimeRangeStore,
} from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';

import { baseSchedulerProConfig } from '@/common/bryntum/baseSchedulerProConfig';
import { ScheduleConstants } from '@/common/bryntum/constants';
import { LoggingService } from '@/interfaces/services';
import { ScheduleStore } from '@/services/store/schedule';
import { getColumns } from '@/services/store/schedule/config/columns';
import { getCustomIsValidDependency } from '@/services/store/schedule/config/listeners/dependencies';
import { getScheduleListeners } from '@/services/store/schedule/config/listeners/schedule';
import { PresetLoader } from '@/services/store/schedule/config/presetLoader';
import { getEventRenderer } from '@/services/store/schedule/config/renderers/eventRenderer';
import {
  CustomDependencyModel,
  CustomDependencyStore,
  CustomEventModel,
  CustomResourceModel,
} from '@/services/store/schedule/utils/overrides';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';

const FIFTEEN_YEARS_IN_MILLISECONDS = 15 * 365 * 24 * 3600000;

export function setConfig(
  store: ScheduleStore,
  i18n: Composer,
  loggingService: LoggingService,
): Partial<SchedulerProConfig> {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const initialPreset = scheduleAppearanceStore.currentZoomPreset;
  const presets = new PresetLoader(ScheduleConstants.MINUTE_SNAP).registerPresets(i18n);
  scheduleAppearanceStore.zoomPresets = presets.map((preset) => preset.id as string);
  const dependencyStore = new CustomDependencyStore({ modelClass: CustomDependencyModel });
  dependencyStore.injectCustomIsValidDependencyFunction(getCustomIsValidDependency(true));

  return {
    ...baseSchedulerProConfig,
    columns: getColumns(store, i18n, loggingService),
    createEventOnDblClick: false,
    dependencyStore,
    deselectAllOnScheduleClick: false,
    deselectOnClick: false,
    destroyStores: true,
    displaySchedulingIssueResolutionPopup: false,
    enableDeleteKey: false,
    // during tests event and row animations contribute to flakiness
    enableEventAnimations: false,
    eventRenderer: getEventRenderer(store),
    eventStore: new EventStore({
      modelClass: CustomEventModel,
    }),
    fullRowRefresh: false,
    infiniteScroll: true,
    isEventSelectable() {
      return !store.utils.disableEventInteractions;
    },
    // @ts-expect-error NOTE: The typing in the listeners is based on the static bryntum types models, however, we use custom models, so there always will be a type mismatch
    listeners: getScheduleListeners(store, i18n, loggingService),
    multiEventSelect: true,
    presets,
    project: {
      enableProgressNotifications: false,
      // fixes bug that dates where changed to 23.00 when overlapping DST time
      adjustDurationToDST: true,
      // avoids implicit constraint setting when updating events
      addConstraintOnDateSet: false,
      maxCalendarRange: FIFTEEN_YEARS_IN_MILLISECONDS,
    },
    resourceStore: new ResourceStore({
      modelClass: CustomResourceModel,
      tree: true,
      transformFlatData: true,
    }),
    resourceTimeRangeStore: new ResourceTimeRangeStore(),
    selectionMode: {
      row: true,
      cell: false,
    },
    snapRelativeToEventStartDate: true,
    timeRangeStore: new TimeRangeStore(),
    useInitialAnimation: false,
    viewPreset: initialPreset,
    visibleDate: {
      date: new Date(scheduleAppearanceStore.scrollDate ?? Date.now()),
      block: 'center',
    },
    zoomKeepsOriginalTimespan: true,
    zoomOnMouseWheel: false,
    transitionDuration: 50,
  };
}
