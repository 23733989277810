import { EventModel, SchedulerEventModel, SchedulerPro } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { FeatureAccessStore } from '@/common/featureAccessStore';
import { createMilestone } from '@/components/schedule/utils';
import { onClickCollision } from '@/features/collisions/bryntum/interactions';
import { MilestoneStore, MilestoneType } from '@/features/milestones/types';
import { DependenciesHighlightedEvent } from '@/features/orderDependencies';
import { OrderStore, useOrderStore } from '@/features/orders';
import {
  checkAreEventsInResourceStacked,
  overlapEventsInResource,
  stackEventsInResource,
} from '@/features/schedule/bryntum/resources/layout';
import { getAbsolutePosition } from '@/helpers/utils/elements';
import { debounce } from '@/helpers/utils/functions';
import { isWinCtrlOrMacCmdKeyPressed } from '@/helpers/utils/os';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { isPlaceholderOrDryingBreakPlaceholder } from '@/services/store/schedule/actions/placeholderEvent';
import { getOpenSidebarComponent } from '@/services/store/schedule/actions/sidebar';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { getPlaceholderEventId } from '@/services/store/schedule/parsers/base';
import {
  SchedulerEvent,
  SchedulerResource,
  SchedulerSidebarComponent,
  ScheduleScrollHelper,
} from '@/services/store/schedule/types';
import { isCreateSidebar, isEditSidebar } from '@/services/store/schedule/utils/sidebar';
import { trackMultiSelect } from '@/services/store/schedule/utils/tracking';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { SchedulePopupStore, SchedulerPopupComponent } from '@/services/store/schedulePopup';
import { useStatusReportModalStore } from '@/services/store/statusReportModal/store';
import { trackSchedulerEventOpen } from '@/utils/analyticsEvents/scheduleTracking';

import { openPopupWithTimeout } from '../../utils/popup';
import {
  BeforeEventDragListener,
  BeforeEventDropFinalizeListener,
  EventDragListener,
  EventDragResetListener,
  EventDragStartListener,
  useBeforeEventDragListener,
  useBeforeEventDropFinalizeListener,
  useEventDragListener,
  useEventDragResetListener,
  useEventDragStartListener,
} from './eventDragAndDrop';
import {
  BeforeEventResizeFinalizeListener,
  BeforeEventResizeListener,
  useBeforeEventResizeFinalizeListener,
  useBeforeEventResizeListener,
} from './eventResize';

export interface EventListeners
  extends BeforeEventDragListener,
    BeforeEventResizeListener,
    BeforeEventResizeFinalizeListener,
    BeforeEventDropFinalizeListener,
    EventDragStartListener,
    EventDragListener,
    EventDragResetListener {
  eventClick: (data: {
    eventRecord: SchedulerEvent;
    event: MouseEvent;
    date: Date;
    resourceRecord: SchedulerResource;
  }) => void;
  eventDblClick: (data: {
    date: Date;
    resourceRecord: SchedulerResource;
    eventRecord: SchedulerEvent;
    event: MouseEvent;
  }) => void;
  eventMouseOver: (data: {
    event: MouseEvent;
    eventRecord: SchedulerEvent;
    resourceRecord: SchedulerResource;
  }) => void;
  eventMouseDown: (data: { event: MouseEvent; eventRecord: SchedulerEvent }) => void;
  eventMouseLeave: (data: { event: MouseEvent; eventRecord: SchedulerEvent }) => void;
  eventContextMenu: (data: { eventRecord: SchedulerEvent; event: MouseEvent }) => void;
  beforeEventDragSelect: () => void;
  eventSelectionChange: (data: {
    action: string;
    selection: SchedulerEvent[];
    selected: SchedulerEvent[];
    deselected: SchedulerEvent[];
  }) => void;
}

interface GetEventListenersParams {
  featureAccessStore: FeatureAccessStore;
  i18n: Composer;
  loggingService: LoggingService;
  milestoneStore: MilestoneStore;
  schedulePopupStore: SchedulePopupStore;
  scrollHelper: ScheduleScrollHelper;
  store: ScheduleStore;
}

export function getEventListeners({
  featureAccessStore,
  i18n,
  loggingService,
  milestoneStore,
  schedulePopupStore,
  scrollHelper,
  store,
}: GetEventListenersParams): EventListeners {
  const debouncedEventClickTracking = debounce((eventToOpen: SchedulerEvent) => {
    trackSchedulerEventOpen(loggingService, eventToOpen.entity, eventToOpen);
  }, ScheduleConstants.DOUBLE_CLICK_TIMEOUT);
  let hoveredEventTimeoutId: number | null = null;
  const isDoubleClick = ref(false);

  return {
    eventClick({ eventRecord, event, date, resourceRecord }): void {
      const scheduler = getScheduler();

      if (
        !scheduler ||
        eventRecord.id === getPlaceholderEventId() ||
        store.utils.disableEventInteractions
      ) {
        return;
      }

      if (
        eventRecord.entity === NodeName.SECTION ||
        eventRecord.entity === NodeName.SECTION_BASEPLAN
      ) {
        handleSectionOrBaseplanClick({
          date,
          event,
          featureAccessStore,
          isDoubleClick,
          loggingService,
          resourceRecord,
          schedulePopupStore,
          scheduler,
          scheduleStore: store,
        });

        return;
      }

      if (eventRecord.entity === NodeName.COLLISION) {
        onClickCollision(scheduler, store, eventRecord);
        return;
      }

      const currentOpenSidebar = getOpenSidebarComponent(store.sidebar);
      if (
        currentOpenSidebar &&
        currentOpenSidebar !== SchedulerSidebarComponent.ORDER_DETAILS &&
        (isEditSidebar(currentOpenSidebar) || isCreateSidebar(currentOpenSidebar))
      ) {
        return;
      }

      let eventToOpen = eventRecord;
      // when clicking drying break, we want to open the related order
      if (eventRecord.entity === NodeName.DRYING_BREAK) {
        eventToOpen = scheduler.eventStore.getById(
          DryingBreakEventParser.dryingBreakIdToOrderId(eventRecord.id),
        ) as SchedulerEvent;
      }

      const isCtrlOrCmd = isWinCtrlOrMacCmdKeyPressed(event);
      const shouldExpandResource =
        !isCtrlOrCmd &&
        eventRecord.entity === NodeName.MILESTONE &&
        milestoneStore.checkDateHasOverlappingMilestones(
          new SchedulingDate(eventRecord.startDate),
          resourceRecord,
        );

      if (shouldExpandResource) {
        stackEventsInResource(resourceRecord, loggingService);
        store.utils.allowEventDeselection = true;
        scheduler.clearEventSelection();
      } else if (!isCtrlOrCmd) {
        scheduler.selectEvents([eventToOpen] as SchedulerEventModel[], false);
        store.openSidebar({ event: eventToOpen });
        debouncedEventClickTracking(eventToOpen);
      } else {
        if (store.readonly) {
          store.utils.allowEventDeselection = true;
          scheduler.clearEventSelection();
          store.closeSidebar();
          return;
        }
        store.filterAndSetMultiSelectedEvents();

        if (store.isMultiSelectToolbarOpen) {
          trackMultiSelect(store, loggingService);
        }
      }
      store.clearClipboard();
    },
    eventDblClick({ date, resourceRecord, eventRecord, event }): void {
      isDoubleClick.value = true;

      if (eventRecord.id === getPlaceholderEventId()) {
        return;
      }

      if (eventRecord.entity === NodeName.COLLISION) {
        return;
      }
      if (
        eventRecord.entity === NodeName.SECTION &&
        !store.readonly &&
        featureAccessStore.hasWriteAccessToPausesAndMilestones
      ) {
        createMilestone(date, resourceRecord.id, 'double_click', MilestoneType.FIXED);

        setTimeout(() => {
          isDoubleClick.value = false;
        }, ScheduleConstants.DOUBLE_CLICK_TIMEOUT);

        return;
      }

      if (!isWinCtrlOrMacCmdKeyPressed(event)) {
        const scheduler = getScheduler();
        if (!scheduler) return;
        let eventToOpen = eventRecord;
        // when clicking drying break, we want to open the related order
        if (eventRecord.entity === NodeName.DRYING_BREAK) {
          eventToOpen = scheduler.eventStore.getById(
            DryingBreakEventParser.dryingBreakIdToOrderId(eventRecord.id),
          ) as SchedulerEvent;
        }

        useScheduleAppearanceStore().setEventIdToHighlightRelationsFor(eventToOpen.id);
        loggingService.trackEvent(
          new DependenciesHighlightedEvent({
            method: 'double_click',
            active: true,
          }),
        );
      }

      isDoubleClick.value = false;
    },
    eventMouseOver: ({ event, eventRecord, resourceRecord }) => {
      const scheduler = getScheduler();
      if (hoveredEventTimeoutId) {
        clearTimeout(hoveredEventTimeoutId);
      }

      const isOverlappingMilestone =
        eventRecord.entity === NodeName.MILESTONE &&
        milestoneStore.checkDateHasOverlappingMilestones(
          new SchedulingDate(eventRecord.startDate),
          resourceRecord,
        );

      // Skip mouse over during scrolling, multi select, and for placeholder events.
      const skipMouseOver =
        scrollHelper.isScrolling ||
        scheduler?.selectedEvents.length ||
        store.utils.isDraggingEvent ||
        store.utils.isDraggingDependency ||
        store.utils.showDragCursor ||
        isPlaceholderOrDryingBreakPlaceholder(eventRecord) ||
        eventRecord.entity === NodeName.SECTION ||
        eventRecord.entity === NodeName.SECTION_BASEPLAN ||
        isOverlappingMilestone ||
        store.utils.disableEventInteractions;

      const orderStore = useOrderStore();

      if (
        shouldDisableEventResizing({
          eventRecord,
          scheduleStore: store,
          featureAccessStore,
          orderStore,
        })
      ) {
        disableEventResizing(eventRecord, scheduler);
      }

      if (skipMouseOver) {
        return;
      }

      hoveredEventTimeoutId = window.setTimeout(() => {
        schedulePopupStore.openPopup({
          component: SchedulerPopupComponent.EVENT_HOVER,
          payload: {
            entity: eventRecord.entity,
            id: eventRecord.id,
            mouseEvent: getAdjustedPopupMouseEvent(event, eventRecord.id),
          },
          submissive: true,
        });
      }, ScheduleConstants.EVENT_HOVER_TIMEOUT);
    },
    eventMouseLeave: ({ eventRecord }) => {
      const scheduler = getScheduler();

      if (hoveredEventTimeoutId) {
        clearTimeout(hoveredEventTimeoutId);
      }

      if (
        store.utils.disableEventInteractions &&
        !isPlaceholderOrDryingBreakPlaceholder(eventRecord)
      ) {
        enableEventResizing(eventRecord, scheduler);
      }

      if (scrollHelper.isScrolling) {
        return;
      }
      schedulePopupStore.closePopup(SchedulerPopupComponent.EVENT_HOVER);
    },
    eventMouseDown({ event }): void {
      store.utils.isMultiSelecting = isWinCtrlOrMacCmdKeyPressed(event);
    },
    eventContextMenu({ eventRecord, event }): void {
      const scheduler = getScheduler();
      if (!scheduler || store.readonly) return;

      if (!eventRecord.isSelectable) {
        return;
      }

      store.clearClipboard();
      event.preventDefault();

      const currentOpenSidebar = getOpenSidebarComponent(store.sidebar);
      if (currentOpenSidebar && isEditSidebar(currentOpenSidebar)) return;

      if (scheduler.isEventSelected(eventRecord as SchedulerEventModel)) return;

      store.filterAndSetMultiSelectedEvents(undefined, [eventRecord]);
    },
    beforeEventDragSelect(): void {
      store.utils.multiSelectedEvents = new Map();
      store.utils.isMultiSelecting = false;
      store.utils.isDragSelecting = true;
      store.closeSidebar({ preserveEventSelection: true });
    },
    eventSelectionChange(data): void {
      useScheduleAppearanceStore().setEventIdToHighlightRelationsFor(null);
      const scheduler = getScheduler()!;

      // make sure that unselectable events are not selected
      const unselectableEvents = data.selected.filter(
        (event) => !event.isSelectable && event.entity !== NodeName.DRYING_BREAK,
      );
      if (unselectableEvents.length) {
        store.utils.allowEventDeselection = true;
        scheduler.deselectEvents(unselectableEvents as SchedulerEventModel[]);
        return;
      }

      if (
        data.action === 'clear' &&
        store.utils.hasEventSelection &&
        !store.utils.allowEventDeselection
      ) {
        scheduler.selectEvents(data.deselected as SchedulerEventModel[]);
        return;
      }
      schedulePopupStore.closePopup();

      store.utils.allowEventDeselection = false;
      store.utils.hasEventSelection = !!data.selection.length;

      const statusReportModalStore = useStatusReportModalStore();
      if (statusReportModalStore.isOpen) return;

      if (data.selection.length) {
        store.clearClipboard();
      }
      if (!store.utils.isMultiSelecting && !store.utils.isDragSelecting) {
        store.utils.multiSelectedEvents = new Map();
      }
    },
    beforeEventDrag: useBeforeEventDragListener(store, schedulePopupStore, i18n),
    eventDragStart: useEventDragStartListener(store),
    eventDrag: useEventDragListener(store),
    beforeEventDropFinalize: useBeforeEventDropFinalizeListener(store, loggingService),
    eventDragReset: useEventDragResetListener(store),
    beforeEventResize: useBeforeEventResizeListener(schedulePopupStore, i18n),
    beforeEventResizeFinalize: useBeforeEventResizeFinalizeListener(store, loggingService),
  };
}

/**
 * Creates a new `MouseEvent` with adjusted `clientX` and `clientY` properties to correctly
 * position an event hover popup based on the related event's position in the scheduler.
 *
 * - If there's enough space, it positions the popup below the event element. If there's not enough space,
 * it positions the popup above the event element.
 * - Adjusts the `x` coordinate to prevent the popup from overlapping with the resource column.
 * - If the element is not provided or not found in the DOM, returns the original `MouseEvent`.
 */
export function getAdjustedPopupMouseEvent(
  mouseEvent: MouseEvent,
  element: HTMLElement | string | undefined | null,
  edge: 'left' | 'right' = 'left',
): MouseEvent {
  if (!element) {
    return mouseEvent;
  }

  const domElement = typeof element === 'string' ? getByEventId(element) : element;

  if (!domElement) {
    return mouseEvent;
  }

  const position = getAbsolutePosition(domElement);
  const offset = 4;
  const y = position.y + domElement.offsetHeight + offset;
  // Determine whether to position the popup to the left or right of the event element
  let x = edge === 'left' ? position.x : position.x + domElement.offsetWidth;

  // prevent event hover from overlapping with the resource column
  const timeAxisSubGridElement = document.querySelector('.b-timeaxissubgrid');
  if (timeAxisSubGridElement) {
    const timeAxisSubGridRect = timeAxisSubGridElement.getBoundingClientRect();
    x = Math.max(x, timeAxisSubGridRect.left + offset);
  }

  return new MouseEvent(mouseEvent.type, {
    ...mouseEvent,
    clientY: y,
    clientX: x,
  });
}

export function getByEventId(id: string): HTMLElement | null {
  return document.querySelector(`[data-event-id="${id}"]`);
}

function hasAccessToEvent(
  eventRecord: SchedulerEvent,
  featureAccessStore: FeatureAccessStore,
  orderStore: OrderStore,
): boolean {
  if (eventRecord.entity === NodeName.ORDER) {
    const order = orderStore.orders.get(eventRecord.id);
    if (order) {
      return featureAccessStore.features.ORDER_PLANNING(order.id).value.write;
    }
  }

  if (eventRecord.entity === NodeName.DRYING_BREAK) {
    const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(eventRecord.id);

    const order = orderStore.orders.get(orderId);
    if (order) {
      return featureAccessStore.features.ORDER_PLANNING(order.id).value.write;
    }
  }

  if (eventRecord.entity === NodeName.PAUSE || eventRecord.entity === NodeName.MILESTONE) {
    return featureAccessStore.hasWriteAccessToPausesAndMilestones;
  }

  return false;
}

interface ShouldDisableEventResizingParams {
  eventRecord: SchedulerEvent;
  scheduleStore: ScheduleStore;
  featureAccessStore: FeatureAccessStore;
  orderStore: OrderStore;
}
function shouldDisableEventResizing({
  eventRecord,
  scheduleStore,
  featureAccessStore,
  orderStore,
}: ShouldDisableEventResizingParams): boolean {
  if (isPlaceholderOrDryingBreakPlaceholder(eventRecord)) {
    return false;
  } else {
    return (
      scheduleStore.utils.disableEventInteractions ||
      !hasAccessToEvent(eventRecord, featureAccessStore, orderStore)
    );
  }
}

export function disableEventResizing(event: SchedulerEvent, scheduler: SchedulerPro | undefined) {
  const eventModel = scheduler?.eventStore.getById(event.id) as EventModel;
  eventModel.resizable = false;
}

export function enableEventResizing(event: SchedulerEvent, scheduler: SchedulerPro | undefined) {
  const eventModel = scheduler?.eventStore.getById(event.id) as EventModel;
  eventModel.resizable = true;
}

interface HandleSectionOrSectionBaseplanClick {
  date: Date;
  event: MouseEvent;
  featureAccessStore: FeatureAccessStore;
  isDoubleClick: Ref<boolean>;
  loggingService: LoggingService;
  resourceRecord: SchedulerResource;
  schedulePopupStore: SchedulePopupStore;
  scheduler: SchedulerPro;
  scheduleStore: ScheduleStore;
}
function handleSectionOrBaseplanClick({
  date,
  event,
  featureAccessStore,
  isDoubleClick,
  loggingService,
  resourceRecord,
  schedulePopupStore,
  scheduler,
  scheduleStore,
}: HandleSectionOrSectionBaseplanClick) {
  scheduleStore.utils.allowEventDeselection = true;
  scheduler.clearEventSelection();
  scheduleStore.closeSidebar();
  if (schedulePopupStore.isOpenPopup(SchedulerPopupComponent.SCHEDULE_SUMMARY_SECTION_CLICK)) {
    return;
  }

  if (checkAreEventsInResourceStacked(resourceRecord)) {
    overlapEventsInResource(resourceRecord, loggingService);
    return;
  }

  if (!scheduleStore.readonly && featureAccessStore.hasWriteAccessToPausesAndMilestones) {
    const schedulingDate = new SchedulingDate(date);

    const popupPayload = {
      mouseEvent: event,
      schedulingDate,
      wbsSectionId: resourceRecord.id,
    };

    openPopupWithTimeout(
      SchedulerPopupComponent.SCHEDULE_SUMMARY_SECTION_CLICK,
      popupPayload,
      isDoubleClick,
    );
  }
}
