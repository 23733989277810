import { DependencyType } from '@koppla-tech/scheduling-engine';

import { OrderDependencyEntity, TradeSequenceActivityDependencyEntity } from '@/common/types';
import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Schedule handling */

export class DependencyCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_CREATED;

  public constructor(
    public properties: { causeRescheduling: boolean; source: 'schedule' | 'sequence_editor' },
  ) {
    super();
  }
}

export function getDependencyTypeOfSelection(
  dependencies: (OrderDependencyEntity | TradeSequenceActivityDependencyEntity | undefined)[],
): 's_s' | 's_f' | 'f_s' | 'f_f' | 'multiple' {
  const types = dependencies.map((d) => d?.type).filter(Boolean) as DependencyType[];
  const set = new Set(types);
  if (set.size > 1) {
    return 'multiple';
  }
  const value = set.values().next().value;
  if (value === DependencyType.FF) {
    return 'f_f';
  }
  if (value === DependencyType.SF) {
    return 's_f';
  }
  if (value === DependencyType.FS) {
    return 'f_s';
  }
  return 's_s';
}

export class DependencyLagViolatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_LAG_VIOLATED;

  public constructor(
    public properties: {
      method: 'details' | 'drag_and_drop' | 'resize';
      dependencyType: 's_s' | 's_f' | 'f_s' | 'f_f' | 'multiple';
      count: number;
      source: 'schedule' | 'sequence_editor';
    },
  ) {
    super();
  }
}

export class DependencyViolationOverruledEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_VIOLATION_OVERRULED;

  public constructor(
    public properties: {
      source: 'schedule' | 'sequence_editor';
    },
  ) {
    super();
  }
}

export class DependencyOpenedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_OPENED;
}

type SharedMethod = 'details' | 'order_details' | 'milestone_details';

export class DependenciesDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCIES_DELETED;

  public constructor(
    public properties: {
      method: 'keyboard' | SharedMethod | 'delete_all' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

export class DependenciesEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.DEPENDENCIES_EDITED;

  public constructor(public properties: { method: SharedMethod; count: number }) {
    super();
  }
}

export class DependenciesHighlightedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCIES_HIGHLIGHTED;

  public constructor(public properties: { method: 'details' | 'double_click'; active: boolean }) {
    super();
  }
}

export class ViolatedDependencyLagUpdatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VIOLATED_DEPENDENCY_LAG_UPDATED;

  public constructor(
    public properties: {
      distance: number;
      oldLag: number;
      newLag: number;
    },
  ) {
    super();
  }
}
