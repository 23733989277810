export class ScheduleConstants {
  public static MARGIN = {
    RESOURCE: 6,
    BAR: 5,
  };

  public static ROW_HEIGHT = 40;

  public static MINUTE_SNAP = 30;

  public static DATE_RANGE_OFFSET_IN_WEEKS = 3;

  public static DEFAULT_EVENT_DURATION_IN_DAYS = 1;

  public static DOUBLE_CLICK_TIMEOUT = 250;

  public static EVENT_HOVER_TIMEOUT = 350;

  public static DEFAULT_EVENT_DURATION_IN_DAYS_NON_DAY_VIEW = 5;

  public static MAX_ROW_INDENTATION = 3; // 2 mid levels and 1 bottom level

  public static MAX_TOP_LEVELS = 10;

  public static DEPENDENCY_RADIUS = 4;
}
