import { DomClassList } from '@bryntum/schedulerpro';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { FeatureAccessStore } from '@/common/featureAccessStore';
import { getPdfExportConfig } from '@/features/filter-and-export/pdfExportConfig';
import { NodeName } from '@/repositories/utils/cache';
import { ScheduleStore } from '@/services/store/schedule';
import {
  getRenderDependencyCreateTooltip,
  getRenderDependencyTooltip,
  getRenderDragTooltip,
  getRenderResizeTooltip,
} from '@/services/store/schedule/config/features/tooltips';
import { getDependencyRenderer } from '@/services/store/schedule/config/renderers/dependencyRenderer';
import { schedulerClassConfig } from '@/services/store/schedule/parsers/base';
import { SchedulerEventColorType, SchedulerFeaturesRef } from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { useSchedulePopupStore } from '@/services/store/schedulePopup';
import { Language } from '@/utils/languages';

export function getFeatures(
  store: ScheduleStore,
  featureAccessStore: FeatureAccessStore,
): SchedulerFeaturesRef {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const schedulePopupStore = useSchedulePopupStore();
  const i18n = useI18n();

  const disableEventInteractions = computed(() => store.utils.disableEventInteractions);

  return computed(() => ({
    cellCopyPasteFeature: {
      disabled: true,
    },
    cellMenuFeature: {
      disabled: true,
    },
    cellEditFeature: {
      disabled: true,
    },
    dependenciesFeature: {
      drawOnScroll: false,
      renderer: getDependencyRenderer(),
      showTooltip: !disableEventInteractions.value && !schedulePopupStore.popup,
      terminalSides: ['left', 'right'],
      tooltipTemplate: getRenderDependencyTooltip(i18n, store.entities.events),
      tooltip: {
        bodyCls: 'tw-max-w-[400px]',
      },
      radius: ScheduleConstants.DEPENDENCY_RADIUS,
      creationTooltipTemplate: getRenderDependencyCreateTooltip(
        i18n,
        store.entities.events,
        featureAccessStore,
      ),
    },
    dependencyEditFeature: {
      disabled: true,
    },
    eventCopyPasteFeature: {
      disabled: true,
    },
    eventDragFeature: {
      copyKey: '',
      disabled: store.readonly,
      tooltipTemplate: getRenderDragTooltip(i18n),
    },
    eventDragCreateFeature: {
      disabled: true,
    },
    eventDragSelectFeature: {
      disabled: store.readonly || disableEventInteractions.value,
    },
    eventMenuFeature: {
      disabled: true,
    },
    eventResizeFeature: {
      disabled: store.readonly || store.utils.hasEventSelection,
      tooltipTemplate: getRenderResizeTooltip(i18n),
      lockLayout: false,
    },
    eventTooltipFeature: {
      disabled: true,
    },
    headerMenuFeature: {
      disabled: true,
    },
    lockRowsFeature: {
      disabled: true,
      fieldName: 'sticky',
    },
    pdfExportFeature: getPdfExportConfig(),
    percentBarFeature: {
      allowResize: false,
      disabled: scheduleAppearanceStore.eventColorType !== SchedulerEventColorType.STATUS,
    },
    regionResizeFeature: {
      disabled: false,
      showSplitterButtons: false,
      animateCollapseExpand: false,
    },
    resourceTimeRangesFeature: {
      disabled: false,
    },
    rowCopyPasteFeature: {
      disabled: true,
    },
    rowReorderFeature: {
      disabled: false,
      hoverExpandTimeout: 9999999999,
    },
    scheduleMenuFeature: {
      disabled: true,
    },
    scheduleTooltipFeature: {
      disabled: true,
    },
    stickyEventsFeature: {
      disabled: false,
    },
    taskEditFeature: {
      disabled: true,
    },
    timeAxisHeaderMenuFeature: {
      disabled: true,
    },
    timeRangesFeature: {
      currentDateFormat: scheduleAppearanceStore.locale === Language.DE ? 'DD.MM.YY' : 'MM/DD/YY',
      showCurrentTimeLine: true,
      showHeaderElements: true,
      headerRenderer({ timeRange }) {
        if ((timeRange.cls as DomClassList)?.contains?.('today-time-range')) {
          const isDayPreset = scheduleAppearanceStore.currentZoomPreset.includes('weekAndDay');
          const date = new Date(timeRange.startDate!);
          if (isDayPreset) {
            return `<div><div class="tw-ds-text-xs">${i18n.d(
              date,
              'weekDay',
            )} </div> <div class="tw-ds-text-sm--medium">${date.getDate()}</div></div>`;
          }
          return `<div><div class="tw-ds-text-xs">${i18n.d(
            date,
            'weekDay',
          )} </div> <div class="tw-ds-text-xs">${i18n.d(date, 'date')}</div></div>`;
        }

        if ((timeRange.cls as DomClassList)?.contains?.(schedulerClassConfig[NodeName.HOLIDAY])) {
          const date = new Date(timeRange.startDate!);
          return `<div><div class="tw-ds-text-xs">${i18n.d(
            date,
            'weekDay',
          )} </div> <div class="tw-ds-text-sm--medium">${date.getDate()}</div></div>`;
        }
        return `${timeRange.name}`;
      },
    },
    treeFeature: {
      disabled: false,
    },
  }));
}
