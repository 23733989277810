import { parseEntityWorkingDayIntoOperationInputWorkingDay } from '@/features/calendars/calendarUtils';
import {
  generateRTCMessageId,
  LocalProjectChangeEventTemplate,
} from '@/features/realTimeCollaboration';
import { OperationInputType } from '@/features/realTimeCollaboration/types';

import { OperationNames } from '../../../events.schema';

export const createProjectChangeCompleteSetupEvent = (
  input: OperationInputType<'CompleteProjectSetup'>,
): LocalProjectChangeEventTemplate => {
  if ('projectTemplateId' in input) {
    // Will be implemented as part of TEM5
    throw new Error('Not implemented.');
  }
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CompleteProjectSetup,
      input: {
        ...input,
        calendar: {
          ...input.calendar,
          workingDays: input.calendar.workingDays.map(
            parseEntityWorkingDayIntoOperationInputWorkingDay,
          ),
        },
      },
    },
  };
};

export const createProjectChangeUpdateStatusEvent = (
  input: OperationInputType<'UpdateProjectStatus'>,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateProjectStatus,
      input,
    },
  };
};

export const createProjectChangeDeleteEvent = (): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.DeleteProject,
      input: {},
    },
  };
};
