import { OrderDependencyEntity } from '@/common/types';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders';
import { useProjectTradeStore } from '@/features/projectTrades';
import { getManualPromise, toAsyncWatch } from '@/helpers/utils/functions';
import { findMapDifferences } from '@/helpers/utils/objects';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { updateMap, watchMap } from '@/services/store/schedule/actions/utils/mapState';
import { OrderDependencyParser } from '@/services/store/schedule/parsers';

export async function watchOrderDependencies(store: ScheduleStore) {
  const orderStore = useOrderStore();
  const tradeStore = useProjectTradeStore();
  const milestoneStore = useMilestoneStore();
  const orderDependencyStore = useOrderDependencyStore();
  const parser = new OrderDependencyParser();

  const orderStoreFetchPromise = toRef(orderStore, 'fetchAllPromise');
  const tradeStoreFetchPromise = toRef(tradeStore, 'fetchAllPromise');
  const milestoneStoreFetchPromise = toRef(milestoneStore, 'fetchAllPromise');
  const orderDependencyStoreFetchPromise = toRef(orderDependencyStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(
    next: Map<string, OrderDependencyEntity>,
    prev: Map<string, OrderDependencyEntity>,
  ): void {
    const differences = findMapDifferences(next, prev);
    updateMap(store.entities.dependencies, differences, (id) => {
      const scheduler = getScheduler();
      const dependency = next.get(id)!;
      const oldDependency = prev.get(id);

      // when adding new drying break, we need to remove the dependency and add a new one, because bryntum can't reassign from and to fields
      // of the same dependency id
      if (scheduler && dependency.useDryingBreak && !oldDependency?.useDryingBreak) {
        scheduler.dependencyStore.remove(id);
      }
      return parser.entityToModel(dependency);
    });
    resolveTransformation(true);
  }

  const unwatch = toAsyncWatch(
    // we also await the other stores to make sure dependencies are rendered after the connected entities
    [
      orderDependencyStoreFetchPromise,
      orderStoreFetchPromise,
      tradeStoreFetchPromise,
      milestoneStoreFetchPromise,
    ],
    () => {
      return watchMap(() => orderDependencyStore.dependencies, onUpdate);
    },
  );
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}
