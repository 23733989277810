/** Semantic events used for tracking */
export enum AnalyticsEventCategories {
  /** User related stuff */
  USER_SIGN_IN = 'User Sign In',
  USER_SIGN_UP = 'User Sign Up',
  USER_SIGN_OUT = 'User Sign Out',
  USER_SSO_PROVIDER_CONNECTED = 'User SSO Provider Connected',
  USER_PROFILE_EDITED = 'User Profile Edited',

  /** Order related stuff */
  ORDER_STATUS_REPORTED = 'Order Status Reported',
  ORDER_CREATED = 'Order Created',
  ORDER_OPENED = 'Order Opened',
  ORDERS_EDITED = 'Orders Edited',
  ORDERS_MOVED_OUTSIDE_WORKING_TIMES = 'Orders Moved Outside Working Times',
  ORDERS_DELETED = 'Orders Deleted',
  ORDERS_CUT = 'Orders Cut',
  ORDERS_COPIED = 'Orders Copied',
  ORDERS_PASTED = 'Orders Pasted',
  ORDER_PHOTOS_DOWNLOADED = 'Order Photos Downloaded',
  ORDER_PHOTOS_UPLOADED = 'Order Photos Uploaded',
  ORDER_DOCUMENT_VIEWED = 'Order Document Viewed',
  ORDER_DOCUMENTS_UPLOADED = 'Order Documents Uploaded',
  WORK_SCHEDULE_EXCEPTION_POPUP_CLOSED = 'Work Schedule Exception Popup Closed',

  /** Dependency */
  DEPENDENCY_CREATED = 'Dependency Created',
  DEPENDENCY_LAG_VIOLATED = 'Dependency Lag Violated',
  DEPENDENCY_VIOLATION_OVERRULED = 'Dependency Violation Overruled',
  DEPENDENCY_OPENED = 'Dependency Opened',
  DEPENDENCIES_EDITED = 'Dependencies Edited',
  DEPENDENCIES_DELETED = 'Dependencies Deleted',
  DEPENDENCIES_HIGHLIGHTED = 'Dependencies Highlighted',
  VIOLATED_DEPENDENCY_LAG_UPDATED = 'Violated Dependency Lag Updated',

  /** Drying Breaks */
  DRYING_BREAK_CREATED = 'Drying Time Created',
  DRYING_BREAK_EDITED = 'Drying Time Edited',
  DRYING_BREAK_DELETED = 'Drying Time Deleted',

  /** Schedule handling */
  SCHEDULE_FILTERED = 'Schedule Filtered',
  SCHEDULE_PERMANENTLY_FILTERED = 'Schedule Permanently Filtered',
  SCHEDULE_DEPENDENCY_VIEW_TOGGLED = 'Schedule Dependency View Toggled',
  SCHEDULE_ORDER_VIEW_TOGGLED = 'Schedule Order View Toggled',
  SCHEDULE_JUMP_TO_TODAY = 'Jump To Today Used',
  SCHEDULE_UNDO = 'Schedule Undo',
  SCHEDULE_REDO = 'Schedule Redo',
  SCHEDULE_COMPONENT_ROW_VISIBILITY_TOGGLED = 'Schedule Component Row Visibility Toggled',

  /** Trade sequences */
  TRADE_SEQUENCE_INSERTED = 'Trade Sequence Inserted',
  TRADE_SEQUENCE_DUPLICATED = 'Trade Sequence Duplicated',
  TRADE_SEQUENCE_CREATED = 'Trade Sequence Created',
  TRADE_SEQUENCE_DELETED = 'Trade Sequence Deleted',
  TRADE_SEQUENCE_EDITED = 'Trade Sequence Edited',
  TRADE_SEQUENCE_EXPORTED_AS_PDF = 'Trade Sequence Exported as PDF',
  TRADE_SEQUENCE_UPDATE_CONFLICTS_DETECTED = 'Trade Sequence Update Conflicts Detected',

  /** Tickets */
  TICKET_CREATED = 'Ticket Created',
  TICKET_DELETED = 'Ticket Deleted',
  TICKET_DETAILS_VIEWED = 'Ticket Details Viewed',
  TICKET_EDITED = 'Ticket Edited',
  TICKET_STATUS_REPORTED = 'Ticket Status Reported',
  TICKETS_EXPORTED = 'Tickets Exported',
  TICKETS_FILTERED = 'Tickets Filtered',
  TICKETS_SEARCHED = 'Tickets Searched',
  TICKETS_SORTED = 'Tickets Sorted',

  /** Schedule export */
  EXPORT_MENU_OPENED = 'Schedule Export Menu Opened',
  EXPORT_PDF = 'Schedule Exported As PDF',
  EXPORT_EXCEL = 'Schedule Exported As XLS',
  EXPORT_GANTT = 'Schedule exported as Schedule File',

  /** Tasks update */
  TASK_STATUS_REPORTED = 'Task Status Reported',

  /** Construction Break / Pause */
  CONSTRUCTION_BREAK_CREATED = 'Construction Break Created',
  CONSTRUCTION_BREAK_OPENED = 'Construction Break Opened',
  CONSTRUCTION_BREAKS_EDITED = 'Construction Breaks Edited',
  CONSTRUCTION_BREAKS_DELETED = 'Construction Breaks Deleted',

  /** Milestone operations */
  ACCEPTANCE_CRITERION_STATUS_REPORTED = 'Acceptance Criterion Status Reported',
  MILESTONE_CREATED = 'Milestone Created',
  MILESTONE_OPENED = 'Milestone Opened',
  MILESTONES_EDITED = 'Milestones Edited',
  MILESTONES_DELETED = 'Milestones Deleted',
  MILESTONE_OVERLAP_VISIBILITY_TOGGLED = 'Milestone Overlap Visibility Toggled',
  MILESTONE_STATUS_REPORTED = 'Milestone Status Reported',

  /** Version history */
  VERSION_HISTORY_OPENED = 'Schedule Version History Opened',
  VERSION_HISTORY_VIEWED = 'Schedule Version Viewed',
  VERSION_HISTORY_APPLIED = 'Schedule Version Applied',
  VERSION_HISTORY_HELP_CLICKED = 'Schedule Version History Help Clicked',
  VERSION_HISTORY_MANUALLY_SAVED = 'Schedule Version Manually Saved',

  /** Working time calendars */
  WORK_CALENDAR_CREATED = 'Work Schedule Created',
  WORK_CALENDAR_EDITED = 'Work Schedule Edited',

  /** Structure */
  SECTION_CREATED = 'Section Created',
  SECTION_DELETED = 'Section Deleted',
  SECTION_DUPLICATED = 'Section Duplicated',
  SECTION_EDITED = 'Section Edited',
  SECTION_ARRANGEMENT_EDITED = 'Section Arrangement Edited',
  SECTION_HIERARCHY_ADDED = 'Section Hierarchy Added',
  SECTION_HIERARCHY_REMOVED = 'Section Hierarchy Removed',
  SECTION_SUMMARY_HOVERD = 'Section Summary Hovered',

  /** Order history */
  ORDER_HISTORY_VIEWED = 'Order History Viewed',

  /** Changelog */
  CHANGELOG_OPENED = 'Changelog Opened',

  /** PWA */
  PWA_INSTALLED = 'Application Installed',

  /** Schedule Multi Entity Events */
  SCHEDULE_ENTITIES_SELECTED = 'Schedule Entities Selected',
  SCHEDULE_ENTITIES_DELETED = 'Schedule Entities Deleted',
  SCHEDULE_ENTITIES_EDITED = 'Schedule Entities Edited',

  /** Memberships and Project Memberships */
  PROJECT_MEMBER_ADDED = 'Project Member Added',
  PROJECT_MEMBER_DELETED = 'Project Member Deleted',
  PROJECT_MEMBER_EDITED = 'Project Member Edited',
  PROJECT_CONTRIBUTOR_GROUP_PERMISSION_CHANGED = 'Project Contributor Group Permission Changed',
  PROJECT_CONTRIBUTOR_GROUP_ADDED = 'Project Contributor Group Added',
  PROJECT_CONTRIBUTOR_GROUP_DELETED = 'Project Contributor Group Deleted',
  PROJECT_CONTRIBUTOR_GROUP_EDITED = 'Project Contributor Group Edited',
  PROJECT_CONTRIBUTOR_GROUP_MEMBERS_CHANGED = 'Project Contributor Group Members Changed',
  PROJECT_CONTRIBUTOR_GROUP_TABLE_VIEW_CHANGED = 'Project Contributor Group Table View Changed',
  PROJECT_CONTRIBUTOR_GROUP_TRADE_ASSIGNMENT_CHANGED = 'Project Contributor Group Trade Assignment Changed',
  TENANT_MEMBER_ADDED = 'Tenant Member Added',
  TENANT_MEMBER_DELETED = 'Tenant Member Deleted',
  USER_INVITATION_RESEND = 'Invitation Resend',

  /** Photo documentation
   *  https://www.notion.so/koppla/PHODO-Photo-documentation-46e25a0150ee437bb02b3fb0f7334461#e0de24d4c5444a1a881c02ac1fb13fd5
   */
  PHOTO_DOCUMENTATION_EXPORTED = 'Photo Documentation Exported',
  PHOTO_DOCUMENTATION_SINGLE_DOWNLOAD = 'Photo Documentation Single Download',
  PHOTO_DETAILS_OPENED = 'Photo Details Opened',
  PHOTO_DOCUMENTATION_FILTERED = 'Photo Documentation Filtered',

  /**
   * SOLL (Base plan)
   * https://www.notion.so/koppla/b9fac4bb6957485da7cbd424c2d7b1af?v=06458df79f894f4c8071211cbac2051a&pvs=4
   */
  BASE_PLAN_CHANGED = 'Baseplan Changed',
  BASE_PLAN_DETAILS_COMPARISON_HOVERED = 'Baseplan Details Comparison Hovered',

  /**
   * DASH (Dashboard)
   */
  DASHBOARD_PROGRESS_SELECTION_CHANGED = 'Dashboard Progress Selection Changed',
  DASHBOARD_PROJECT_DELAYS_TYPE_TOGGLED = 'Dashboard Project Delays Type Toggled',
  DASHBOARD_TRADE_DELAYS_TYPE_TOGGLED = 'Dashboard Trade Delays Type Toggled',

  /**
   * TRY (project alternatives)
   * https://www.notion.so/koppla/752c0c82d7224f1eab9a78bef26366ad?v=7c4b22c7670846f0af7c7b5034e1d176
   */
  PROJECT_ALTERNATIVE_CREATED = 'Schedule Alternative Created',
  PROJECT_ALTERNATIVE_ARCHIVED = 'Schedule Alternative Archived',
  PROJECT_ALTERNATIVE_ACTIVATED = 'Schedule Alternative Activated',
  PROJECT_ALTERNATIVE_SWITCHED = 'Schedule Alternative Switched',
  PROJECT_ALTERNATIVE_MODE_ENTERED = 'Schedule Alternative Mode Entered',
  PROJECT_ALTERNATIVE_MODE_LEFT = 'Schedule Alternative Mode Left',
  PROJECT_ALTERNATIVE_DESCPRIPTION_EDITED = 'Schedule Alternative Description Edited',

  /**
   * TEM3 (project structure templates)
   * https://www.notion.so/koppla/TEM3-Spec-Company-wide-project-structure-templates-d4582cf8f39c468da0a54069963b89fc?pvs=4#c35cb7494e4b4a12b99867d8e2291ed0
   */
  PROJECT_STRUCTURE_TEMPLATE_CREATED = 'Structure Template Created',
  PROJECT_STRUCTURE_TEMPLATE_DUPLICATED = 'Structure Template Duplicated',
  PROJECT_STRUCTURE_TEMPLATE_EDITED = 'Structure Template Edited',
  PROJECT_STRUCTURE_TEMPLATE_DELETED = 'Structure Template Deleted',
  PROJECT_STRUCTURE_TEMPLATE_SELECTED = 'Structure Template Selected',

  /**
   * Global Trades
   */
  TRADE_CREATED = 'Trade Created',
  TRADE_DELETED = 'Trade Deleted',
  TRADE_EDITED = 'Trade Edited',
  TRADES_GLOBALLY_IMPORTED = 'Trades Globally Imported',

  /**
   * Real Time Collaboration
   */
  APPLICATION_OPENED = 'Application Opened',
  CONFLICT_NOTIFICATION_SHOWN = 'Conflict Notification Shown',
  CONNECTION_STATE_CHANGED = 'Connection State Changed',
  TECHNICAL_ERROR_NOTIFICATION_SHOWN = 'Technical Error Notification Shown',
  TRIED_TO_LEAVE_WITH_UNACKNOWLEDGED_EVENTS = 'Tried To Leave With Unacknowledged Events',
  SCHEDULE_USER_AVATAR_HOVERED = 'Schedule User Avatar Hovered',
  PROJECTS_SWITCHED = 'Projects Switched',

  /**
   * Links
   */
  LINK_ADDED = 'Link Added',
  LINK_DELETED = 'Link Deleted',
  LINK_EDITED = 'Link Edited',
  LINK_VIEWED = 'Link Viewed',

  /**
   * Projects
   */
  PROJECT_CREATED = 'Project Created',
  PROJECT_DELETED = 'Project Deleted',
  PROJECT_DETAILS_EDITED = 'Project Details Edited',
  PROJECT_STATUS_EDITED = 'Project Status Edited',
  PROJECTS_SORTED = 'Projects Sorted',
  PROJECTS_FILTERED = 'Projects Filtered',

  /**
   * Collisions
   */
  COLLISION_ROW_SCROLL_BUTTON_CLICKED = 'Collision Row Scroll Button Clicked',
  COLLISION_CAUSED = 'Collision Caused',
  COLLISION_RESOLVED = 'Collision Resolved',
  COLLISION_VIEWED = 'Collision Viewed',

  /**
   * Schedule Assistant
   */
  SCHEDULE_ASSISTANT_OPENED = 'Schedule Assistant Opened',
}

/** Basic entity to track events */
export interface AnalyticsEntity<T = Dictionary> {
  identification: string;
  properties?: T;
}
export abstract class AnalyticsEvent {
  public abstract readonly identification: AnalyticsEventCategories;

  public properties: Dictionary | undefined = undefined;
}
