export const sectionSelectors = {
  toolbar: {
    addButton: (sectionId: string) => `section-toolbar-add-button-${sectionId}`,
    copyButton: (sectionId: string) => `section-toolbar-copy-button-${sectionId}`,
    indentButton: (sectionId: string) => `section-toolbar-indent-button-${sectionId}`,
    outdentButton: (sectionId: string) => `section-toolbar-outdent-button-${sectionId}`,
    deleteButton: (sectionId: string) => `section-toolbar-delete-button-${sectionId}`,
    infoButton: (sectionId: string) => `section-toolbar-info-button-${sectionId}`,
  },
  deleteConfirmationDialog: {
    dialog: 'section-delete-confirmation-dialog',
    confirmButton: 'section-delete-confirmation-dialog-delete-button',
  },
  sectionName: (sectionId: string) => `section-name-${sectionId}`,
};

export const SectionType = {
  TopLevelSection: 'top-level-section',
  MidLevelSection: 'mid-level-section',
  BottomLevelSection: 'bottom-level-section',
  MainResourceSection: 'main-resource-section',
  EmptyResourceSection: 'empty-resource-section',
};
