import { endOfDay } from 'date-fns';
import { isEqual } from 'lodash';

import { ScheduleStore } from '../../../store';
import { SchedulerEvent, SchedulerResource } from '../../../types';

export interface EventDragListener {
  eventDrag: ReturnType<typeof useEventDragListener>;
}

export function useEventDragListener(
  store: ScheduleStore,
): (data: {
  eventRecords: SchedulerEvent[];
  newResource: SchedulerResource;
  startDate: Date;
  endDate: Date;
}) => void {
  return (data) => {
    const newContextMarker = {
      startDate: new SchedulingDate(data.startDate),
      endDate: endOfDay(new SchedulingDate(data.startDate)),
      resourceId: data.newResource.id,
    };
    if (!isEqual(store.utils.scheduleContextMarker, newContextMarker)) {
      store.utils.scheduleContextMarker = newContextMarker;
    }
  };
}
