import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import {
  getCollisionResourceId,
  getEmptyResourceId,
  getMainResourceId,
} from '@/services/store/schedule/parsers/base';
import { SchedulerResource } from '@/services/store/schedule/types';

export function eventHasValidResource(event: { entity: NodeName }, resourceId: string): boolean {
  const scheduler = getScheduler();
  const resource = scheduler?.resourceStore.getById(resourceId) as SchedulerResource | undefined;
  if (!resource || resource.id === getEmptyResourceId()) return false;

  if (event.entity === NodeName.ORDER) {
    return (
      resourceId !== getMainResourceId() &&
      resource.id !== getCollisionResourceId() &&
      !!resource.isBottomLevelRow
    );
  }
  if (event.entity === NodeName.MILESTONE) {
    return (
      resourceId === getMainResourceId() || !!resource.isTopLevelRow || !!resource.isMidLevelRow
    );
  }
  if (event.entity === NodeName.PAUSE) {
    return resourceId === getMainResourceId();
  }

  // TODO: add check for collision event here later

  return false;
}

export function getNextValidResource(
  event: { entity: NodeName },
  resourceId: string,
): string | undefined {
  if (eventHasValidResource(event, resourceId)) return resourceId;
  const scheduler = getScheduler();
  const resourceIds = (scheduler?.resourceStore.allRecords as SchedulerResource[]).map(
    (resource) => resource.id,
  );
  const resourceIndex = resourceIds.findIndex((id) => resourceId === id);

  const possibleResourceIds = [
    ...resourceIds.filter((_, index) => index > resourceIndex),
    ...resourceIds.filter((_, index) => index < resourceIndex).reverse(),
  ];
  for (let i = 0; i < possibleResourceIds.length; i++) {
    if (eventHasValidResource(event, possibleResourceIds[i])) return possibleResourceIds[i];
  }
  return undefined;
}
