import { SchedulerResourceModel } from '@bryntum/schedulerpro';

import { WbsSectionEntity } from '@/common/types';
import { getFlatSortedSections, useWbsSectionStore } from '@/features/projectStructure';
import { scrollToDate, scrollToResource } from '@/features/schedule/bryntum/schedulerInteractions';
import { getManualPromise, toAsyncWatch } from '@/helpers/utils/functions';
import { clamp } from '@/helpers/utils/numbers';
import { findMapDifferences } from '@/helpers/utils/objects';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { updateMap, watchMap } from '@/services/store/schedule/actions/utils/mapState';
import { ResourceParser } from '@/services/store/schedule/parsers';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';

export async function watchSections(store: ScheduleStore) {
  const sectionStore = useWbsSectionStore();
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const resourceParser = new ResourceParser();

  const sectionStoreFetchPromise = toRef(sectionStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(
    next: Map<string, WbsSectionEntity>,
    prev: Map<string, WbsSectionEntity>,
  ): void {
    const differences = findMapDifferences(next, prev);
    const sortedSections = getFlatSortedSections(sectionStore.wbsSections);

    // NOTE: Additions need to be sorted cause we can not add children before their respective parents
    differences.added.sort((a, b) => {
      return (
        sortedSections.findIndex((section) => section.id === a) -
        sortedSections.findIndex((section) => section.id === b)
      );
    });

    updateMap(store.entities.resources, differences, (id) => {
      const section = next.get(id)!;
      return resourceParser.parseSection(
        sectionStore.wbsSections,
        section,
        scheduleAppearanceStore.collapsedRowIds,
      );
    });
    resolveTransformation(true);
  }

  const unwatch = toAsyncWatch([sectionStoreFetchPromise], () => {
    return watchMap(() => sectionStore.wbsSections, onUpdate);
  });
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export function scrollToPersistedResource(): void {
  const scheduler = getScheduler();
  if (!scheduler) return;

  scheduler.whenProjectReady(() => {
    const scheduleAppearanceStore = useScheduleAppearanceStore();
    const persistedResourceIdx = clamp(scheduleAppearanceStore.scrollRowIdx ?? 0, [
      0,
      scheduler.resourceStore.records.length - 1,
    ]);
    const persistedResource = scheduler.resourceStore.records[
      persistedResourceIdx
    ] as SchedulerResourceModel;
    const currentScrollDate = scheduler.viewportCenterDate;
    if (persistedResourceIdx > 0) {
      scrollToResource(scheduler, persistedResource.id.toString(), 'start');
      // NOTE: We scroll to the previous date again, cause the vertical scroll apparently messes up the horizontal scroll
      scrollToDate(scheduler, currentScrollDate);
    }
  });
}
