import {
  DomConfig,
  SchedulerAssignmentModel,
  SchedulerDependencyModel,
} from '@bryntum/schedulerpro';

import { useCollisionStore } from '@/features/collisions';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';

interface DependencyRendererData {
  dependencyRecord: SchedulerDependencyModel;
  domConfig: DomConfig;
  fromAssignmentRecord: SchedulerAssignmentModel;
  toAssignmentRecord: SchedulerAssignmentModel;
}

export const getDependencyRenderer = (): ((data: DependencyRendererData) => void) => {
  const collisionStore = useCollisionStore();
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  return ({ dependencyRecord, domConfig, fromAssignmentRecord, toAssignmentRecord }): void => {
    const fromId = DryingBreakEventParser.dryingBreakIdToOrderId(`${fromAssignmentRecord.eventId}`);
    if (domConfig.class) {
      domConfig.class['--violated'] = collisionStore.collisions.has(dependencyRecord.id.toString());
      domConfig.class['--highlighted'] =
        scheduleAppearanceStore.eventToHighlightRelationsFor === fromId ||
        scheduleAppearanceStore.eventToHighlightRelationsFor === toAssignmentRecord.eventId;
    }
  };
};
