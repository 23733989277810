export const useOpenSection = () => {
  const openToolbarSectionId = ref<string | null>(null);

  const setOpenToolbarSectionId = (id: string | null) => {
    openToolbarSectionId.value = id;
  };
  const isMouseDown = ref(false);

  document.addEventListener('mousedown', () => {
    isMouseDown.value = true;
  });

  document.addEventListener('mouseup', () => {
    isMouseDown.value = false;
  });

  return {
    getIsMouseDown: () => isMouseDown.value,
    getOpenToolbarSectionId: () => openToolbarSectionId.value,
    setOpenToolbarSectionId,
  };
};
