import { DomHelper, SchedulerPro } from '@bryntum/schedulerpro';

import { WbsSectionEntity } from '@/common/types/entities';
import { getSectionAncestors, useWbsSectionStore } from '@/features/projectStructure';
import { stackEventsInResourceIfMilestonesOverlapOnDate } from '@/features/schedule/bryntum/resources/layout';
import {
  expandResource,
  scrollToDate,
  scrollToResource,
} from '@/features/schedule/bryntum/schedulerInteractions';
import { asyncTimeout } from '@/helpers/utils/functions';
import { NodeName } from '@/repositories/utils/cache';
import { useLoggingService } from '@/services/logging/composable';
import { ScheduleStore } from '@/services/store/schedule';
import {
  getAdjustedPopupMouseEvent,
  getByEventId,
} from '@/services/store/schedule/config/listeners/events';
import { getMainResourceId } from '@/services/store/schedule/parsers/base';
import { SchedulerEvent, SchedulerResource } from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { SchedulerPopupComponent, useSchedulePopupStore } from '@/services/store/schedulePopup';
import { AnalyticsEvent } from '@/utils/analyticsEvents/categories';

import { CollisionViewedEvent } from '../collisionAnalytics';
import { useCollisionStore } from '../collisionStore';
import { getCollisionsByTarget, getCollisionsOfDate, isCollisionFiltered } from '../collisionUtils';
import { Collision } from '../types';

export async function onClickCollision(
  scheduler: SchedulerPro,
  scheduleStore: ScheduleStore,
  eventRecord: SchedulerEvent,
): Promise<void> {
  const collisionStore = useCollisionStore();
  const schedulePopupStore = useSchedulePopupStore();

  const collision = collisionStore.collisions.get(eventRecord.id);
  if (!collision) {
    return;
  }

  const collisionsOnSameDateForSameTarget = getCollisionsByTarget(
    getCollisionsOfDate(collisionStore.collisions, collision?.date),
  );
  if (collisionsOnSameDateForSameTarget.size > 1) {
    schedulePopupStore.closePopup();
    const event = getAdjustedPopupMouseEvent(new MouseEvent('click'), collision.id);
    setTimeout(() => {
      schedulePopupStore.openPopup({
        component: SchedulerPopupComponent.COLLISION_JUMP_LIST,
        payload: {
          mouseEvent: new MouseEvent('click', {
            ...event,
            // Reduce width of tooltip to center it. Ideally the popup should use useGloballyPositionedOverlay,
            // but it is not feasible to change it now, so we hardcode the width (- tooltip width + half of event width)
            clientX: event.clientX - 150 + 15,
            clientY: event.clientY,
          }),
          date: collision.date,
        },
      });
    });
    return;
  }

  if (isCollisionFiltered(collision.id, collisionStore)) return;

  scrollCollisionTargetIntoView(
    scheduler,
    scheduleStore,
    collision,
    new CollisionViewedEvent({
      type: collision.toType === NodeName.MILESTONE ? 'milestone' : 'order',
      source: 'row',
    }),
  );
}

export const scrollCollisionTargetIntoView = async (
  scheduler: SchedulerPro,
  scheduleStore: ScheduleStore,
  collision: Pick<Collision, 'toId' | 'wbsSectionId' | 'type' | 'date'>,
  trackingEvent: AnalyticsEvent,
  scrollToCollisionDate = false,
): Promise<void> => {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const wbsSectionStore = useWbsSectionStore();
  const schedulePopupStore = useSchedulePopupStore();
  const loggingService = useLoggingService();

  const resourceId = collision.wbsSectionId ?? getMainResourceId();

  schedulePopupStore.closePopup();

  if (resourceId !== getMainResourceId()) {
    const ancestors = getSectionAncestors(wbsSectionStore.wbsSections, resourceId);
    // Add timeout, as re-rendering the resources after expanding might take longer than the expand itself
    await asyncTimeout(
      () => expandAncestors(ancestors, scheduler, scheduleStore, scheduleAppearanceStore),
      50,
    );
  }

  const targetScrollDate = scrollToCollisionDate ? collision.date : undefined;
  await scrollIntoView(scheduler, resourceId, targetScrollDate);
  stackEventsInResourceIfMilestonesOverlapOnDate(
    scheduler.resourceStore.getById(resourceId) as SchedulerResource,
    collision.date,
  );
  openPopup(schedulePopupStore, collision);

  loggingService.trackEvent(trackingEvent);
};

const scrollIntoView = async (
  scheduler: SchedulerPro,
  resourceId: string,
  targetScrollDate: Date | undefined,
): Promise<void> => {
  const visibleDate = scheduler.visibleDateRange.startDate
    ? new Date(scheduler.visibleDateRange.startDate)
    : undefined;
  const resourceScrollPromise = scrollToResource(scheduler, resourceId, 'center');

  if (targetScrollDate) {
    await scrollToDate(scheduler, targetScrollDate, 'center');
  } else {
    // Hack to prevent bryntum from scrolling to a random date
    if (visibleDate) {
      await scrollToDate(scheduler, visibleDate, 'start');
    }
  }
  await resourceScrollPromise;
};

const openPopup = (
  schedulePopupStore: ReturnType<typeof useSchedulePopupStore>,
  collision: Pick<Collision, 'toId' | 'wbsSectionId' | 'type' | 'date'>,
): void => {
  setTimeout(() => {
    // If the target element is not visible, we don't open the popup
    const toElement = getByEventId(collision.toId);
    if (!toElement || !DomHelper.isVisible(toElement)) return;

    schedulePopupStore.openPopup({
      component: SchedulerPopupComponent.COLLISION_DETAILS,
      payload: {
        mouseEvent: getAdjustedPopupMouseEvent(
          new MouseEvent('click'),
          toElement,
          collision.type === 'start' ? 'left' : 'right',
        ),
        toId: collision.toId,
        date: collision.date,
      },
      patient: true,
    });
  });
};

const expandAncestors = async (
  ancestors: WbsSectionEntity[],
  scheduler: SchedulerPro,
  scheduleStore: ScheduleStore,
  scheduleAppearanceStore: ReturnType<typeof useScheduleAppearanceStore>,
) => {
  for (const ancestor of ancestors) {
    const resourceModel = scheduler.resourceStore.getById(ancestor.id);
    if (!resourceModel) {
      continue;
    }

    if (scheduleAppearanceStore.collapsedRowIds.has(ancestor.id)) {
      await expandResource(scheduler, ancestor.id);
      scheduleAppearanceStore.expandStateForRows([ancestor.id]);
      resourceModel.set({ expanded: true });
      scheduleStore.entities.resources.set(resourceModel.id.toString(), {
        ...scheduleStore.entities.resources.get(resourceModel.id.toString())!,
        expanded: true,
      });
    }
  }
};
