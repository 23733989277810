import { DependencyType } from '@koppla-tech/scheduling-engine';
import { Ref } from 'vue';
import { Composer } from 'vue-i18n';

import { OrderDependencyEntity } from '@/common/types';
import { useCalendarStore } from '@/features/calendars';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { useProjectStore } from '@/features/projects/projectStore';
import { MINUTES_PER_DAY } from '@/helpers/utils/timeConstants';

export enum DependencyRelationship {
  PREDECESSOR = 'PREDECESSOR',
  SUCCESSOR = 'SUCCESSOR',
}

type DependencyStringType =
  | 'START_TO_START'
  | 'START_TO_FINISH'
  | 'FINISH_TO_START'
  | 'FINISH_TO_FINISH';

export type DependencyDetail = {
  id: string;
  useDryingBreak: boolean;
  lagInMinutes: number;
  bufferInMinutes: number;
  type: DependencyStringType;
  from: {
    orderId?: (string | null) | undefined;
    milestoneId?: (string | null) | undefined;
  };
  to: {
    orderId?: (string | null) | undefined;
    milestoneId?: (string | null) | undefined;
  };
};

export const dependencyTypeList: {
  id: DependencyType;
  type: DependencyStringType;
  i18nKey: string;
}[] = [
  {
    id: DependencyType.SS,
    type: 'START_TO_START',
    i18nKey: 'objects.orderDependency.typeNames.startToStart',
  },
  {
    id: DependencyType.SF,
    type: 'START_TO_FINISH',
    i18nKey: 'objects.orderDependency.typeNames.startToEnd',
  },
  {
    id: DependencyType.FS,
    type: 'FINISH_TO_START',
    i18nKey: 'objects.orderDependency.typeNames.endToStart',
  },
  {
    id: DependencyType.FF,
    type: 'FINISH_TO_FINISH',
    i18nKey: 'objects.orderDependency.typeNames.endToEnd',
  },
];

export function getDependencyIdOfTypeName(type: string): number {
  return dependencyTypeList.findIndex((entry) => entry.type === type) ?? -1;
}

export function getDependencyTypeOfId(id: number): DependencyType | undefined {
  return dependencyTypeList[id].id;
}

/**
 * Return start of dates, depending on type of dependency
 * @param typeId
 * @param start
 * @param end
 */
export function getStartDependingOnDependency<Type = Date>(
  type: DependencyType,
  start: Type,
  end: Type,
): Type {
  // dependency types are like: 'START_TO_START', where the part before the _TO_ belongs to the predecessor,
  // and the second part to the successor
  const stringResult = type?.split('_TO_');
  if (!stringResult) {
    throw new Error('Dependency type not found');
  }
  const realStart = stringResult[0];
  return realStart.includes('START') ? start : end;
}

/**
 * Return end of dates, depending on type of dependency
 * @param typeId
 * @param start
 * @param end
 */
export function getEndDependingOnDependency<Type = Date>(
  type: DependencyType,
  start: Type,
  end: Type,
): Type {
  // dependency types are like: 'START_TO_START', where the part before the _TO_ belongs to the predecessor,
  // and the second part to the successor
  const stringResult = type?.split('_TO_');
  if (!stringResult) {
    throw new Error('Dependency type not found');
  }
  const realEnd = stringResult[1];
  return realEnd.includes('START') ? start : end;
}

export function useDependencyCalendarInputHelper(
  i18n: Composer,
  dependency: Ref<OrderDependencyEntity | null>,
) {
  const projectStore = useProjectStore();
  const calendarStore = useCalendarStore();

  function getItem(
    id: string,
    respectDryingBreak = false,
  ): { name: string; calendar?: string } | null {
    const orderStore = useOrderStore();
    const order = orderStore.orders.get(id);
    if (order) {
      const { dryingBreak } = order;
      return {
        name:
          respectDryingBreak && dryingBreak && dependency.value?.useDryingBreak
            ? `${
                dryingBreak.name ?? i18n.t('objects.dryingBreak.defaultName')
              }${` (${order.name})`}`
            : order.name,
        calendar: order.calendar?.id,
      };
    }
    const milestoneStore = useMilestoneStore();
    const milestone = milestoneStore.milestones.get(id);
    if (milestone) {
      return {
        name: milestone.name,
      };
    }
    return null;
  }

  const minutesPerDayOfCalendar = computed(() => {
    const from = getItem(dependency.value?.from?.id ?? '');
    const to = getItem(dependency.value?.to?.id ?? '');
    const fromCalendar = calendarStore.calendars.get(from?.calendar ?? '');
    const toCalendar = calendarStore.calendars.get(to?.calendar ?? '');
    return (toCalendar ?? fromCalendar)?.minutesPerDay ?? MINUTES_PER_DAY;
  });

  const hourlyPlanningEnabled = computed(() => {
    return projectStore.currentProject?.hourlyPlanningEnabled ?? false;
  });

  return { minutesPerDayOfCalendar, hourlyPlanningEnabled, getItem };
}

export function getDependencyTypeFromEventConnection(connection: {
  fromEvent: 'start' | 'end';
  toEvent: 'start' | 'end';
}): DependencyType {
  if (connection.fromEvent === 'start') {
    if (connection.toEvent === 'start') {
      return DependencyType.SS;
    }
    return DependencyType.SF;
  }
  if (connection.toEvent === 'start') {
    return DependencyType.FS;
  }
  return DependencyType.FF;
}
