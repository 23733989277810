import { differenceInMinutes, min } from 'date-fns';

import { useLocalOrderSchedulingEngine } from '@/features/schedule';
import { addDelta } from '@/helpers/utils/dates';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { SchedulerEvent, SchedulerResource } from '@/services/store/schedule/types';

import { getMainResourceId } from '../../parsers/base';

export function calculateResourceAndKeepPriorDistance(
  events: SchedulerEvent[],
  event: SchedulerEvent,
  newResourceId: string,
): SchedulerResource {
  const scheduler = getScheduler()!;
  // NOTE: We only use the visible records here, as we only want to move the event to a visible resource
  const resources = (scheduler.resourceStore.records as SchedulerResource[]).filter(
    (resource) => resource.isBottomLevelRow || resource.id === getMainResourceId(),
  );
  // highest resource is resource with lowest idx
  const highestResourceIdx = events.reduce(
    (highest, currentEvent) =>
      Math.min(
        resources.findIndex((resource) => resource.id === currentEvent.resourceId),
        highest,
      ),
    Number.POSITIVE_INFINITY,
  );
  const oldResourceIdx = resources.findIndex((resource) => resource.id === event.resourceId);
  const newInitialResourceIdx = resources.findIndex((resource) => resource.id === newResourceId);
  const priorDistance = newInitialResourceIdx - highestResourceIdx;

  return resources[
    Math.min(
      Math.max(oldResourceIdx + priorDistance, 1), // 1 is lowest possible, as main slot is not allowed for orders
      resources.length - 1,
    )
  ];
}

export function calculateStartAndKeepPriorDistance(
  events: SchedulerEvent[],
  mostLeftEvent: SchedulerEvent,
  newStart: SchedulingDate,
): Date {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
  const earliestDate = min(events.map((e) => e.startDate));

  return localOrderSchedulingEngine.utils.getValidStartDate(
    addDelta(mostLeftEvent.startDate, {
      endDate: newStart,
      startDate: earliestDate,
    }),
    mostLeftEvent.calendarId ?? null,
  );
}

export function calculateStartRelativeToMostLeftEvent(
  mostLeftEvent: SchedulerEvent,
  event: SchedulerEvent,
  newStartOfMostLeftEvent: Date,
): Date {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();

  const priorDistanceToMostLeftEvent = localOrderSchedulingEngine.utils.computeWorkingTimeBetween(
    event.startDate,
    mostLeftEvent.startDate,
    event.calendarId ?? null,
  );

  return localOrderSchedulingEngine.utils.getValidStartDate(
    newStartOfMostLeftEvent,
    event.calendarId ?? null,
    priorDistanceToMostLeftEvent,
  );
}

export function calculateEndAndKeepPriorDuration(
  event: SchedulerEvent,
  newStart: SchedulingDate,
): Date {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();

  const eventStartDate = new SchedulingDate(event.startDate);
  const eventEndDate = new SchedulingDate(event.endDate);

  let duration: number;

  // if either start or end are non-working, we take the plain difference as duration,
  // as some changes to the calendar / pauses were made after the order was finished
  if (
    !localOrderSchedulingEngine.utils.isStartDateDuringWorkingTime(
      event.startDate,
      event.calendarId || null,
    ) ||
    !localOrderSchedulingEngine.utils.isEndDateDuringWorkingTime(
      event.endDate,
      event.calendarId ?? null,
    )
  ) {
    duration = differenceInMinutes(eventEndDate, eventStartDate);
  }
  // if both dates are inside the working time interval, we use the original
  // working time difference
  else {
    duration = localOrderSchedulingEngine.utils.computeWorkingTimeBetween(
      eventEndDate,
      eventStartDate,
      event.calendarId || null,
    );
  }

  return localOrderSchedulingEngine.utils.getValidEndDate(
    new SchedulingDate(newStart),
    event.calendarId || null,
    duration,
  );
}
