import { ScheduleStore } from '../../../store';

export interface EventDragResetListener {
  eventDragReset: ReturnType<typeof useEventDragResetListener>;
}

export function useEventDragResetListener(store: ScheduleStore): () => void {
  return () => {
    store.utils.scheduleContextMarker = null;
  };
}
