import { useMilestoneStore } from '@/features/milestones';
import { MilestoneOverlapVisibilityToggled } from '@/features/milestones/analytics';
import { EVENT_LAYOUT_NONE, EVENT_LAYOUT_STACK } from '@/features/schedule/bryntum/resources/const';
import { LoggingService } from '@/interfaces/services';
import { SchedulerResource } from '@/services/store/schedule/types';

export const checkAreEventsInResourceStacked = (resource: SchedulerResource) => {
  return resource.eventLayout === EVENT_LAYOUT_STACK;
};

export const stackEventsInResource = (
  resource: SchedulerResource,
  loggingService: LoggingService | null = null,
) => {
  resource.eventLayout = EVENT_LAYOUT_STACK;

  loggingService?.trackEvent(
    new MilestoneOverlapVisibilityToggled({
      isExpanded: true,
    }),
  );
};

export const overlapEventsInResource = (
  resource: SchedulerResource,
  loggingService: LoggingService | null = null,
) => {
  resource.eventLayout = EVENT_LAYOUT_NONE;

  loggingService?.trackEvent(
    new MilestoneOverlapVisibilityToggled({
      isExpanded: false,
    }),
  );
};

export const stackEventsInResourceIfMilestonesOverlapOnDate = (
  resource: SchedulerResource,
  date: SchedulingDate,
  loggingService: LoggingService | null = null,
) => {
  const hasOverlappingMilestones = useMilestoneStore().checkDateHasOverlappingMilestones(
    date,
    resource,
  );

  if (hasOverlappingMilestones) {
    stackEventsInResource(resource, loggingService);
  }
};
