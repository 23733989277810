import { toBase64 } from 'js-base64';
import { defineStore } from 'pinia';

import { getRandomId } from '@/helpers/utils/strings';
import {
  HolidaysQueryVariables,
  HolidayStoreActions,
  HolidayStoreGetters,
  HolidayStoreState,
} from '@/services/store/holiday/types';
import { AvailableHolidayState, AvailableHolidayYear, holidays, states } from '@/utils/holidayList';

export const useHolidayStore = defineStore<
  string,
  HolidayStoreState,
  HolidayStoreGetters,
  HolidayStoreActions
>('holiday-store', {
  state: () => {
    return { holidays: new Map() };
  },
  actions: {
    fetchAll(vars: HolidaysQueryVariables) {
      const { country, state } = vars;
      const countries = Object.keys(holidays);

      // return, if given country is not registered
      if (!countries.includes(country)) {
        this.holidays = new Map();
        return [];
      }

      const holidayStore = holidays;

      const years = Object.keys(holidayStore[country]) as unknown as (keyof AvailableHolidayYear)[];

      /**
       * Google places api returns the long name for the state.
       * Therefore we need to compute the iso version of it to access holidays
       */
      let computedState = state;
      if (!states[country][state]) {
        const stateOfLongName = Object.entries((states as Dictionary)[country]).find(
          ([, longStateName]) => longStateName === state,
        );
        computedState = (
          stateOfLongName?.length ? stateOfLongName[0] : ''
        ) as keyof AvailableHolidayState;
      }

      const result = years.flatMap((year) =>
        (holidayStore[country][year][computedState] ?? []).map(
          (holiday: { name: string; date: string }) => {
            // Dates are stored in german format, and need to be parsed to iso format
            const isoDate = holiday.date.split('.').reverse().join('-');

            return {
              id: toBase64(`HolidayNode:${getRandomId()}`),
              date: isoDate,
              name: holiday.name,
              state: computedState,
              country,
            };
          },
        ),
      );

      this.holidays = new Map(result.map((holiday) => [holiday.id, holiday]));

      return result;
    },
  },
});
