import { DryingBreakDeletedEvent, DryingBreakEditedEvent } from '@/features/dryingBreaks';
import {
  getMilestoneSectionAnalyticsType,
  MilestoneOpenedEvent,
  MilestonesDeletedEvent,
  MilestonesEditedEvent,
} from '@/features/milestones';
import { mapMilestoneToAnalyticsType } from '@/features/milestones/analytics';
import { MilestoneType } from '@/features/milestones/types';
import { DependenciesDeletedEvent } from '@/features/orderDependencies';
import { useWbsSectionStore } from '@/features/projectStructure';
import { omitKeys } from '@/helpers/utils/objects';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getMainResourceId } from '@/services/store/schedule/parsers/base';
import { SchedulerEvent } from '@/services/store/schedule/types';

import { AnalyticsEvent } from './categories';
import { MultiEventCounts } from './eventCategories/schedule';

type Groups = Partial<Record<NodeName, number>>;

export function trackUpdate(
  groups: Groups,
  loggingService: LoggingService,
  method: 'drag_and_drop' | 'resize',
): void {
  if (Object.keys(groups).length === 0) return;
  if (Object.keys(groups).length === 1) {
    trackSingleEntityAction(groups, loggingService, {
      buildOrderEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.OrdersEditedEvent({
          method,
          count,
          tasksWereUpdated: false,
        }),
      buildMilestoneEvent: (count: number) =>
        new MilestonesEditedEvent({
          type: mapMilestoneToAnalyticsType(MilestoneType.FLEXIBLE),
          source: 'multiple',
          method: 'drag_and_drop',
          count,
          wasTypeChanged: false,
        }),
      buildPauseEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.ConstructionBreaksEditedEvent({ method, count }),
      buildDryingBreakEvent: () => new DryingBreakEditedEvent({ method: 'resize' }),
    });
  } else {
    trackMultiEntityAction(
      groups,
      loggingService,
      (counts: MultiEventCounts) =>
        new loggingService.AnalyticEventCategories.ScheduleEntitiesEdited({
          method: 'drag_and_drop',
          ...counts,
        }),
    );
  }
}

export function trackDelete(
  groups: Groups,
  loggingService: LoggingService,
  method: 'keyboard' | 'toolbar',
) {
  // drying breaks are always deleted together with orders and should not be considered own entity for multi tracking
  const filteredGroups = omitKeys(groups, [NodeName.DRYING_BREAK]);
  if (Object.keys(filteredGroups).length === 0) return;
  if (Object.keys(filteredGroups).length === 1) {
    trackSingleEntityAction(groups, loggingService, {
      buildOrderEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.OrdersDeletedEvent({ method, count }),
      buildMilestoneEvent: (count: number) =>
        new MilestonesDeletedEvent({
          type: 'multiple',
          source: 'multiple',
          method,
          count,
        }),
      buildDependencyEvent: (count: number) =>
        new DependenciesDeletedEvent({
          method,
          count,
        }),
      buildPauseEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.ConstructionBreaksDeletedEvent({
          method,
          count,
        }),
      buildDryingBreakEvent: () => new DryingBreakDeletedEvent({ method: 'keyboard' }),
    });
  } else {
    trackMultiEntityAction(
      groups,
      loggingService,
      (counts: MultiEventCounts) =>
        new loggingService.AnalyticEventCategories.ScheduleEntitiesDeleted({ method, ...counts }),
    );
  }
}

export function trackCut(
  groups: Groups,
  loggingService: LoggingService,
  method: 'keyboard' | 'toolbar',
) {
  if (Object.keys(groups).length === 0) return;
  if (Object.keys(groups).length === 1) {
    trackSingleEntityAction(groups, loggingService, {
      buildOrderEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.OrdersCutEvent({ method, count }),
    });
  }
  // multi entity cut is not possible
}

export function trackCopy(
  groups: Groups,
  loggingService: LoggingService,
  method: 'keyboard' | 'toolbar',
) {
  if (Object.keys(groups).length === 0) return;
  if (Object.keys(groups).length === 1) {
    trackSingleEntityAction(groups, loggingService, {
      buildOrderEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.OrdersCopiedEvent({ method, count }),
    });
  }
  // multi entity copy is not possible
}

export function trackPaste(
  groups: Groups,
  loggingService: LoggingService,
  method: 'keyboard' | 'right_click',
) {
  if (Object.keys(groups).length === 0) return;
  if (Object.keys(groups).length === 1) {
    trackSingleEntityAction(groups, loggingService, {
      buildOrderEvent: (count: number) =>
        new loggingService.AnalyticEventCategories.OrdersPastedEvent({ method, count }),
    });
  }
  // multi entity paste is not possible
}

export function trackSchedulerEventOpen(
  loggingService: LoggingService,
  entity: NodeName,
  record?: SchedulerEvent,
) {
  switch (entity) {
    case NodeName.ORDER:
      loggingService.trackEvent(
        new loggingService.AnalyticEventCategories.OrderOpenedEvent({ source: 'schedule' }),
      );
      break;
    case NodeName.PAUSE:
      loggingService.trackEvent(
        new loggingService.AnalyticEventCategories.ConstructionBreakOpenedEvent(),
      );
      break;
    case NodeName.MILESTONE:
      loggingService.trackEvent(
        new MilestoneOpenedEvent({
          type: mapMilestoneToAnalyticsType(
            record?.isFixed ? MilestoneType.FIXED : MilestoneType.FLEXIBLE,
          ),
          source:
            record?.resource?.id === getMainResourceId()
              ? 'events'
              : getMilestoneSectionAnalyticsType(
                  useWbsSectionStore().wbsSections,
                  (record?.resource?.id ?? '') as string,
                ),
          method: 'schedule',
        }),
      );
      break;
    default:
  }
}

interface EventBuilders {
  buildOrderEvent: (count: number) => AnalyticsEvent;
  buildMilestoneEvent?: (count: number) => AnalyticsEvent;
  buildDependencyEvent?: (count: number) => AnalyticsEvent;
  buildPauseEvent?: (count: number) => AnalyticsEvent;
  buildDryingBreakEvent?: (count: number) => AnalyticsEvent;
}

function trackSingleEntityAction(
  groups: Groups,
  loggingService: LoggingService,
  eventBuilders: EventBuilders,
) {
  const {
    buildOrderEvent,
    buildMilestoneEvent,
    buildDependencyEvent,
    buildPauseEvent,
    buildDryingBreakEvent,
  } = eventBuilders;
  Object.entries(groups).forEach(([entity, count]) => {
    if (entity === NodeName.ORDER) {
      loggingService.trackEvent(buildOrderEvent(count));
    } else if (buildMilestoneEvent && entity === NodeName.MILESTONE) {
      loggingService.trackEvent(buildMilestoneEvent(count));
    } else if (buildDependencyEvent && entity === NodeName.ORDER_DEPENDENCY) {
      loggingService.trackEvent(buildDependencyEvent(count));
    } else if (buildPauseEvent && entity === NodeName.PAUSE) {
      loggingService.trackEvent(buildPauseEvent(count));
    } else if (buildDryingBreakEvent && entity === NodeName.DRYING_BREAK) {
      loggingService.trackEvent(buildDryingBreakEvent(count));
    }
  });
}

function trackMultiEntityAction(
  groups: Groups,
  loggingService: LoggingService,
  buildEvent: (counts: MultiEventCounts) => AnalyticsEvent,
) {
  const counts: MultiEventCounts = {
    orderCount: groups[NodeName.ORDER] ?? 0,
    milestoneCount: groups[NodeName.MILESTONE] ?? 0,
    constructionBreakCount: groups[NodeName.PAUSE] ?? 0,
  };

  loggingService.trackEvent(buildEvent(counts));
}
