<template>
  <div id="app" class="application">
    <div class="application-wrapper">
      <BrowserUnsupported v-if="!isBrowserSupported" />
      <RouterView v-else />
      <UpdateApp />
      <InfoSnackbar />
      <TheNotification />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/common/globalStore';
import { useAppLevelStores, useProjectLevelStores } from '@/common/storeInitializers';
import BrowserUnsupported from '@/components/utils/BrowserUnsupported.vue';
import InfoSnackbar from '@/components/utils/InfoSnackbar.vue';
import UpdateApp from '@/components/utils/UpdateApp.vue';
import { TheNotification } from '@/features/notifications';
import { useProjectStore } from '@/features/projects/projectStore';
import { useBrowserSupport } from '@/helpers/utils/os';

import {
  ApplicationOpenedEvent,
  ProjectsSwitchedEvent,
  TriedToLeaveWithUnacknowledgedEventsEvent,
  useRTCClient,
  useRTCController,
} from './features/realTimeCollaboration';
import { AdditionalAnalyticsProperties } from './interfaces/services/logging';
import { useLoggingService } from './services/logging/composable';
import { DEFAULT_PAGE_TITLE } from './utils/config';

const loggingService = useLoggingService();
const { isBrowserSupported } = useBrowserSupport();
const globalStore = useGlobalStore();
const { whenLoggedIn, whenEnteringProject, whenExitingProject } = globalStore;
const appLevelStores = useAppLevelStores();
const projectLevelStores = useProjectLevelStores();
const projectStore = useProjectStore();
const rtcController = useRTCController();
const rtcClient = useRTCClient();

let hasLeftAProjectBefore = false;
loggingService.trackEvent(new ApplicationOpenedEvent());

watchEffect(() => {
  document.title = projectStore.currentProject
    ? `${projectStore.currentProject.name} | ${DEFAULT_PAGE_TITLE}`
    : DEFAULT_PAGE_TITLE;
});

const onLogin = () => {
  // Check for hasTenantId to make sure that we initialize for external users
  if (!globalStore.hasTenantId) return;
  globalStore.appInitializationPromise = appLevelStores.initialize(globalStore.currentTenantId);
};

const onEnterProject = () => {
  if (!globalStore.currentProjectId || !globalStore.hasTenantId) return;
  const promise = projectLevelStores.initialize(
    globalStore.currentProjectId,
    false,
    loggingService,
  );
  globalStore.projectInitializationPromise = promise;
  const currentProjectId = globalStore.currentProjectId;

  projectStore.fetchProject({ id: currentProjectId }).then((project) => {
    if (!project || project.id !== globalStore.currentProjectId) {
      return;
    }
    rtcController.initialize({
      tenantId: project!.tenant.id,
      projectId: currentProjectId,
    });
  });

  window.addEventListener('beforeunload', onUnloadPage);

  if (hasLeftAProjectBefore) {
    loggingService.trackEvent(new ProjectsSwitchedEvent());
  }
};

const onExitProject = () => {
  rtcController.destroy();
  hasLeftAProjectBefore = true;
  window.removeEventListener('beforeunload', onUnloadPage);
  loggingService.setAdditionalProperty(AdditionalAnalyticsProperties.ProjectMembership, undefined);
};

/** Show default browser confirm dialog on page reload or page close in case there are non-acknowledged changes */
const onUnloadPage = (event: BeforeUnloadEvent) => {
  if (rtcClient.hasUnacknowledgedEvents) {
    loggingService.trackEvent(new TriedToLeaveWithUnacknowledgedEventsEvent());
    event.preventDefault();
  }
};

whenLoggedIn(onLogin);
whenEnteringProject(onEnterProject);
whenExitingProject(onExitProject);
</script>

<style lang="css">
a {
  color: inherit;
  text-decoration: inherit;
}
/* Hide arrows for number fields */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.application {
  @apply tw-flex tw-bg-white tw-z-[1000];
}

.application-wrapper {
  @apply tw-flex tw-flex-col tw-flex-1 tw-basis-auto tw-max-w-full tw-min-h-screen tw-relative;
}
</style>
