import { defineStore } from 'pinia';

export const useStatusReportModalStore = defineStore('status-report-modal-store', {
  state: () => ({
    isOpen: false,
  }),

  actions: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
});
