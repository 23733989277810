import { DomClassList } from '@bryntum/schedulerpro';
import { subDays, subHours } from 'date-fns';

import { Collision } from '@/features/collisions';
import { getCollisionsOfDate } from '@/features/collisions/collisionUtils';
import { NodeName } from '@/repositories/utils/cache';
import {
  BaseScheduleEventParser,
  getCollisionResourceId,
  schedulerClassConfig,
} from '@/services/store/schedule/parsers/base';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import { SchedulerEvent } from '@/services/store/schedule/types';

export const enum CollisionEventShape {
  CIRCLE = 'circle',
  DIAMOND = 'diamond',
}

export class CollisionEventParser
  extends BaseScheduleEventParser
  implements ScheduleModelParser<Collision, [Map<string, Collision>]>
{
  public entityToModel(
    collision: Collision,
    collisions: Map<string, Collision>,
    isHourly: boolean = false,
  ): SchedulerEvent {
    const collisionsOnThisDate = getCollisionsOfDate(collisions, collision.date);
    const hasMilestone = collisionsOnThisDate.some((c) => c.toType === NodeName.MILESTONE);
    let date = collision.date;
    if (collision.type === 'end') {
      if (isHourly) {
        date = subHours(collision.date, 1);
      } else {
        date = subDays(collision.date, 1);
      }
    }

    return {
      ...this.parseSharedInput(
        {
          ...collision,
          date,
        },
        'date',
        'date',
        NodeName.COLLISION,
      ),
      name: collisionsOnThisDate.length > 1 ? `${collisionsOnThisDate.length}` : '',
      cls: new DomClassList([
        schedulerClassConfig[NodeName.COLLISION],
        'hide-dependency-terminals',
        collisionsOnThisDate.length > 1 ? '--multiple' : '',
      ]),
      resourceId: getCollisionResourceId(),
      milestoneWidth: 31,
      manuallyScheduled: true,
      calendarId: null,
      isSelectable: false,
      readOnly: true,
      resizable: false,
      shape: hasMilestone ? CollisionEventShape.DIAMOND : CollisionEventShape.CIRCLE,
    };
  }
}
