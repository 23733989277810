import { computed, PropType, Ref, ref, SetupContext, unref, watch } from 'vue';

import { isEqual } from '@/helpers/utils/objects';
import { DataType, getPropDefault, getPropType } from '@/helpers/utils/props';

export interface UseComputedLabelAndId {
  computedLabel: Ref<string>;
  computedId: Ref<string>;
}

/** Use generated label and id to be added to the input component */
export function useComputedLabelAndId(
  { t },
  props: {
    rules: string | Record<string, string>;
    label: string;
    name: string;
    hideOptionalText?: boolean;
  },
): UseComputedLabelAndId {
  const isOptional = computed(
    () =>
      (typeof props.rules === 'string' && !props.rules.includes('required')) ||
      (typeof props.rules !== 'string' && !props.rules.required),
  );
  const optionalText = computed(() => `(${t('form.optionalField')})`);
  const computedLabel = computed(() =>
    props.label
      ? `${props.label} ${isOptional.value && !props.hideOptionalText ? optionalText.value : ''}`
      : '',
  );

  const computedId = computed(() => `${props.name}-input`);

  return {
    computedLabel,
    computedId,
  };
}

export interface UseNumericInput {
  onKeyDown: (event: KeyboardEvent) => void;
}

/**
 * Filters numeric input and enables max digits on inputs
 * @param inputValue
 * @param isNumeric
 * @param maxDigits
 */
export function useNumericInput<T>(
  inputValue: Ref<T>,
  isNumeric: Ref<boolean> | boolean,
  maxDigits: {
    value: Ref<number | null> | number;
    leading?: boolean;
  },
  allowNegatives: Ref<boolean> = ref(false),
): UseNumericInput {
  const maxDigitsReached = (input: T): boolean => {
    if (unref(maxDigits.value) == null) return false;
    const cleanInput = (typeof input === 'string' ? input : `${input}`).replace(',', '.');
    const parts = cleanInput.split('.');

    if (maxDigits.leading) {
      return parts[0].length >= unref(maxDigits.value)!;
    }
    if (parts.length < 2) return false;
    return parts[1].length >= unref(maxDigits.value)!;
  };

  function onKeyDown(event: KeyboardEvent): void {
    // some keys are still allowed, when using a numeric input. That's why we need to filter them out manually
    // However if negatives inputs are allowed allow minus
    const unallowedKeyCodes = [69, 187, ...(allowNegatives.value ? [] : [189])]; // e, +, -

    if (
      // if input is parsed to number, skip unallowed keys
      unref(isNumeric) &&
      (unallowedKeyCodes.includes(event.keyCode) ||
        // if pressed key is number, check if max number of digits already reached
        (/\\d/.test(event.key) && inputValue.value != null && maxDigitsReached(inputValue.value)))
    ) {
      event.preventDefault();
    }
  }

  return {
    onKeyDown,
  };
}

/** Returns prop types of `UseAsUpdateChildInput` component */
export function getUpdateChildInputProps<T>(
  type: DataType,
  defaultValue: T,
): { modelValue: { type: PropType<T>; default: T | (() => T) } } {
  /** The current value of the input */
  return {
    modelValue: {
      type: getPropType(type) as PropType<T>,
      default: getPropDefault(type, defaultValue),
    },
  };
}

/** Use current component as child component with a single input. Works in combination with v-model
 * @param defaultValue value to be used as default value for the child input
 * @param conversionFunction function that should be called before emitting the update event to convert the input value
 * @param unwantedValue value that should not update the current input value
 * @param oneWay indicator, if the component should just call the conversion function when emitting
 * @param skipEmit indicator, if the emit of the updated value should be skipped
 */
export function useAsUpdatingChildInput<
  TValue,
  TContext extends {
    emit: (message: 'update:modelValue', ...payload: any[]) => void;
  },
>(
  context: TContext,
  parentValue: Ref<TValue | null>,
  defaultValue: TValue,
  {
    conversionFunction,
    oneWayConversion,
    unwantedValue,
    skipEmit,
  }: Partial<{
    conversionFunction: (x: TValue | null) => unknown;
    unwantedValue: TValue | null;
    oneWayConversion: boolean;
    skipEmit: Ref<boolean> | boolean;
  }> = {},
): Ref<TValue> {
  const childValue = ref<TValue>(defaultValue) as Ref<TValue>;

  watch(
    parentValue,
    (value) => {
      const newValue = (
        conversionFunction && !oneWayConversion ? conversionFunction(value) : value
      ) as TValue;

      /* do not update if already equal to child  */
      if (isEqual(newValue, childValue.value)) return;

      childValue.value = newValue;
    },
    { immediate: true },
  );

  watch(
    childValue,
    (newValue) => {
      if (unref(skipEmit)) return;

      /** do not emit new value, if unwanted value present */
      if (unwantedValue !== undefined && newValue === unwantedValue) return;

      /* do not update if already equal to parent */
      if (isEqual(newValue, parentValue.value)) return;

      context.emit(
        'update:modelValue',
        conversionFunction ? conversionFunction(newValue) : newValue,
      );
    },
    { deep: true },
  );

  return childValue;
}

export interface UseTrimmedInputOnBlur {
  onBlur: (event: MouseEvent) => void;
}

/** Update input value with trimmed input on blur event
 * @param inputValue value of the input
 * @param conversionFunction function that should be called before emitting the update event to convert the input value
 */
export function useTrimmedInputOnBlur<T>(
  context: SetupContext,
  inputValue: Ref<T | null>,
  conversionFunction?: (x: T) => unknown,
): UseTrimmedInputOnBlur {
  return {
    onBlur: (event: MouseEvent): void => {
      /** trim input value, if type of string */
      const trimmedInput =
        typeof inputValue.value === 'string' ? inputValue.value.trim() : inputValue.value;

      /** emit additional input event to update variable with trimmed input */
      context.emit(
        'input',
        conversionFunction ? conversionFunction(trimmedInput as T) : trimmedInput,
      );

      /** fire basic blur event */
      context.emit('blur', event);
    },
  };
}

/** Default number of milliseconds to debounce validation. This can be used when different action interferes with validation process */
export const defaultValidationDebounce = 50;

export interface UseValidationDebounce {
  debounceValidation: number;
}

export function getDebouncedValidationProps() {
  return {
    /** The number of milliseconds to wait until validation will be processed */
    debounceValidation: {
      type: Number,
      default: 0,
    },
  };
}

/** Returns the width of the displayed text depending on the used font */
export function getDisplayedTextWidth(text: string, font = '16px Montserrat', ceil = true): number {
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) return 0;
  canvasContext.font = font;
  const metrics = canvasContext.measureText(text);
  return ceil ? Math.ceil(metrics.width) : metrics.width;
}
