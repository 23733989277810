<template>
  <span
    class="ds-svg-icon"
    :class="[`--${theme}`, `--${color}`, `--${size}`, props.class]"
    :style="outerStyle"
    :data-icon-name="icon"
  >
    <span
      class="tw-blue tw-inline-flex tw-items-center tw-justify-center tw-flex-none"
      :class="{ 'inner-circle': useInnerBorder }"
      :style="innerStyle"
      v-bind="$attrs"
    >
      <svg v-bind="svgAttrs" xmlns="http://www.w3.org/2000/svg">
        <path
          v-for="(d, idx) in dList"
          :key="idx"
          :d="d"
          :stroke-width="strokeWidth"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </span>
</template>
<script setup lang="ts">
import { PropType, StyleValue } from 'vue';

import { SvgIconType } from '@/assets/generated/svgIcons/_types';
import { IconToBundleMapping } from '@/assets/generated/svgIcons/iconMappings';
import { DsSvgIconSize } from '@/ds-components/types';
import { useLoggingService } from '@/services/logging/composable';

const props = defineProps({
  class: {
    type: [String, Array, Object] as PropType<
      string | Record<string, boolean> | (Record<string, boolean> | string)[]
    >,
    default: '',
  },
  color: {
    type: String as PropType<
      'inherit' | 'inherit-fill' | 'blue' | 'grey' | 'info' | 'attention' | 'warning' | 'success'
    >,
    default: 'inherit',
  },
  customStrokeWidth: {
    type: Number as PropType<number | null>,
    default: null,
  },
  icon: {
    type: String as PropType<SvgIconType>,
    required: true,
  },
  size: {
    type: String as PropType<DsSvgIconSize>,
    default: 'md',
  },
  theme: {
    type: String as PropType<
      'default' | 'circle' | 'halo-light' | 'halo-dark' | 'double-outline' | 'modern'
    >,
    default: 'default',
  },
});

const dList = ref([]);

const logger = useLoggingService();

watch(
  () => props.icon,
  async () => {
    const bundle = IconToBundleMapping[props.icon];
    if (!bundle) {
      logger.error(`Unable to determine bundle for icon ${props.icon}`, { code: 'MISSING_ICON' });
      return;
    }

    // the vite bundler requires a static extension so we add .ts here instead of adding it to the bundle mapping
    const iconBundleContext = (await import(`@/assets/generated/svgIcons/${bundle}.ts`)).default;

    const iconValue = iconBundleContext[props.icon];
    if (!iconValue) {
      logger.error(`Unable to find icon path in bundle ${bundle} for icon ${props.icon}`, {
        code: 'MISSING_ICON',
      });
      return;
    }

    dList.value = JSON.parse(iconValue);
  },
  { immediate: true },
);

defineOptions({ inheritAttrs: false });

const useOuterCircle = computed(
  () => props.color !== 'inherit' && ['circle', 'halo-light', 'halo-dark'].includes(props.theme),
);
const useOuterBorder = computed(
  () => props.color !== 'inherit' && ['halo-light', 'halo-dark'].includes(props.theme),
);
const useInnerBorder = computed(
  () => props.color !== 'inherit' && props.theme === 'double-outline',
);

const iconSize = computed(() => {
  const sizes: Record<DsSvgIconSize, number> = {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 28,
    '2xl': 32,
    '4xl': 64,
  };
  return sizes[props.size] ?? 20;
});

const outerStyle = computed(() => {
  const multiplier = props.theme === 'default' ? 1 : 2;
  const size = iconSize.value;
  const outerSize = size * multiplier;
  const style: StyleValue = {
    height: `${outerSize}px`,
    width: `${outerSize}px`,
  };
  const borderStyle = useInnerBorder.value
    ? { borderWidth: `${size / 10}px` }
    : {
        borderWidth: useOuterBorder.value ? `${Math.round(size / 4)}px` : '',
      };
  if (useInnerBorder.value) {
    style.padding = `${Math.round(size / 6)}px`;
  }
  return {
    ...style,
    ...borderStyle,
  };
});

const innerStyle = computed(() => {
  const size = iconSize.value;
  const style: StyleValue = {
    height: `${size}px`,
    width: `${size}px`,
  };
  if (useInnerBorder.value) {
    const borderWidth = Math.round(size / 10);
    const borderColor = `var(--color-${props.color}-100)`;
    const padding = Math.round(size / 8);
    style.border = `solid ${borderWidth}px ${borderColor}`;
    style.padding = `${padding}px`;
    style.height = `${size + borderWidth * 2 + padding * 2}px`;
    style.width = `${size + borderWidth * 2 + padding * 2}px`;
  } else {
    const marginFactor = useOuterBorder.value ? 4 : 2;
    style.margin = useOuterCircle.value ? `${Math.round(size / marginFactor)}px` : '';
  }
  return style;
});

const svgAttrs = computed(() => {
  const viewBox = `0 0 24 24`;

  return {
    fill: props.color === 'inherit-fill' ? 'currentColor' : 'none',
    height: iconSize.value,
    viewBox,
    width: iconSize.value,
  };
});

const strokeWidth = computed(() => {
  if (props.customStrokeWidth) return props.customStrokeWidth;

  const widths: Record<DsSvgIconSize, number> = {
    xs: 2.5,
    sm: 2,
    md: 1.6,
    lg: 1.6,
    xl: 1.8,
    '2xl': 2,
    '4xl': 2.4,
  };
  return widths[props.size];
});
</script>

<style scoped>
.ds-svg-icon {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-flex-none tw-rounded-full;
}

.ds-svg-icon .inner-circle {
  @apply tw-rounded-full;
}
.ds-svg-icon.--inherit,
.ds-svg-icon.--inherit-fill {
  @apply tw-stroke-[currentColor];
}
.ds-svg-icon.--blue {
  @apply tw-stroke-blue-500 tw-bg-blue-50;
}
.ds-svg-icon.--grey {
  @apply tw-stroke-grey-500 tw-bg-grey-50;
}
.ds-svg-icon.--info {
  @apply tw-stroke-info-500 tw-bg-info-50;
}
.ds-svg-icon.--attention {
  @apply tw-stroke-attention-500 tw-bg-attention-50;
}
.ds-svg-icon.--warning {
  @apply tw-stroke-warning-500 tw-bg-warning-50;
}
.ds-svg-icon.--success {
  @apply tw-stroke-success-600 tw-bg-success-50;
}

.ds-svg-icon.--halo-dark,
.ds-svg-icon.--halo-light,
.ds-svg-icon.--double-outline {
  @apply tw-border-solid;
}

/* Light halo */
.ds-svg-icon.--blue.--halo-light {
  @apply tw-stroke-blue-500 tw-border-blue-50 tw-bg-blue-100;
}
.ds-svg-icon.--grey.--halo-light {
  @apply tw-stroke-grey-500 tw-border-grey-50 tw-bg-grey-100;
}
.ds-svg-icon.--info.--halo-light {
  @apply tw-stroke-info-500 tw-border-info-50 tw-bg-info-100;
}
.ds-svg-icon.--attention.--halo-light {
  @apply tw-stroke-attention-500 tw-border-attention-50 tw-bg-attention-100;
}
.ds-svg-icon.--warning.--halo-light {
  @apply tw-stroke-warning-500 tw-border-warning-50 tw-bg-warning-100;
}
.ds-svg-icon.--success.--halo-light {
  @apply tw-stroke-success-600 tw-border-success-50 tw-bg-success-100;
}

/* Dark halo */
.ds-svg-icon.--blue.--halo-dark {
  @apply tw-stroke-white tw-border-blue-700 tw-bg-blue-600;
}
.ds-svg-icon.--grey.--halo-dark {
  @apply tw-stroke-white tw-border-grey-700 tw-bg-grey-600;
}
.ds-svg-icon.--info.--halo-dark {
  @apply tw-stroke-white tw-border-info-700 tw-bg-info-600;
}
.ds-svg-icon.--attention.--halo-dark {
  @apply tw-stroke-white tw-border-attention-600 tw-bg-attention-500;
}
.ds-svg-icon.--warning.--halo-dark {
  @apply tw-stroke-white tw-border-warning-600 tw-bg-warning-500;
}
.ds-svg-icon.--success.--halo-dark {
  @apply tw-stroke-white tw-border-success-600 tw-bg-success-500;
}

/* Double outline */
.ds-svg-icon.--double-outline {
  @apply tw-bg-transparent;
}
.ds-svg-icon.--blue.--double-outline {
  @apply tw-border-blue-50;
}
.ds-svg-icon.--blue.--double-outline span {
  @apply tw-border-blue-100;
}
.ds-svg-icon.--grey.--double-outline {
  @apply tw-border-grey-50;
}
.ds-svg-icon.--grey.--double-outline span {
  @apply tw-border-grey-100;
}
.ds-svg-icon.--info.--double-outline {
  @apply tw-border-info-50;
}
.ds-svg-icon.--info.--double-outline span {
  @apply tw-border-info-100;
}
.ds-svg-icon.--attention.--double-outline {
  @apply tw-border-attention-50;
}
.ds-svg-icon.--attention.--double-outline span {
  @apply tw-border-attention-100;
}
.ds-svg-icon.--warning.--double-outline {
  @apply tw-border-warning-50;
}
.ds-svg-icon.--warning.--double-outline span {
  @apply tw-border-warning-100;
}
.ds-svg-icon.--success.--double-outline {
  @apply tw-border-success-50;
}
.ds-svg-icon.--success.--double-outline span {
  @apply tw-border-success-100;
}

.ds-svg-icon.--modern {
  @apply tw-border tw-border-solid tw-border-grey-100 tw-bg-transparent;
}
.ds-svg-icon.--modern.--xs {
  @apply tw-rounded-2xs;
}
.ds-svg-icon.--modern.--sm {
  @apply tw-rounded-xs;
}
.ds-svg-icon.--modern.--md {
  @apply tw-rounded-xs;
}
.ds-svg-icon.--modern.--lg {
  @apply tw-rounded-sm;
}
.ds-svg-icon.--modern.--xl {
  @apply tw-rounded-sm;
}
.ds-svg-icon.--modern.--2xl,
.ds-svg-icon.--modern.--4xl {
  @apply tw-rounded-md;
}

.ds-svg-icon.--default {
  @apply tw-bg-transparent tw-border-none;
}
</style>
