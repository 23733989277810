import { DependencyType } from '@koppla-tech/scheduling-engine';
import { isEqual } from 'date-fns';

import {
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
} from '@/common/types';
import { scrollCollisionTargetIntoView } from '@/features/collisions/bryntum/interactions';
import { useCollisionStore } from '@/features/collisions/collisionStore';
import { MembershipStore } from '@/features/memberships/membershipStore';
import { useNotificationStore } from '@/features/notifications';
import { OrderStore } from '@/features/orders';
import { ProjectContributorStore } from '@/features/projectContributors/store/projectContributorStore';
import { getEndDependingOnDependency } from '@/helpers/orders/dependencies';
import { StatusReport } from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { useScheduleStore } from '@/services/store/schedule';
import { checkIfEventIsFilteredOut } from '@/services/store/schedule/actions/filter';
import { CollisionCounter } from '@/services/store/schedule/types';
import { UserStore } from '@/services/store/user/store';
import { AnalyticsEvent } from '@/utils/analyticsEvents/categories';

import { getDependentEntities } from '../orderDependencies/orderDependencyUtils';
import { CollisionViewedEvent } from './collisionAnalytics';
import { CollisionStore } from './collisionStore';
import { Collision } from './types';

export function transformDependenciesToCollisions(
  orderDependencies: OrderDependencyEntity[],
  stores: Pick<InteractiveEntityStores, 'orderStore' | 'orderDependencyStore' | 'milestoneStore'>,
): Collision[] {
  return orderDependencies
    .map((orderDependency): Collision | undefined => {
      if (!orderDependency.isViolated) {
        return undefined;
      }

      const { from, to, fromType, toType } = getDependentEntities(stores, {
        dependencyOrId: orderDependency,
      });

      if (!from || !to) {
        return undefined;
      }

      if (dependencyCollisionIsHidden(orderDependency, stores)) {
        return undefined;
      }

      let date: Date;
      let type: 'start' | 'end';
      let wbsSectionId: string | null;

      if (toType === NodeName.ORDER) {
        const order = to as OrderEntity;
        const startDate = order.startAt;
        const endDate = order.finishAt;
        date = getEndDependingOnDependency(orderDependency.type, startDate, endDate);
        type = [DependencyType.SS, DependencyType.FS].includes(orderDependency.type)
          ? 'start'
          : 'end';
        wbsSectionId = order.wbsSection.id;
      } else {
        const milestone = to as MilestoneEntity;
        date = milestone.date;
        type = 'start';
        wbsSectionId = milestone.wbsSection?.id ?? null;
      }

      return {
        id: orderDependency.id,
        dependencyId: orderDependency.id,
        fromType: fromType,
        fromId: from.id,
        fromName: from.name,
        toType: toType,
        toId: to.id,
        toName: to.name,
        date,
        type,
        wbsSectionId,
      };
    })
    .filter(Boolean) as Collision[];
}

export function dependencyCollisionIsHidden(
  orderDependency: OrderDependencyEntity,
  stores: Pick<InteractiveEntityStores, 'orderStore' | 'orderDependencyStore' | 'milestoneStore'>,
): boolean {
  const { to, toType } = getDependentEntities(stores, {
    dependencyOrId: orderDependency,
  });

  if (!to) {
    return true;
  }
  if (toType === NodeName.ORDER) {
    const order = to as OrderEntity;
    if ([StatusReport.DONE, StatusReport.REPORTED_DONE].includes(order.status as StatusReport)) {
      return true;
    }
  } else {
    const milestone = to as MilestoneEntity;
    if (!!milestone.completedAt) {
      return true;
    }
  }

  return false;
}

export function isCollisionFiltered(id: string, store: CollisionStore) {
  const collision = store.collisions.get(id);
  if (!collision) {
    return true;
  }

  return checkIfEventIsFilteredOut(collision.toId, collision.toType);
}

export const findUniqueCollisions = (
  collisionMap: Map<string, Collision>,
  {
    by = 'both',
  }: {
    by?: 'date' | 'section' | 'both';
  } = {},
): Map<string, Collision> => {
  const getUniqueKey = (collision: Collision): string => {
    if (by === 'date') {
      return collision.date.toISOString();
    }
    if (by === 'section') {
      return collision.wbsSectionId ?? '';
    }
    return `${collision.date.toISOString()}_${collision.wbsSectionId}`;
  };

  const alreadyFoundKeys = new Set<string>();
  const uniqueCollisions = new Map<string, Collision>();

  collisionMap.forEach((collision) => {
    const key = getUniqueKey(collision);
    if (!alreadyFoundKeys.has(key)) {
      alreadyFoundKeys.add(key);
      uniqueCollisions.set(collision.id, collision);
    }
  });

  return uniqueCollisions;
};

export function isMilestoneCollision(collision: Collision): boolean {
  return collision.fromType === NodeName.MILESTONE || collision.toType === NodeName.MILESTONE;
}

function orderBelongsToOwnMemberships(
  orderId: string,
  orderStore: OrderStore,
  membershipStore: MembershipStore,
): boolean {
  const order = orderStore.orders.get(orderId);
  return membershipStore.contributorGroupBelongsToOwnMemberships(order?.contributorGroup?.id).value;
}

export function isOwnOrderCollision(
  collision: Collision,
  orderStore: OrderStore,
  membershipStore: MembershipStore,
): boolean {
  if (collision.fromType === NodeName.ORDER) {
    if (orderBelongsToOwnMemberships(collision.fromId, orderStore, membershipStore)) {
      return true;
    }
  }
  if (collision.toType === NodeName.ORDER) {
    if (orderBelongsToOwnMemberships(collision.toId, orderStore, membershipStore)) {
      return true;
    }
  }
  return false;
}

type GroupedCollisions = {
  milestones: [string, Collision[]][];
  orders: [string, Collision[]][];
  myOrders: [string, Collision[]][];
  other: [string, Collision[]][];
};

export const groupRelevantCollisions = (
  collisionStore: CollisionStore,
  userStore: UserStore,
  membershipStore: MembershipStore,
  projectContributorStore: ProjectContributorStore,
  orderStore: OrderStore,
) => {
  const allCollisionsAreRelevantToTheUser = computed(() => {
    return (
      membershipStore.isCurrentTenantAdmin ||
      projectContributorStore.userIsManagementGroupMember(userStore.ownUser?.id).value
    );
  });
  const relevantCollisions = computed(() => {
    const groups: GroupedCollisions = {
      milestones: [],
      orders: [],
      myOrders: [],
      other: [],
    };
    const collisionsGroupedByTarget = getCollisionsByTarget(collisionStore.collisions);

    collisionsGroupedByTarget.forEach((collisions, targetId) => {
      if (!collisions.length) return;
      if (collisions[0].toType === NodeName.MILESTONE) {
        groups.milestones.push([targetId, collisions]);
      } else if (collisions[0].toType === NodeName.ORDER) {
        if (allCollisionsAreRelevantToTheUser.value) {
          groups.orders.push([targetId, collisions]);
        } else if (orderBelongsToOwnMemberships(collisions[0].toId, orderStore, membershipStore)) {
          groups.myOrders.push([targetId, collisions]);
        } else {
          groups.other.push([targetId, collisions]);
        }
      }
    });

    return groups;
  });

  return { relevantCollisions };
};

export const getCollisionCounterForEntity = (entityId: string): CollisionCounter => {
  const { collisions } = useCollisionStore();

  const collisionsToCheck = Array.from(collisions.values()).filter((collision) => {
    return collision.toId === entityId;
  });
  if (collisionsToCheck.length === 0) {
    return null;
  }
  const collisionCounter = { start: 0, end: 0 };

  collisionsToCheck.forEach(({ type }) => collisionCounter[type]++);
  return collisionCounter;
};

export const getCollisionsOfDate = (
  collisionMap: Map<string, Collision>,
  date: Date,
): Collision[] => {
  return Array.from(collisionMap.values()).filter((collision) => {
    return isEqual(collision.date, date);
  });
};

export const getCollisionsByTarget = (
  collisions: Map<string, Collision> | Collision[],
): Map<string, Collision[]> => {
  const collisionsMap = new Map<string, Collision[]>();
  collisions.forEach((collision) => {
    const existing = collisionsMap.get(collision.toId);
    if (existing) {
      existing.push(collision);
    } else {
      collisionsMap.set(collision.toId, [collision]);
    }
  });
  return collisionsMap;
};

export const entityHasCollisions = (
  collisions: Map<string, Collision> | Collision[],
  targetId: string,
): boolean => {
  return Array.from(collisions.values()).some((collision) => collision.toId === targetId);
};

export type ScheduleAssistantState = 'ACTIVE' | 'INACTIVE' | 'AVAILABLE';

export const getScheduleAssistantState = (
  milestone: MilestoneEntity | null | undefined,
  dependencies: Map<string, OrderDependencyEntity>,
): ScheduleAssistantState => {
  if (!milestone || milestone.type === 'FLEXIBLE') {
    return 'INACTIVE';
  }
  const hasIncomingDependencies = Array.from(dependencies.values()).some(
    (dependency) => dependency.to.id === milestone.id,
  );
  return hasIncomingDependencies ? 'ACTIVE' : 'AVAILABLE';
};

export const showMilestoneCollisionNotifications = (
  collisions: Map<string, Collision> | Collision[],
) => {
  const scheduleStore = useScheduleStore();

  if (scheduleStore.loading || scheduleStore.isSwitchingScheduler) return;

  collisions.forEach((collision: Collision) => {
    if (collision.toType === NodeName.MILESTONE) {
      useNotificationStore().push({
        titleI18nKey: 'collisions.collisionMilestoneNotificationTitle',
        titleHTMLAttributes: { class: 'tw-text-attention-500' },
        bodyI18nKey: 'collisions.collisionMilestoneNotificationBody',
        primaryAction: {
          i18nKey: 'collisions.jumpToCollision',
          callback: () => {
            jumpToCollision(
              collision,
              new CollisionViewedEvent({ type: 'milestone', source: 'notification' }),
            );
          },
        },
        type: 'attention',
        iconProps: {
          icon: 'collision',
          theme: 'circle',
        },
      });
    }
  });
};

export const jumpToCollision = async (collision: Collision, trackingEvent: AnalyticsEvent) => {
  const scheduler = getScheduler();
  if (!scheduler) return;

  await scrollCollisionTargetIntoView(
    scheduler,
    useScheduleStore(),
    collision,
    trackingEvent,
    true,
  );
};
