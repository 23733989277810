import { DomClassList } from '@bryntum/schedulerpro';
import { isAfter, isBefore } from 'date-fns';

import { OrderEntity, TradeEntity } from '@/common/types';
import { BaseActualStatus, getBaseActualColor, useBaseOrder } from '@/features/basePlan';
import { getCollisionCounterForEntity } from '@/features/collisions/collisionUtils';
import { statusNotSet, StatusReport } from '@/helpers/orders/status';
import {
  determineColorBrightness,
  getHexFromCssVariable,
  hasHighLuminance,
} from '@/helpers/utils/colors';
import { NodeName } from '@/repositories/utils/cache';
import {
  BaseScheduleEventParser,
  schedulerClassConfig,
} from '@/services/store/schedule/parsers/base';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import {
  SchedulerEvent,
  SchedulerEventAppearance,
  SchedulerEventColorType,
} from '@/services/store/schedule/types';

export class OrderEventParser
  extends BaseScheduleEventParser
  implements ScheduleModelParser<OrderEntity, [Map<string, TradeEntity>]>
{
  public entityToModel(order: OrderEntity, trades: Map<string, TradeEntity>): SchedulerEvent {
    const baseInput = this.parseSharedInput(
      order,
      'startAt',
      this.getFinishDateKey(order),
      NodeName.ORDER,
    );
    return {
      ...baseInput,
      calendarId: order.calendar?.id ?? null,
      cls: new DomClassList([schedulerClassConfig[NodeName.ORDER]]),
      appearance: this.getAppearance(order, trades),
      percentDone: statusNotSet(order.status)
        ? 0
        : // Inverse the percentages in order to show them on the right hand side with white opacity
          100 - (order?.progress ?? 0),
      resourceId: order.wbsSection?.id ?? null,
      manuallyScheduled: true,
      collisionCounter: getCollisionCounterForEntity(order.id),
    };
  }

  /**
   * The displayed end date of an order depends on its status
   * If actual finished date is smaller (before the) than planned finish date,
   * And the finishedDate is AFTER the startDate
   * use this as displayed date
   */
  public getFinishDateKey(order: OrderEntity): keyof OrderEntity {
    return order.finishedAt &&
      isBefore(new Date(order.finishedAt), new Date(order.finishAt)) &&
      isAfter(new Date(order.finishedAt), new Date(order.startAt))
      ? 'finishedAt'
      : 'finishAt';
  }

  public getAppearance(
    order: OrderEntity,
    trades: Map<string, TradeEntity>,
  ): Record<number, SchedulerEventAppearance> {
    return {
      [SchedulerEventColorType.TRADE]: this.getTradeViewAppearance(order, trades),
      [SchedulerEventColorType.STATUS]: this.getStatusViewAppearance(order),
      [SchedulerEventColorType.BASE_ACTUAL]: this.getBaseActualViewAppearance(order),
    };
  }

  public getTradeViewAppearance(
    order: OrderEntity,
    trades: Map<string, TradeEntity>,
  ): SchedulerEventAppearance {
    const classList: string[] = [];
    const color =
      trades.get(order.tenantTradeVariation?.id ?? '')?.color ??
      getHexFromCssVariable(`--color-grey-300`);
    const brightness = determineColorBrightness(color);

    // add black text for light colors
    if (brightness === 'light') classList.push('black-event-text');
    // add black borders for very light colors
    if (hasHighLuminance(color)) classList.push('black-event-border');
    // additional styling for light colors

    return {
      cls: classList,
      eventColor: color,
    };
  }

  private getStatusViewAppearance(order: OrderEntity): SchedulerEventAppearance {
    const status = order.status;

    return {
      cls: [`order-status-report-${status ?? StatusReport.NOT_SET}-event`],
      eventColor: '',
    };
  }

  private getBaseActualViewAppearance(order: OrderEntity): SchedulerEventAppearance {
    const orderStatus = useBaseOrder(ref(order.id)).value?.status ?? BaseActualStatus.UNAVAILABLE;
    const stateColor = getBaseActualColor(orderStatus);

    return {
      cls: [],
      eventColor: getHexFromCssVariable(stateColor),
    };
  }
}
