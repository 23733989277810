import { APP_ENV, APP_VERSION } from '@/utils/config';

export type BulkOrder = {
  id: string;
  name: string;
  timezone: string | null;
  startAt: string;
  finishAt: string;
  startedAt: string | null;
  finishedAt: string | null;
  tenantTradeVariation: {
    id: string;
  };
  progress: number | null;
  status: string;
  wbsSection: {
    id: string;
  };
  contributorGroup: {
    id: string;
  } | null;
  tradeSequenceActivity: {
    id: string;
  } | null;
  tradeSequenceInstanceId: string | null;
  calendar: {
    id: string;
  };
  isFixed: boolean;
  dryingBreak: {
    name: string | null;
    duration: number;
  } | null;
};

export type BulkOrderDependency = {
  id: string;
  type: string;
  lagInMinutes: number;
  bufferInMinutes: number;
  from: {
    orderId?: string;
    milestoneId?: string;
  };
  to: {
    orderId?: string;
    milestoneId?: string;
  };
  useDryingBreak: boolean;
};

type BulkBaseEntities = {
  orders: {
    id: string;
    startAt: Date;
    finishAt: Date;
    dryingBreakDuration: number | null;
  }[];
  milestones: {
    id: string;
    date: Date;
  }[];
  wbsSections: {
    id: string;
    startAt: Date;
    finishAt: Date;
  }[];
};

export class BulkApiClient {
  public constructor(
    private endpoint: string,
    private getToken: (refresh?: boolean) => Promise<string>,
  ) {}

  // See here for types https://github.com/koppla-tech/monolith-backend-service/blob/master/serverless/src/modules/http/types.ts
  public async getOrders(projectId: string): Promise<BulkOrder[]> {
    const response = await this.makeRequest(`${this.endpoint}/orders?projectId=${projectId}`);

    if (response.status !== 200) throw new Error(await response.text());

    const body = await response.json();
    return body as BulkOrder[];
  }

  public async getDependencies(projectId: string): Promise<BulkOrderDependency[]> {
    const response = await this.makeRequest(`${this.endpoint}/dependencies?projectId=${projectId}`);

    if (response.status !== 200) throw new Error(await response.text());

    const body = await response.json();
    return body as BulkOrderDependency[];
  }

  public async getBaseEntities(projectId: string): Promise<BulkBaseEntities | undefined> {
    const response = await this.makeRequest(`${this.endpoint}/project/${projectId}/basePlan`);

    if (response.status === 404) return undefined;
    if (response.status !== 200) throw new Error(await response.text());

    const body = await response.json();
    return {
      orders: body.orders.map((order) => ({
        ...order,
        dryingBreakDuration: order.dryingTimeDuration,
        startAt: new SchedulingDate(order.startAt),
        finishAt: new SchedulingDate(order.finishAt),
        finishedAt: order.finishedAt ? new SchedulingDate(order.finishedAt) : null,
      })),
      milestones: body.milestones.map((milestone) => ({
        ...milestone,
        date: new SchedulingDate(milestone.date),
      })),
      wbsSections: body.wbsSections.map((section) => ({
        ...section,
        startAt: new SchedulingDate(section.startAt),
        finishAt: new SchedulingDate(section.finishAt),
      })),
    } as BulkBaseEntities;
  }

  private async makeRequest(url: string, refresh?: boolean): Promise<Response> {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: await this.getJWT(refresh),
        ...this.getDefaultHeaders(),
      },
    });

    if (response.status === 403) {
      if (refresh) throw new Error(await response.text());
      return this.makeRequest(url, true);
    }

    return response;
  }

  private getDefaultHeaders() {
    return {
      'koppla-client-version': APP_VERSION,
      'koppla-client-name': 'web',
      'koppla-client-env': APP_ENV,
    };
  }

  private async getJWT(refresh?: boolean): Promise<string> {
    const token = await this.getToken(refresh);
    return `JWT ${token}`;
  }
}
