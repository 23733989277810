import { DomClassList } from '@bryntum/schedulerpro';

import { MilestoneEntity, OrderEntity, PauseEntity, TradeEntity } from '@/common/types';
import { useBasePlanStore } from '@/features/basePlan';
import { Collision, useCollisionStore } from '@/features/collisions';
import {
  findUniqueCollisions,
  showMilestoneCollisionNotifications,
} from '@/features/collisions/collisionUtils';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { useProjectStore } from '@/features/projects';
import { useWbsSectionStore } from '@/features/projectStructure';
import { useProjectTradeStore } from '@/features/projectTrades';
import { getManualPromise, toAsyncWatch } from '@/helpers/utils/functions';
import { findMapDifferences } from '@/helpers/utils/objects';
import { NodeName } from '@/repositories/utils/cache';
import { useHolidayStore } from '@/services/store/holiday';
import { ScheduleStore } from '@/services/store/schedule';
import { redrawDependencies } from '@/services/store/schedule/actions/initialize/utils';
import { updateMap, watchMap } from '@/services/store/schedule/actions/utils/mapState';
import {
  DryingBreakEventParser,
  MilestoneEventParser,
  OrderEventParser,
  PauseEventParser,
  ResourceParser,
  TimeRangeParser,
} from '@/services/store/schedule/parsers';
import { schedulerClassConfig } from '@/services/store/schedule/parsers/base';
import { SectionSummaryEventParser } from '@/services/store/schedule/parsers/sectionSummary';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { AvailableHolidayCountry, AvailableHolidayState } from '@/utils/holidayList';

import { CollisionEventParser } from '../../parsers/collision';
import { SchedulerEventColorType } from '../../types';

export async function watchMilestones(store: ScheduleStore) {
  const milestoneStore = useMilestoneStore();
  const parser = new MilestoneEventParser();

  const milestoneStoreFetchPromise = toRef(milestoneStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(next: Map<string, MilestoneEntity>, prev: Map<string, MilestoneEntity>): void {
    const differences = findMapDifferences(next, prev);
    updateMap(store.entities.events, differences, (id) => {
      const milestone = next.get(id)!;
      return parser.entityToModel(milestone);
    });
    if (differences.added.length) {
      redrawDependencies(store);
    }
    resolveTransformation(true);
  }

  const unwatch = toAsyncWatch([milestoneStoreFetchPromise], () => {
    return watchMap(() => milestoneStore.milestones, onUpdate);
  });
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export async function watchPauses(store: ScheduleStore) {
  const pauseStore = usePauseStore();
  const parser = new PauseEventParser();
  const timeRangeParser = new TimeRangeParser();

  const pauseStoreFetchPromise = toRef(pauseStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(next: Map<string, PauseEntity>, prev: Map<string, PauseEntity>): void {
    const differences = findMapDifferences(next, prev);
    updateMap(store.entities.events, differences, (id) => {
      const pause = next.get(id)!;
      return parser.entityToModel(pause);
    });
    updateMap(store.entities.timeRanges, differences, (id) => {
      const pause = next.get(id)!;
      return timeRangeParser.parseFromPause(pause);
    });
    resolveTransformation(true);
  }

  const unwatch = toAsyncWatch([pauseStoreFetchPromise], () => {
    return watchMap(() => pauseStore.pauses, onUpdate);
  });
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export async function watchTrades(store: ScheduleStore) {
  const orderParser = new OrderEventParser();
  const tradeStore = useProjectTradeStore();
  const orderStore = useOrderStore();

  const tradeStoreFetchPromise = toRef(tradeStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(next: Map<string, TradeEntity>, prev: Map<string, TradeEntity>): void {
    const differences = findMapDifferences(next, prev);
    resolveTransformation(true);

    // no need to update the orders again, for only additions or deletions
    if (!differences.updated.length) return;

    const orderIdsByTradeId = new Map<string, string[]>();

    orderStore.orders.forEach((order) => {
      const tradeId = order.tenantTradeVariation?.id;
      if (!tradeId) return;
      const orders = orderIdsByTradeId.get(tradeId) || [];
      orders.push(order.id);
      orderIdsByTradeId.set(tradeId, orders);
    });

    const ordersIdsToUpdate: string[] = [];
    differences.updated.forEach((tradeId) => {
      ordersIdsToUpdate.push(...(orderIdsByTradeId.get(tradeId) || []));
    });
    updateMap(
      store.entities.events,
      { updated: ordersIdsToUpdate, added: [], deleted: [] },
      (id) => {
        const order = orderStore.orders.get(id)!;
        return orderParser.entityToModel(order, tradeStore.trades);
      },
    );
  }

  const unwatch = toAsyncWatch([tradeStoreFetchPromise], () => {
    return watchMap(() => tradeStore.trades, onUpdate);
  });
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export function setHolidays(
  store: ScheduleStore,
  country: keyof AvailableHolidayCountry,
  state: keyof AvailableHolidayState,
): void {
  const holidayStore = useHolidayStore();
  const timeRangeParser = new TimeRangeParser();

  const holidays = holidayStore.fetchAll({
    state,
    country,
  });

  store.entities.timeRanges.forEach((range) => {
    const rangeIsHoliday =
      typeof range.cls !== 'string' &&
      (range.cls as DomClassList).contains(schedulerClassConfig[NodeName.HOLIDAY]);
    if (!rangeIsHoliday) {
      return;
    }
    if (!holidayStore.holidays.get(range.id)) {
      store.entities.timeRanges.delete(range.id);
    }
  });

  holidays.forEach((holiday) => {
    store.entities.timeRanges.set(holiday.id, timeRangeParser.parseFromHoliday(holiday));
  });
}

export async function watchOrders(store: ScheduleStore) {
  const orderStore = useOrderStore();
  const tradeStore = useProjectTradeStore();
  const orderParser = new OrderEventParser();
  const dryingBreakParser = new DryingBreakEventParser();

  const orderStoreFetchPromise = toRef(orderStore, 'fetchAllPromise');
  const tradeStoreFetchPromise = toRef(tradeStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  function onUpdate(next: Map<string, OrderEntity>, prev: Map<string, OrderEntity>): void {
    const differences = findMapDifferences(next, prev);
    updateMap(store.entities.events, differences, (id) => {
      const order = next.get(id)!;
      return orderParser.entityToModel(order, tradeStore.trades);
    });

    differences.deleted.forEach((id) => {
      store.entities.events.delete(DryingBreakEventParser.orderIdToDryingBreakId(id));
      store.entities.dependencies.delete(DryingBreakEventParser.getFakeDependencyId(id));
    });

    updateMap(store.entities.events, differences, (id) => {
      const order = next.get(id)!;
      const dryingBreak = dryingBreakParser.entityToModel(order, store.i18n, tradeStore.trades);

      if (dryingBreak == null) {
        store.entities.events.delete(DryingBreakEventParser.orderIdToDryingBreakId(id));
        store.entities.dependencies.delete(DryingBreakEventParser.getFakeDependencyId(id));
      } else {
        const dryingBreakDependency = dryingBreakParser.getFakeDependency(order);
        store.entities.dependencies.set(dryingBreakDependency.id, dryingBreakDependency);
      }
      return dryingBreak;
    });

    if (differences.added.length) {
      redrawDependencies(store);
    }

    resolveTransformation(true);
  }

  const unwatch = toAsyncWatch([orderStoreFetchPromise, tradeStoreFetchPromise], () => {
    return watchMap(() => orderStore.orders, onUpdate);
  });
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export async function watchSectionSummary(store: ScheduleStore) {
  const orderStore = useOrderStore();
  const sectionStore = useWbsSectionStore();
  const scheduleAppearance = useScheduleAppearanceStore();
  const basePlanStore = useBasePlanStore();

  const orderStoreFetchPromise = toRef(orderStore, 'fetchAllPromise');
  const sectionStoreFetchPromise = toRef(sectionStore, 'fetchAllPromise');
  const basePlanStoreFetchPromise = toRef(basePlanStore, 'fetchAllPromise');
  const { resolve: resolveTransformation, promise: transformationPromise } = getManualPromise();

  const unwatch = toAsyncWatch(
    [orderStoreFetchPromise, sectionStoreFetchPromise, basePlanStoreFetchPromise],
    () => {
      return watch(
        [
          () => orderStore.orders.values(),
          () => sectionStore.wbsSections.values(),
          () => basePlanStore.baseOrders.values(),
          () => scheduleAppearance.eventColorType,
        ],
        () => {
          store.entities.sectionSummaryEvents =
            SectionSummaryEventParser.generateSectionSummaryEvents(
              sectionStore.wbsSectionsList,
              Array.from(orderStore.orders.values()),
              scheduleAppearance.eventColorType === SchedulerEventColorType.BASE_ACTUAL,
            );
          resolveTransformation(true);
        },
        {
          immediate: true,
        },
      );
    },
  );
  store.utils.unwatchers.push(unwatch);
  return transformationPromise;
}

export async function watchCollisions(store: ScheduleStore) {
  const orderStore = useOrderStore();
  const milestoneStore = useMilestoneStore();
  const tradeStore = useProjectTradeStore();
  const collisionStore = useCollisionStore();
  const projectStore = useProjectStore();

  const resourceParser = new ResourceParser();
  const timeRangeParser = new TimeRangeParser();
  const eventParser = new CollisionEventParser();

  const orderParser = new OrderEventParser();
  const dryingBreakParser = new DryingBreakEventParser();
  const milestoneParser = new MilestoneEventParser();

  function onUpdate(next: Map<string, Collision>, prev: Map<string, Collision>): void {
    const collisionResource = resourceParser.parseCollisionResource();
    const collisionCount = next.size;
    if (collisionCount > 0) {
      store.entities.resources.set(collisionResource.id, collisionResource);
    } else {
      store.entities.resources.delete(collisionResource.id);
    }

    const newDateUniqueCollisions = findUniqueCollisions(next, { by: 'date' });
    const oldDateUniqueCollisions = findUniqueCollisions(prev, { by: 'date' });

    updateMap(
      store.entities.events,
      // Since dates are aggregated, we simply recreate them all, as otherwise we can't use
      // the current approach where we use one of the aggregated events as subsitute for all events
      {
        added: [...newDateUniqueCollisions.keys()],
        updated: [],
        deleted: [...oldDateUniqueCollisions.keys()],
      },
      (id) => {
        const collision = next.get(id)!;
        return eventParser.entityToModel(
          collision,
          collisionStore.collisions,
          projectStore.currentProject?.hourlyPlanningEnabled,
        );
      },
    );

    const cellUniqueDifferences = findMapDifferences(
      findUniqueCollisions(next),
      findUniqueCollisions(prev),
    );
    updateMap(store.entities.resourceTimeRanges, cellUniqueDifferences, (id) => {
      const collision = next.get(id)!;
      return timeRangeParser.parseFromCollision(
        collision,
        projectStore.currentProject?.hourlyPlanningEnabled,
      );
    });

    const changedOrders: OrderEntity[] = [];
    const changedMilestones: MilestoneEntity[] = [];

    const differences = findMapDifferences(next, prev);
    [...differences.added, ...differences.updated].forEach((id) => {
      const collision = next.get(id)!;
      if (collision.toType === NodeName.ORDER) {
        const order = orderStore.orders.get(collision.toId)!;
        changedOrders.push(order);
      } else {
        const milestone = milestoneStore.milestones.get(collision.toId)!;
        changedMilestones.push(milestone);
      }
    });
    differences.deleted.forEach((id) => {
      const collision = prev.get(id)!;
      if (collision.toType === NodeName.ORDER) {
        const order = orderStore.orders.get(collision.toId);
        if (order) {
          changedOrders.push(order);
        }
      } else {
        const milestone = milestoneStore.milestones.get(collision.toId);
        if (milestone) {
          changedMilestones.push(milestone);
        }
      }
    });

    showMilestoneCollisionNotifications(
      differences.added.map((id) => next.get(id)).filter(Boolean) as Collision[],
    );
    changedOrders.forEach((order) => {
      store.entities.events.set(order.id, orderParser.entityToModel(order, tradeStore.trades));

      const dryingBreak = dryingBreakParser.entityToModel(order, store.i18n, tradeStore.trades);
      if (dryingBreak) {
        store.entities.events.set(dryingBreak.id, dryingBreak);
      }
    });
    changedMilestones.forEach((milestone) => {
      store.entities.events.set(milestone.id, milestoneParser.entityToModel(milestone));
    });
  }

  const unwatch = watchMap(() => collisionStore.collisions, onUpdate);
  store.utils.unwatchers.push(unwatch);
}
