import { WbsSectionEntity } from '@/common/types';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';

import { useWbsSectionStore } from '../store';
import { getSectionChildren } from './sectionTreeUtils';

export function isEmptySection(section: WbsSectionEntity): boolean {
  const milestoneStore = useMilestoneStore();
  const orderStore = useOrderStore();
  const sectionStore = useWbsSectionStore();

  const hasMilestone = [...milestoneStore.milestones.values()].some(
    (milestone) => milestone.wbsSection?.id === section.id,
  );
  const hasOrder = [...orderStore.orders.values()].some(
    (order) => order.wbsSection?.id === section.id,
  );
  return (
    !hasMilestone &&
    !hasOrder &&
    getSectionChildren(sectionStore.wbsSections, section).every((child) => isEmptySection(child))
  );
}
