import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { SchedulerEvent } from '@/services/store/schedule/types';

export interface EventDragStartListener {
  eventDragStart: ReturnType<typeof useEventDragStartListener>;
}

export function useEventDragStartListener(
  store: ScheduleStore,
): (data: { eventRecords: SchedulerEvent[] }) => void {
  return ({ eventRecords }) => {
    store.utils.isDraggingEvent = true;
    if (eventRecords.length > 1) return;
    // Bryntum auto-selects moved records, but we want to clear them when only one event is dragged
    const scheduler = getScheduler();
    store.utils.allowEventDeselection = true;
    scheduler?.clearEventSelection();
  };
}
