export function containsDependencyCycle(dependencies: { from: string; to: string }[]): boolean {
  if (!dependencies.length) return false;

  const adjacent = new Map<string, string[]>();

  const visited: Record<string, boolean> = {};

  const recStack: Record<string, boolean> = {};

  dependencies.forEach((deps) => {
    if (adjacent.has(deps.from)) {
      adjacent.get(deps.from)?.push(deps.to);
    } else {
      adjacent.set(deps.from, [deps.to]);
    }
  });

  for (const child of [...adjacent.keys()]) {
    const result = isCyclicUtil(child, visited, recStack, adjacent);
    if (result !== undefined) {
      return true;
    }
  }
  return false;
}

function isCyclicUtil(
  id: string,
  visited: Record<string, boolean>,
  recStack: Record<string, boolean>,
  adj: Map<string, string[]>,
): string[] | undefined {
  // Mark the current node as visited and
  // part of recursion stack
  if (recStack[id])
    return Object.entries(recStack)
      .filter((v) => v[1])
      .map((v) => v[0]);

  if (visited[id]) return undefined;

  visited[id] = true;

  recStack[id] = true;
  const children = adj.get(id) ?? [];

  for (const child of children) {
    const result = isCyclicUtil(child, visited, recStack, adj);
    if (result !== undefined) {
      return result;
    }
  }

  recStack[id] = false;

  return undefined;
}
