export function convertFromUtcToLocalTimezone(sourceDate: SchedulingDate | string): Date {
  sourceDate = new SchedulingDate(sourceDate);
  const year = sourceDate.getFullYear();
  const month = sourceDate.getMonth();
  const day = sourceDate.getDate();
  const hours = sourceDate.getHours();
  const minutes = sourceDate.getMinutes();
  const seconds = sourceDate.getSeconds();
  const milliseconds = sourceDate.getMilliseconds();

  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}
