import { defineStore } from 'pinia';
import { v4 as uuidV4 } from 'uuid';

import { useLoggingService } from '@/services/logging/composable';

import { notificationSelectors } from './notificationSelectors';
import { Notification, NotificationOptions } from './types';

export const useNotificationStore = defineStore('notificationStore', () => {
  const loggingService = useLoggingService();

  const notifications = reactive<Notification[]>([]);
  const activeNotification = ref<Notification | null>(null);
  let currentTimeout = 0;

  // eslint-disable-next-line complexity
  const push = ({
    testId,
    titleI18nKey,
    titleI18nKeyVariables = {},
    titleHTMLAttributes = {},
    bodyI18nKey,
    bodyI18nKeyVariables = {},
    bodyI18nKeyVariablesKeys = {},
    type = 'success',
    timeout = 5000,
    iconProps = {
      icon: 'check-circle',
    },
    primaryAction = null,
    secondaryAction = null,
    onClose = null,
    analyticsEvent = null,
  }: NotificationOptions) => {
    const notification: Notification = {
      id: uuidV4(),
      testId: testId ?? notificationSelectors.defaultNotificationId,
      titleI18nKey,
      titleI18nKeyVariables,
      titleHTMLAttributes,
      bodyI18nKey: bodyI18nKey ?? '',
      bodyI18nKeyVariables,
      bodyI18nKeyVariablesKeys,
      type,
      timeout,
      iconProps: {
        ...iconProps,
        color: iconProps?.color || type,
        theme: iconProps?.theme || 'halo-light',
      },
      primaryAction: primaryAction
        ? {
            i18nKey: primaryAction.i18nKey,
            i18nKeyVariables: primaryAction.i18nKeyVariables ?? {},
            callback: () => {
              closeCurrentNotification();
              primaryAction.callback();
            },
          }
        : null,
      secondaryAction: secondaryAction
        ? {
            i18nKey: secondaryAction.i18nKey,
            i18nKeyVariables: secondaryAction.i18nKeyVariables ?? {},
            callback: () => {
              closeCurrentNotification();
              secondaryAction.callback();
            },
          }
        : null,
      onClose: () => {
        if (onClose) onClose();
        closeCurrentNotification();
      },
      analyticsEvent,
    };
    showNextNotification(notification);
  };

  const closeCurrentNotification = () => {
    window.clearTimeout(currentTimeout);
    clearActiveAndShowNextIfExists();
  };

  const showNextNotification = (notification: Notification) => {
    if (activeNotification.value !== null) {
      notifications.push(notification);
      return;
    }
    activeNotification.value = notification;
    if (notification.analyticsEvent) {
      loggingService.trackEvent(notification.analyticsEvent);
    }
    if (!notification.timeout) return;
    currentTimeout = window.setTimeout(() => {
      clearActiveAndShowNextIfExists();
    }, notification.timeout);
  };
  const clearActiveAndShowNextIfExists = () => {
    activeNotification.value = null;
    const next = notifications.shift();
    if (next) showNextNotification(next);
  };

  const pauseTimeout = () => {
    window.clearTimeout(currentTimeout);
  };

  const resumeTimeout = () => {
    if (activeNotification.value && activeNotification.value.timeout) {
      currentTimeout = window.setTimeout(() => {
        clearActiveAndShowNextIfExists();
      }, activeNotification.value.timeout);
    }
  };

  const showCreateNotification = (options: NotificationOptions) => {
    push(options);
  };

  const showUpdateNotification = (options: NotificationOptions) => {
    push(options);
  };

  const showAttentionNotification = (options: NotificationOptions) => {
    push({
      type: 'attention',
      iconProps: {
        icon: 'info-circle',
      },
      ...options,
    });
  };

  return {
    activeNotification,
    notifications,
    push,
    closeCurrentNotification,
    pauseTimeout,
    resumeTimeout,
    showCreateNotification,
    showUpdateNotification,
    showAttentionNotification,
  };
});
