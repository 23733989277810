import { useI18n } from 'vue-i18n';

import { useBasePlanStore } from '@/features/basePlan';
import { useMembershipStore } from '@/features/memberships/membershipStore';
import { useProjectAlternativeStore } from '@/features/projectAlternatives';
import { useProjectStore } from '@/features/projects/projectStore';
import { useProjectStructureTemplatesStore } from '@/features/projectStructureTemplates';
import { refreshScheduleUI } from '@/features/schedule/bryntum/schedulerInteractions';
import { useTradeSequenceTemplatesStore } from '@/features/tenantTemplates/tradeSequenceTemplatesStore';
import { useTicketStore } from '@/features/tickets';
import { ProjectPermission } from '@/graphql/__generated__/graphql';
import { LoggingService } from '@/interfaces/services';
import { AdditionalAnalyticsProperties } from '@/interfaces/services/logging';
import { useJimo } from '@/plugins/jimo/jimo';
import { useUserback } from '@/plugins/userback';
import { useLoggingService } from '@/services/logging/composable';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { useTenantStore } from '@/services/store/tenant';
import { useUserStore } from '@/services/store/user';
import { identifyUser, setUserLanguage } from '@/services/store/user/utils';

import { useGlobalStore } from './globalStore';

export const useAppLevelStores = () => {
  const jimo = useJimo();
  const userback = useUserback();
  const i18n = useI18n();
  const loggingService = useLoggingService();
  const userStore = useUserStore();
  const tenantStore = useTenantStore();
  const membershipStore = useMembershipStore();

  const initialize = async (tenantId: string | null): Promise<void> => {
    /**
     * tenantId is null if user only belongs to external groups (or is currently
     * in external tenant)
     */
    const [tenantMemberships, tenant, user] = await Promise.all([
      membershipStore.fetchTenantMemberships(),
      tenantId ? tenantStore.fetchOwn({ id: tenantId }) : Promise.resolve(),
      userStore.fetchOwn(),
      membershipStore.fetchContributorGroupMemberships(),
    ]);

    const userIsTenantMember = Boolean(
      tenant && tenantMemberships.some((membership) => membership.tenant.id === tenant?.id),
    );

    identifyUser(user, userIsTenantMember, loggingService, userback, jimo);
    setUserLanguage(user, userback, i18n);

    if (tenant && userIsTenantMember) {
      const results: Promise<unknown>[] = [];

      loggingService.setTenantGroup({
        identification: tenant.id,
        properties: {
          name: tenant.name,
          type: tenant.type,
          companyId: tenant.id,
          created: tenant.createdAt,
          hasMembership: userIsTenantMember,
        },
      });

      if (tenant.features?.enableTradeSequenceTemplates) {
        results.push(useTradeSequenceTemplatesStore().initialize(tenant.id));
      }

      results.push(useProjectStructureTemplatesStore().initialize(tenant.id));

      await Promise.all(results);
    }
  };

  return { initialize };
};

export const useProjectLevelStores = () => {
  const projectStore = useProjectStore();
  const ticketStore = useTicketStore();
  const basePlanStore = useBasePlanStore();
  const projectAlternativeStore = useProjectAlternativeStore();
  const globalStore = useGlobalStore();
  const membershipStore = useMembershipStore();

  /**
   * Keeps track of last projectId that 'initialize' was called with.
   * Used inside of 'initialize' to prevent race conditions.
   */
  let lastInitializedProjectId: string = '';

  const initialize = async (
    projectId: string,
    partial = false,
    loggingService: LoggingService,
  ): Promise<void> => {
    lastInitializedProjectId = projectId;

    performance.mark('projectLevelStores initialize start');
    if (!partial) {
      const project = await projectStore.fetchProject({ id: projectId }).catch(() => null);
      if (!project || projectId !== lastInitializedProjectId) return;

      loggingService.setProjectGroup({
        identification: projectId,
        properties: {
          id: project.id,
          name: project.name,
          address: {
            city: project.address.city,
            country: project.address.country,
            postalCode: project.address.postalCode,
            street: project.address.street,
          },
          createdAt: project.createdAt,
          projectId: project.id,
          tenantId: project.tenant.id,
          status: project.lifecycleStatus,
          // legacy
          created: project.createdAt,
          tenantName: project.tenant.name,
          $city: project.address.city,
          $country: project.address.country,
        },
      });

      const type = await membershipStore.getProjectPermissionType(project);
      if (projectId !== lastInitializedProjectId) return;
      loggingService.setAdditionalProperty(AdditionalAnalyticsProperties.ProjectMembership, type);
    }

    membershipStore
      .fetchProjectAuthorization({ projectId, tenantId: globalStore.currentTenantId ?? '_' })
      .then(async ({ projectMembership, tenantMembership }) => {
        const isTenantAdmin = tenantMembership?.isAdmin ?? false;
        const isProjectEditor = Boolean(
          projectMembership && projectMembership.permission === ProjectPermission.Editor,
        );
        const canLoadAlternatives = isProjectEditor || isTenantAdmin;
        if (!partial && canLoadAlternatives) {
          projectAlternativeStore.initialize(projectId).catch(() => {});
        }
      });

    const { scheduleProjectId } = globalStore;

    if (!scheduleProjectId) return;

    /**
     * Fetch async, result isn't required for schedule interactions
     * and long running query would block the schedule if we await it.
     */
    ticketStore.fetchAll({ project: scheduleProjectId }).catch();

    if (!partial) {
      basePlanStore.initialize(projectId).then(() => {
        refreshScheduleUI(getScheduler());
      });
    }

    performance.mark('projectLevelStores initialize end');
    performance.measure(
      'projectLevelStores initialize',
      'projectLevelStores initialize start',
      'projectLevelStores initialize end',
    );
  };

  return { initialize };
};
