import {
  DependencyType,
  generateDerivedOrdersFromFreshPrefilledTradeSequence,
  generateDerivedOrdersFromTradeSequence,
  IdMapping,
  OrderSchedulingEngine,
  syncDerivedOrdersWithTradeSequence,
} from '@koppla-tech/scheduling-engine';
import { OperationNames } from 'events.schema';

import {
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
  OrderDependencyEntity,
  PartialEntity,
} from '@/common/types';
import {
  convertCalendarCreateOperationInput,
  convertCalendarUpdateOperationInput,
} from '@/features/calendars/calendarOperationsUtils';
import {
  convertMilestoneCreateOperationInput,
  convertMilestoneUpdateOperationInput,
} from '@/features/milestones/stores/milestoneOperationsUtils';
import {
  convertRemoveScheduleNodesOperationInput,
  convertRescheduleScheduleNodesOperationInput,
  convertRestoreScheduleNodesOperationInput,
} from '@/features/multiSelectActions/multiSelectActionOperationsUtils';
import {
  convertOrderDependencyCreateEventInput,
  convertOrderDependencyUpdateEventInput,
} from '@/features/orderDependencies/orderDependencyOperationsUtils';
import { getDependentEntities } from '@/features/orderDependencies/orderDependencyUtils';
import {
  convertOrderCopyOperationInput,
  convertOrderCreateOperationInput,
  convertOrderUpdateOperationInput,
} from '@/features/orders/orderOperationsUtils';
import { convertOrderStatusReportCreateOperationInput } from '@/features/orderStatusReport/orderStatusReportOperationsUtils';
import {
  convertPauseCreateOperationInput,
  convertPauseUpdateOperationInput,
} from '@/features/pauses/pauseOperationsUtils';
import { convertProjectAlternativeDeleteOperationInput } from '@/features/projectAlternatives/store/projectAlternativesOperationsUtils';
import {
  convertProjectCompleteSetupOperationInput,
  convertProjectUpdateStatusOperationInput,
} from '@/features/projects/projectOperationsUtils';
import {
  convertSectionCreateOperationInput,
  convertSectionIndentOperationInput,
  convertSectionOutdentOperationInput,
  convertSectionUpdateOperationInput,
} from '@/features/projectStructure/utils/sectionOperationsUtils';
import {
  convertProjectTradeSequenceCreateOperationInput,
  convertProjectTradeSequenceInsertOperationInput,
  convertProjectTradeSequenceUpdateOperationInput,
} from '@/features/projectTradeSequences/projectTradeSequenceOperationsUtils';
import { getSchedulingContextFromEvent } from '@/features/schedule/utils';
import { NodeName } from '@/repositories/utils/cache';
import { checkIfEventIsFilteredOut } from '@/services/store/schedule/actions/filter';

import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  RemoteProjectChangeEvent,
} from '../../types';
import {
  encodeIdsOfNewOrdersAndDependencies,
  mapInteractiveChangesToSENG,
  sanitizeSchedulingChanges,
  unionizeChanges,
} from './sanitize';

export function getChangesFromEvent(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  engine: OrderSchedulingEngine,
  entityStores: InteractiveEntityStores,
  entityStates: InteractiveEntityMaps,
  stateless = false,
): {
  changes: InteractiveEntityChanges;
  idMappings?: IdMapping[];
} {
  const directChanges = getDirectChangesFromEvent(engine, event, entityStores, entityStates);
  const { changes: indirectChanges, idMappings } = getIndirectChangesFromEvent(
    engine,
    event,
    directChanges,
    entityStores,
    entityStates,
    stateless,
  );
  const changes = unionizeChanges(directChanges, indirectChanges);

  return { changes, idMappings };
}

/**
 * Returns all changes to the state that can be directly derived from the event.
 * @param engine
 * @param event
 * @param entityStores
 * @param entityStates
 * @returns
 */
// eslint-disable-next-line complexity -- Numerous cases, but not complex
function getDirectChangesFromEvent(
  engine: OrderSchedulingEngine,
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  entityStores: InteractiveEntityStores,
  entityStates: InteractiveEntityMaps,
): InteractiveEntityChanges {
  /**
   * Schedule Nodes Operations
   */
  if (event.operation.name === OperationNames.RestoreScheduleNodes) {
    return {
      add: convertRestoreScheduleNodesOperationInput(event.restoredEntities, entityStores),
    };
  }
  if (event.operation.name === OperationNames.RemoveScheduleNodes) {
    return {
      delete: convertRemoveScheduleNodesOperationInput(event.operation.input, entityStates),
    };
  }
  if (event.operation.name === OperationNames.RescheduleScheduleNodes) {
    return {
      update: convertRescheduleScheduleNodesOperationInput(event.operation.input, entityStates),
    };
  }
  /**
   * Pause Operations
   */
  if (event.operation.name === OperationNames.CreatePauses) {
    return {
      add: {
        pauses: convertPauseCreateOperationInput(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdatePauses) {
    return {
      update: {
        pauses: convertPauseUpdateOperationInput(event.operation.input),
      },
    };
  }
  /**
   * Calendar Operations
   */
  if (event.operation.name === OperationNames.CreateCalendars) {
    const { addedCalendars, updatedCalendars } = convertCalendarCreateOperationInput(
      event.operation.input,
      entityStates.calendars!,
    );
    return {
      add: {
        calendars: addedCalendars,
      },
      update: {
        calendars: updatedCalendars,
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateCalendars) {
    return {
      update: {
        calendars: convertCalendarUpdateOperationInput(
          event.operation.input,
          entityStates.calendars!,
        ),
      },
    };
  }
  /**
   * WBS Section Operations
   */
  if (event.operation.name === OperationNames.CreateWBSSections) {
    const restoredEntities = convertRestoreScheduleNodesOperationInput(
      event.restoredEntities,
      entityStores,
    );

    const changes = convertSectionCreateOperationInput(event.operation.input, entityStates);
    return {
      add: { ...restoredEntities, wbsSections: changes.addedSections },
      update: {
        wbsSections: changes.updatedSections,
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateWBSSection) {
    return {
      update: {
        wbsSections: convertSectionUpdateOperationInput(event.operation.input, entityStates),
      },
    };
  }
  if (event.operation.name === OperationNames.IndentWBSSection) {
    const { addedSections, updatedSections } = convertSectionIndentOperationInput(
      event.operation.input,
      entityStates,
    );
    return {
      add: {
        wbsSections: addedSections,
      },
      update: {
        wbsSections: updatedSections,
      },
    };
  }
  if (event.operation.name === OperationNames.OutdentWBSSection) {
    const { updatedSections, deletedMilestones, deletedSections } =
      convertSectionOutdentOperationInput(event.operation.input, entityStates);
    return {
      update: {
        wbsSections: updatedSections,
      },
      delete: {
        wbsSections: deletedSections,
        milestones: deletedMilestones,
      },
    };
  }
  /**
   * Order Operations
   */
  if (event.operation.name === OperationNames.CreateOrders) {
    return {
      add: {
        orders: convertOrderCreateOperationInput(engine, event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateOrders) {
    return {
      update: {
        orders: convertOrderUpdateOperationInput(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.CopyOrders) {
    const { copiedOrders, copiedDependencies } = convertOrderCopyOperationInput(
      event.operation.input,
    );
    return {
      add: {
        orders: copiedOrders,
        dependencies: copiedDependencies,
      },
    };
  }
  /**
   * Order Status Report Operations
   */
  if (event.operation.name === OperationNames.CreateOrderStatusReports) {
    const { addedOrderStatusReports, updatedOrders } = convertOrderStatusReportCreateOperationInput(
      event,
      event.operation.input,
    );
    return {
      add: {
        orderStatus: addedOrderStatusReports,
      },
      update: {
        orders: updatedOrders,
      },
    };
  }
  /**
   * Trade Sequence Operations
   */
  if (event.operation.name === OperationNames.CreateTradeSequence) {
    const { addedTradeSequences } = convertProjectTradeSequenceCreateOperationInput(
      event.operation.input,
    );
    return {
      add: {
        tradeSequences: addedTradeSequences,
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateTradeSequence) {
    const { updatedTradeSequences } = convertProjectTradeSequenceUpdateOperationInput(
      event.operation.input,
    );
    return {
      update: {
        tradeSequences: updatedTradeSequences,
      },
    };
  }
  /**
   * Order Dependency Operations
   */
  if (event.operation.name === OperationNames.CreateDependencies) {
    const convertedDependencies = convertOrderDependencyCreateEventInput(event.operation.input);
    // Check if the from and to entities exist, if not, soft delete the entity
    convertedDependencies.forEach((dependency) => {
      const { from, to } = getDependentEntities(entityStores, { dependencyOrId: dependency });
      if (!from) {
        entityStores.orderStore().setSoftDeletedEntity(dependency.from.id, dependency.id);
        entityStores.milestoneStore().setSoftDeletedEntity(dependency.from.id, dependency.id);
      }
      if (!to) {
        entityStores.orderStore().setSoftDeletedEntity(dependency.to.id, dependency.id);
        entityStores.milestoneStore().setSoftDeletedEntity(dependency.to.id, dependency.id);
      }
    });
    return {
      add: {
        dependencies: convertedDependencies,
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateDependencies) {
    return {
      update: {
        dependencies: convertOrderDependencyUpdateEventInput(event.operation.input),
      },
    };
  }
  /**
   * Milestone Operations
   */
  if (event.operation.name === OperationNames.CreateMilestones) {
    return {
      add: {
        milestones: convertMilestoneCreateOperationInput(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateMilestones) {
    return {
      update: {
        milestones: convertMilestoneUpdateOperationInput(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateMilestoneStatus) {
    return {
      update: {
        milestones: convertMilestoneUpdateOperationInput([
          {
            id: event.operation.input.milestoneId,
            completedAt: event.operation.input.completedAt,
          },
        ]),
      },
    };
  }
  /**
   * Project Operations
   */
  if (event.operation.name === OperationNames.UpdateProjectStatus) {
    const currentProjectId = entityStores.projectStore().currentProject?.id;
    if (!currentProjectId) return {};
    return {
      update: {
        projects: convertProjectUpdateStatusOperationInput(currentProjectId, event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.CompleteProjectSetup) {
    const currentProjectId = entityStores.projectStore().currentProject?.id;
    if (!currentProjectId) return {};
    const { addedCalendars, addedSections, updatedProjects } =
      convertProjectCompleteSetupOperationInput(currentProjectId, event.operation.input);
    return {
      add: {
        calendars: addedCalendars,
        wbsSections: addedSections,
      },
      update: {
        projects: updatedProjects,
      },
    };
  }
  /**
   * Project Alternative Operations
   */
  if (event.operation.name === OperationNames.DeleteProjectAlternative) {
    return {
      delete: {
        projectAlternatives: convertProjectAlternativeDeleteOperationInput(event.operation.input),
      },
    };
  }

  return {};
}

/**
 * Returns all changes to the state that can only be identified indirectly through scheduling of the direct changes.
 * @param engine
 * @param event
 * @param directChanges
 * @param entityStores
 * @param entityStates
 * @param stateless
 * @returns
 */
function getIndirectChangesFromEvent(
  engine: OrderSchedulingEngine,
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  directChanges: InteractiveEntityChanges,
  entityStores: InteractiveEntityStores,
  entityStates: InteractiveEntityMaps,
  stateless = false,
): {
  changes: InteractiveEntityChanges;
  idMappings?: IdMapping[];
} {
  const { context } = event.operation;

  /**
   * Trade Sequence Operations
   */
  if (event.operation.name === OperationNames.CreateTradeSequence) {
    if (!event.operation.input.orderAssignments) {
      return { changes: {} };
    }

    const converted = convertProjectTradeSequenceCreateOperationInput(event.operation.input);

    const { changes: schedulingChanges, idMapping: generatedIdMapping } =
      generateDerivedOrdersFromFreshPrefilledTradeSequence(
        engine,
        converted.addedTradeSequences[0],
        {
          idMapping: converted.idMapping ?? undefined,
          orderAssignments: event.operation.input.orderAssignments,
          stateless,
        },
      );

    return {
      changes: encodeIdsOfNewOrdersAndDependencies(
        engine,
        schedulingChanges as InteractiveEntityChanges,
        stateless,
      ),
      idMappings: [generatedIdMapping],
    };
  }

  if (event.operation.name === OperationNames.InsertTradeSequence) {
    const currentTradeSequence = entityStates.tradeSequences?.get(
      event.operation.input.tradeSequenceId,
    );
    if (!currentTradeSequence) {
      throw new Error('Could not find to be inserted trade sequence in the store');
    }

    const { changes: schedulingChanges, idMapping: generatedIdMapping } =
      generateDerivedOrdersFromTradeSequence(
        engine,
        {
          ...currentTradeSequence,
          dependencies: currentTradeSequence.dependencies.map((dependency) => ({
            ...dependency,
            type: dependency.type as DependencyType,
          })),
        },
        {
          ...convertProjectTradeSequenceInsertOperationInput(event.operation.input),
          stateless,
        },
      );
    return {
      changes: encodeIdsOfNewOrdersAndDependencies(
        engine,
        schedulingChanges as InteractiveEntityChanges,
        stateless,
      ),
      idMappings: [generatedIdMapping],
    };
  }
  if (event.operation.name === OperationNames.UpdateTradeSequence) {
    const currentTradeSequence = entityStates.tradeSequences?.get(event.operation.input.id);
    if (!currentTradeSequence) {
      throw new Error('Could not find to be synced trade sequence in the store');
    }
    const converted = convertProjectTradeSequenceUpdateOperationInput(event.operation.input);
    const { changes: schedulingChanges, idMappings: syncedIdMappings } =
      syncDerivedOrdersWithTradeSequence(
        engine,
        converted.updatedTradeSequences[0],
        {
          ...currentTradeSequence,
          dependencies: currentTradeSequence.dependencies.map((dependency) => ({
            ...dependency,
            type: dependency.type as DependencyType,
          })),
        },
        {
          idMappings: converted.idMappings,
          dependencies: context?.dependencies as PartialEntity<OrderDependencyEntity>[],
          stateless,
        },
      );

    return {
      changes: encodeIdsOfNewOrdersAndDependencies(
        engine,
        schedulingChanges as InteractiveEntityChanges,
        stateless,
      ),
      idMappings: syncedIdMappings,
    };
  }
  /**
   * Scheduling-Related Operations
   */
  if (changesNeedRescheduling(directChanges)) {
    const sanitizedChanges = sanitizeSchedulingChanges(directChanges, context);
    const { changes: schedulingChanges } = engine.schedule(
      mapInteractiveChangesToSENG(sanitizedChanges),
      getSchedulingContextFromEvent(event),
      {
        stateless,
      },
    );
    return {
      changes: schedulingChanges as InteractiveEntityChanges,
    };
  }
  return { changes: {} };
}

// eslint-disable-next-line complexity -- Numerous cases, but not complex
export function distributeChangesToStores(
  entityStores: InteractiveEntityStores,
  changes: InteractiveEntityChanges,
): void {
  entityStores.pauseStore().applyChanges({
    add: changes.add?.pauses,
    update: changes.update?.pauses,
    delete: changes.delete?.pauses,
  });
  entityStores.wbsSectionStore().applyChanges(
    {
      add: changes.add?.wbsSections,
      update: changes.update?.wbsSections,
      delete: changes.delete?.wbsSections,
    },
    entityStores.orderStore().copyState(),
    entityStores.milestoneStore().copyState(),
  );
  entityStores.orderStore().applyChanges({
    add: changes.add?.orders,
    update: changes.update?.orders,
    delete: changes.delete?.orders,
    addStatus: changes.add?.orderStatus,
  });
  entityStores.calendarStore().applyChanges({
    add: changes.add?.calendars,
    update: changes.update?.calendars,
    delete: changes.delete?.calendars,
  });
  entityStores.projectTradeSequenceStore().applyChanges({
    add: changes.add?.tradeSequences,
    update: changes.update?.tradeSequences,
    delete: changes.delete?.tradeSequences,
  });
  entityStores.milestoneStore().applyChanges({
    add: changes.add?.milestones,
    update: changes.update?.milestones,
    delete: changes.delete?.milestones,
  });
  entityStores.orderDependencyStore().applyChanges({
    add: changes.add?.dependencies,
    update: changes.update?.dependencies,
    delete: changes.delete?.dependencies,
  });
  entityStores.projectStore().applyChanges({
    add: changes.add?.projects,
    update: changes.update?.projects,
    delete: changes.delete?.projects,
  });
}

// eslint-disable-next-line complexity -- Numerous cases, but not complex
export function changesNeedRescheduling(changes: InteractiveEntityChanges): boolean {
  return (
    !!changes.add?.orders?.length ||
    !!changes.update?.orders?.length ||
    !!changes.delete?.orders?.length ||
    !!changes.add?.milestones?.length ||
    !!changes.update?.milestones?.length ||
    !!changes.delete?.milestones?.length ||
    !!changes.add?.calendars?.length ||
    !!changes.update?.calendars?.length ||
    !!changes.delete?.calendars?.length ||
    !!changes.add?.pauses?.length ||
    !!changes.update?.pauses?.length ||
    !!changes.delete?.pauses?.length ||
    !!changes.add?.dependencies?.length ||
    !!changes.update?.dependencies?.length ||
    !!changes.delete?.dependencies?.length ||
    !!changes.add?.contributorGroups?.length ||
    !!changes.update?.contributorGroups?.length ||
    !!changes.delete?.contributorGroups?.length
  );
}

export function identifyChangesThatAreFilteredOut(changes: InteractiveEntityChanges): {
  addedElementTypes: NodeName[];
  editedElementTypes: NodeName[];
} {
  const addedOrders = (changes.add?.orders ?? []).filter((order) =>
    checkIfEventIsFilteredOut(order.id, NodeName.ORDER),
  );
  const addedMilestones = (changes.add?.milestones ?? []).filter((milestone) =>
    checkIfEventIsFilteredOut(milestone.id, NodeName.MILESTONE),
  );
  const addedPauses = (changes.add?.pauses ?? []).filter((pause) =>
    checkIfEventIsFilteredOut(pause.id, NodeName.PAUSE),
  );
  const editedOrders = (changes.update?.orders ?? []).filter((order) =>
    checkIfEventIsFilteredOut(order.id, NodeName.ORDER),
  );
  const editedMilestones = (changes.update?.milestones ?? []).filter((milestone) =>
    checkIfEventIsFilteredOut(milestone.id, NodeName.MILESTONE),
  );
  const editedPauses = (changes.update?.pauses ?? []).filter((pause) =>
    checkIfEventIsFilteredOut(pause.id, NodeName.PAUSE),
  );

  return {
    addedElementTypes: [
      ...addedOrders.map(() => NodeName.ORDER),
      ...addedMilestones.map(() => NodeName.MILESTONE),
      ...addedPauses.map(() => NodeName.PAUSE),
    ],
    editedElementTypes: [
      ...editedOrders.map(() => NodeName.ORDER),
      ...editedMilestones.map(() => NodeName.MILESTONE),
      ...editedPauses.map(() => NodeName.PAUSE),
    ],
  };
}
