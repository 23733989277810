import { DomConfig } from '@bryntum/schedulerpro';

import SectionItem, { SectionItemConfiguration } from '@/features/sections/jsx/SectionItem';
import { getTeleportId } from '@/helpers/vue/teleport';
import { ColumnRendererData, SchedulerResource } from '@/services/store/schedule/types';

function getSectionTypeForCell(data: ColumnRendererData): string {
  let sectionType = '';
  if (data.record.isTopLevelRow) {
    sectionType = 'top-level-section';
  } else if (data.record.isMidLevelRow) {
    sectionType = `mid-level-section-${data.record.sectionIndentation ?? 1}`;
  } else if (data.record.isBottomLevelRow) {
    sectionType = 'bottom-level-section';
  }
  return sectionType;
}

function renderResourceCell(data: ColumnRendererData, config: SectionItemConfiguration) {
  const cell = document.createElement('div');

  // check for sections here cause the scheduler could try to rerender while
  // sections are reinitialized (e.g., during switching projects/alternatives)
  const sectionType = config.getWbsSectionsTree().size ? getSectionTypeForCell(data) : '';

  data.cellElement?.classList.add('resource-teleport-cell');
  cell.id = getTeleportId(data.record.id);
  cell.className = `resource-content resource-teleport-target ${sectionType}`;
  // Is used e.g. for exports to display the resource name in case teleport is not available
  cell.dataset.resourceName = data.record.name;

  const section = config.getWbsSectionsTree().get(data.record.id);
  if (!section) return { children: [cell] };

  cell.appendChild(
    SectionItem({
      section,
      config,
    }),
  );
  return { children: [cell] };
}

export function updateBorderClass(
  data: ColumnRendererData,
  removeTopBorder?: boolean,
  removeBottomBorder?: boolean,
): void {
  const removeTopBorderClass = 'hide-row-top-border';
  const removeBottomBorderClass = 'hide-row-bottom-border';

  const updateClass = (className: string, addClass: boolean) => {
    if (addClass) {
      if (!data.record.cls?.includes(className)) {
        data.record.cls = `${data.record.cls} ${className}`;
      }
    } else if (data.record.cls?.includes(className)) {
      data.record.cls = data.record.cls?.replace(className, '');
    }
  };

  if (removeTopBorder !== undefined) {
    updateClass(removeTopBorderClass, removeTopBorder);
  }
  if (removeBottomBorder !== undefined) {
    updateClass(removeBottomBorderClass, removeBottomBorder);
  }
}

export function renderSectionRow(
  data: ColumnRendererData,
  config: SectionItemConfiguration,
): Element | string | DomConfig | undefined {
  const ownIdx = data.grid.resourceStore.indexOf(data.record.id);
  const previousRecord = data.grid.resourceStore.getAt(ownIdx - 1) as SchedulerResource;
  const nextRecord = data.grid.resourceStore.getAt(ownIdx + 1) as SchedulerResource;

  // Set the correct border classes (to hide specific top and bottom borders) for different row types (otherwise, we might have double borders)
  if (previousRecord) {
    if (data.record.isBottomLevelRow) {
      updateBorderClass(data, undefined, nextRecord?.isMidLevelRow || false);
    } else if (data.record.isTopLevelRow) {
      updateBorderClass(data, undefined, nextRecord?.isMidLevelRow || false);
    } else if (data.record.cls?.includes('mid-level-row-1')) {
      updateBorderClass(data, previousRecord.cls?.includes('mid-level-row-1'));
    } else if (data.record.cls?.includes('mid-level-row-2')) {
      updateBorderClass(
        data,
        previousRecord.isMidLevelRow!,
        nextRecord?.cls?.includes('mid-level-row-1'),
      );
    }
  }

  return renderResourceCell(data, config);
}
