import { WbsSectionEntity } from '@/common/types';
import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

import { getSectionAnalyticsType } from '../projectStructure';
import { MilestoneType } from './types';

export class MilestoneCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.MILESTONE_CREATED;

  public constructor(
    public properties: {
      type: 'fixed' | 'flexible';
      source: 'events' | 'phase' | 'mid1' | 'mid2';
      method: 'menu' | 'double_click';
      wasTypeChanged: boolean;
    },
  ) {
    super();
  }
}

export class MilestoneOpenedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.MILESTONE_OPENED;

  public constructor(
    public properties: {
      type: 'fixed' | 'flexible';
      source: 'events' | 'phase' | 'mid1' | 'mid2';
      method: 'schedule' | 'dashboard';
    },
  ) {
    super();
  }
}

export class MilestonesDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.MILESTONES_DELETED;

  public constructor(
    public properties: {
      type: 'fixed' | 'flexible' | 'multiple';
      source: 'events' | 'phase' | 'mid1' | 'mid2' | 'multiple';
      method: 'keyboard' | 'details' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

export class MilestonesEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.MILESTONES_EDITED;

  public constructor(
    public properties: {
      type: 'fixed' | 'flexible';
      source: 'events' | 'phase' | 'mid1' | 'mid2' | 'multiple';
      method: 'details' | 'drag_and_drop' | 'keyboard' | 'toolbar';
      count: number;
      wasTypeChanged: boolean;
    },
  ) {
    super();
  }
}

export class MilestoneOverlapVisibilityToggled extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.MILESTONE_OVERLAP_VISIBILITY_TOGGLED;

  public constructor(public properties: { isExpanded: boolean }) {
    super();
  }
}

export class MilestoneStatusReported extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.MILESTONE_STATUS_REPORTED;

  public constructor(public properties: { achieved: boolean }) {
    super();
  }
}

export class AcceptanceCriterionStatusReported extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ACCEPTANCE_CRITERION_STATUS_REPORTED;

  public constructor(public properties: { done: boolean }) {
    super();
  }
}

export function getMilestoneSectionAnalyticsType(
  sections: Map<string, WbsSectionEntity>,
  sectionId: string,
): 'phase' | 'mid1' | 'mid2' | 'events' {
  if (!sectionId) return 'events';
  return getSectionAnalyticsType(sections, sectionId) as 'phase' | 'mid1' | 'mid2';
}

export const mapMilestoneToAnalyticsType = (type: MilestoneType): 'fixed' | 'flexible' =>
  type === MilestoneType.FIXED ? 'fixed' : 'flexible';
