import { endOfDay, isBefore, startOfDay } from 'date-fns';

import { useScheduleStore } from '@/services/store/schedule/store';

import { useCollisionStore } from '../collisionStore';
import { Collision } from '../types';

export function useCollisionsInRange() {
  const store = useScheduleStore();
  const collisionStore = useCollisionStore();

  const processedCollisions = computed(() => {
    const range = store.dateRange;
    const collisions = collisionStore.collisions;

    if (!range || !collisions) return { before: [], inRange: [], after: [] };

    const collisionsToProcess = Array.from(collisions.values());
    const before: Collision[] = [];
    const inRange: Collision[] = [];
    const after: Collision[] = [];

    const rangeStart = startOfDay(range.startDate);
    const rangeEnd = endOfDay(range.endDate);

    collisionsToProcess.forEach((collision: Collision) => {
      if (isBefore(collision.date, rangeStart)) {
        before.push(collision);
      } else if (isBefore(collision.date, rangeEnd)) {
        inRange.push(collision);
      } else {
        after.push(collision);
      }
    });

    return { before, inRange, after };
  });

  const collisionsBeforeRange = computed(() => processedCollisions.value.before);
  const collisionsInRange = computed(() => processedCollisions.value.inRange);
  const collisionsAfterRange = computed(() => processedCollisions.value.after);

  return {
    collisionsBeforeRange,
    collisionsInRange,
    collisionsAfterRange,
  };
}
