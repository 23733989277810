import { DependencyType } from '@koppla-tech/scheduling-engine';
import { OperationNames } from 'events.schema';

import { OrderDependencyEntity, PartialEntity } from '@/common/types';
import { useMilestoneStore } from '@/features/milestones/stores/milestoneStore';
import { useOrderStore } from '@/features/orders';

import { generateRTCMessageId, LocalProjectChangeEventTemplate } from '../realTimeCollaboration';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';

export const createProjectChangeCreateEvent = (
  vars: OrderDependencyEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  const milestoneStore = useMilestoneStore();
  const orderStore = useOrderStore();

  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateDependencies,
      input: vars.map((orderDependency) => {
        const maybeFromOrder = orderStore.orders.get(orderDependency.from.id);
        const maybeToOrder = orderStore.orders.get(orderDependency.to.id);
        const maybeFromMilestone = milestoneStore.milestones.get(orderDependency.from.id);
        const maybeToMilestone = milestoneStore.milestones.get(orderDependency.to.id);

        return {
          ...orderDependency,
          bufferInMinutes: orderDependency.bufferInMinutes,
          type: orderDependency.type as DependencyType,
          fromOrderId: maybeFromOrder?.id,
          toOrderId: maybeToOrder?.id,
          fromMilestoneId: maybeFromMilestone?.id,
          toMilestoneId: maybeToMilestone?.id,
        };
      }),
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: PartialEntity<OrderDependencyEntity>[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateDependencies,
      input: vars.map((partialOrderDependency) => ({
        id: partialOrderDependency.id,
        useDryingBreak: partialOrderDependency.useDryingBreak,
        lagInMinutes: partialOrderDependency.lagInMinutes,
        bufferInMinutes: partialOrderDependency.bufferInMinutes,
        type: partialOrderDependency.type as DependencyType | undefined,
      })),
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  orderDependencies: OrderDependencyEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        dependencies: orderDependencies.map((orderDependency) => orderDependency.id),
      },
      context,
    },
    restoredEntities: {
      dependencies: orderDependencies.map((orderDependency) => orderDependency.id),
    },
  };
};

export const createProjectChangeDeleteEvent = (
  orderDependencies: OrderDependencyEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        dependencies: orderDependencies.map((orderDependency) => orderDependency.id),
      },
      context,
    },
  };
};
