import { ScheduleConstants } from '@/common/bryntum/constants';

import { SchedulerPopupComponent, useSchedulePopupStore } from '../../schedulePopup';

export function openPopupWithTimeout(
  component: SchedulerPopupComponent,
  payload,
  isDoubleClick: Ref<boolean>,
) {
  const schedulePopupStore = useSchedulePopupStore();

  setTimeout(() => {
    if (isDoubleClick.value) return;

    schedulePopupStore.openPopup({ component, payload, patient: true });
  }, ScheduleConstants.DOUBLE_CLICK_TIMEOUT);
}
