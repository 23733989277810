import { h } from '../jsxFactory';

type IconProps = {
  icon: string;
  outlined: boolean;
  size?: number;
  viewBox?: string;
  svg?: boolean;
  strokeWidth?: number;
  className?: string;
};

export default function Icon({
  icon,
  outlined,
  size = 20,
  viewBox = '0 0 24 24',
  svg = false,
  className = '',
  strokeWidth = 1.6,
}: IconProps) {
  const innerStyle = {
    fontSize: `${size}px`,
    height: `${size}px`,
    width: `${size}px`,
  };
  return (
    <span
      class={`tw-inline-flex tw-items-center tw-justify-center tw-flex-none tw-rounded-full ${className}`}
    >
      <span
        class={`tw-leading-none tw-blue ${outlined ? 'material-icons-outlined' : 'material-icons'}`}
        style={innerStyle}
      >
        {svg ? (
          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={viewBox}>
            <path
              fill={outlined ? 'none' : 'currentColor'}
              d={icon}
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width={strokeWidth}
            />
          </svg>
        ) : (
          icon
        )}
      </span>
    </span>
  );
}
