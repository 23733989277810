<template>
  <Component
    :is="clickable ? 'button' : 'span'"
    ref="chip"
    class="ds-chip"
    v-bind="{ type: clickable ? 'button' : undefined }"
    :class="[
      `--${color}`,
      `--${size}`,
      {
        '--disabled': disabled || loading,
        '--clickable': clickable,
        'tw-mix-blend-multiply': blend,
      },
      props.class,
    ]"
    :style="customStyle"
    :title="chipTitle"
    @click="clickable && !loading ? emit('click') : null"
  >
    <span
      v-if="showDot"
      class="tw-rounded-full tw-bg-success-500 tw-border-success-50"
      :class="{
        'tw-h-[12px] tw-w-[12px] tw-border-solid tw-border-[3px]': dotOutline,
        'tw-h-[6px] tw-w-[6px]': !dotOutline,
      }"
    />
    <slot name="icon-prepend" v-bind="{ size: iconSize }"></slot>
    <slot />
    <slot name="icon-append" v-bind="{ size: iconSize }"></slot>
    <button v-if="closable" type="button" class="ds-chip-close" @click="emit('close')">
      <DsSvgIcon :size="iconSize" icon="x-close" />
    </button>
  </Component>
</template>
<script setup lang="ts">
import { DsChipColorType, DsChipCustomStyle } from '@/ds-components/types';
import { determineColorBrightness } from '@/helpers/utils/colors';

const props = defineProps({
  clickable: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
    default: 'md',
  },
  color: {
    type: String as PropType<DsChipColorType>,
    default: 'blue',
  },
  customStyle: {
    type: Object as PropType<DsChipCustomStyle>,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showDot: {
    type: Boolean,
    default: false,
  },
  dotOutline: {
    type: Boolean,
    default: false,
  },
  closable: {
    type: Boolean,
    default: false,
  },
  blend: {
    type: Boolean,
    default: true,
  },
  loading: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'click'): void;
}>();

const iconSize = computed(() => props.size);

const customStyle = computed(() => {
  if (!props.customStyle || !props.customStyle.color) return undefined;
  return props.customStyle.outlineColor
    ? {
        backgroundColor: props.customStyle.color,
        color: customTextColor.value,
        borderColor: props.customStyle.outlineColor,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {
        backgroundColor: props.customStyle.color,
        color: customTextColor.value,
      };
});

const customTextColor = computed(() => {
  if (props.customStyle && props.customStyle.textColor) return props.customStyle.textColor;
  return determineColorBrightness(props.customStyle.color) === 'dark'
    ? 'var(--color-white)'
    : 'var(--color-black)';
});

const chip = ref<HTMLElement | null>(null);
const chipTitle = computed(() => chip.value?.innerText || '');
</script>
<style>
.ds-chip {
  @apply tw-inline-flex tw-items-center tw-gap-1 tw-truncate tw-px-2 tw-py-1;
  @apply tw-rounded-xs;
}
.ds-chip.--xs {
  @apply tw-ds-text-xs--medium;
  @apply tw-leading-[14px] !important;
}
.ds-chip.--sm {
  @apply tw-ds-text-xs--medium;
  @apply tw-leading-[18px] !important;
}
.ds-chip.--md {
  @apply tw-ds-text-sm--medium;
  @apply tw-leading-[20px] !important;
}
.ds-chip.--lg {
  @apply tw-ds-text-sm--medium;
  @apply tw-leading-[22px] !important;
}
.ds-chip.--blue {
  @apply tw-bg-blue-50 tw-text-blue-500;
}
.ds-chip.--grey {
  @apply tw-bg-grey-50 tw-text-grey-500;
}
.ds-chip.--yellow {
  @apply tw-bg-yellow-50 tw-text-yellow-600;
}
.ds-chip.--petrol {
  @apply tw-bg-petrol-50 tw-text-petrol-500;
}
.ds-chip.--attention {
  @apply tw-bg-attention-50 tw-text-attention-500;
}
.ds-chip.--warning {
  @apply tw-bg-warning-50 tw-text-warning-500;
}
.ds-chip.--success {
  @apply tw-bg-success-50 tw-text-success-700;
}
.ds-chip.--info {
  @apply tw-bg-info-50 tw-text-info-500;
}
.ds-chip.--primary {
  @apply tw-bg-blue-500 tw-text-white;
}
.ds-chip.--primary.--clickable {
  @apply hover:tw-bg-blue-700;
}
.ds-chip.--primary.--disabled {
  @apply tw-bg-grey-100 tw-text-grey-50;
}
.ds-chip.--secondary {
  @apply tw-bg-white tw-text-blue-500 tw-border tw-border-solid tw-border-blue-500;
}
.ds-chip.--secondary.--clickable {
  @apply hover:tw-bg-blue-25;
}
.ds-chip.--secondary.--disabled {
  @apply tw-bg-grey-50 tw-text-grey-100 tw-border-grey-100;
  @apply tw-bg-grey-50;
}
.ds-chip.--outline-grey {
  @apply tw-bg-white tw-text-grey-400 tw-border tw-border-solid tw-border-grey-300;
  @apply tw-px-[7px] tw-py-[3px];
}
.ds-chip.--outline-grey.--clickable {
  @apply hover:tw-bg-grey-25;
}
.ds-chip.--outline-grey.--disabled {
  @apply tw-bg-grey-50 tw-text-grey-100 tw-border-grey-100;
}
.ds-chip-close {
  @apply tw-flex-none tw-flex tw-items-center tw-justify-center;
  @apply tw-p-0.5 tw-rounded-[0.1875rem] tw-outline-none tw-cursor-pointer;
  @apply hover:tw-bg-[rgba(0,0,0,0.1)];
  @apply focus-visible:tw-ring focus-visible:tw-ring-[rgba(0,0,0,0.1)];
  @apply active:tw-bg-transparent;
}
</style>
