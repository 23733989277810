import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const calendarsQuery = gqlFn(/* GraphQL */ `
  query calendarsV2($projectId: ID!, $orderBy: [String!]) {
    calendars(project: $projectId, orderBy: $orderBy) {
      edges {
        node {
          ...Calendar
        }
      }
    }
  }
`);

export const calendarIsDeletableQuery = gqlFn(/* GraphQL */ `
  query calendarsIsDeletableV2($projectId: ID!) {
    calendars(project: $projectId) {
      edges {
        node {
          id
          isDeletable
        }
      }
    }
  }
`);

export const calendarFragment = gql`
  fragment Calendar on CalendarNode {
    id
    name
    isDefault
    workingDays {
      edges {
        node {
          ...WorkingDay
        }
      }
    }
    minutesPerDay
    exceptions {
      edges {
        node {
          ...CalendarException
        }
      }
    }
  }
`;

export const workingDayFragment = gql`
  fragment WorkingDay on WorkingDayNode {
    id
    weekday
    workingTimes {
      edges {
        node {
          ...WorkingTime
        }
      }
    }
  }
`;

export const calendarExceptionFragment = gql`
  fragment CalendarException on CalendarExceptionNode {
    id
    startAt
    finishAt
    isWorkingDay
    workingTimes {
      edges {
        node {
          ...WorkingTime
        }
      }
    }
  }
`;

export const workingTimeFragment = gql`
  fragment WorkingTime on WorkingTimeNode {
    id
    from
    to
  }
`;
