import tinycolor from 'tinycolor2';

/** Converts hex color to rgba color and uses given intensity for alpha channel. Returns rgb string if no alpha value given. */
export function hexToRGBAString(hexColor: string, alpha?: number): string {
  const { r, g, b } = getRGBofHex(hexColor);
  if (alpha === undefined) {
    return `rgb(${r}, ${g}, ${b})`;
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getHexFromCssVariable(cssVariable: string) {
  return getComputedStyle(document.body)?.getPropertyValue(cssVariable).trim();
}

const LUMINANCE_INSENSITY_BREAKPOINT = 200;
const MINIMUM_CONTRAST = 255 / LUMINANCE_INSENSITY_BREAKPOINT;

export const isAboveMinimumContrast = (color: string, backgroundColor: string): boolean => {
  const luminance = getLuminance(color);
  const backgroundLuminance = getLuminance(backgroundColor);
  const contrast = calculateContrast(luminance, backgroundLuminance);
  return contrast > MINIMUM_CONTRAST;
};

const calculateContrast = (luminance1: number, luminance2: number): number => {
  const lighter = Math.max(luminance1, luminance2);
  const darker = Math.min(luminance1, luminance2);
  return (lighter + 0.05) / (darker + 0.05);
};

/** Calculates how bright the given color is */
export function determineColorBrightness(color: string): 'light' | 'dark' {
  return tinycolor(color).isLight() ? 'light' : 'dark';
}

export function hasHighLuminance(color: string): boolean {
  return getLuminance(color) > LUMINANCE_INSENSITY_BREAKPOINT;
}

/** Returns the luminance value of the color to check for brightness.
 * See: https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
 */
export function getLuminance(color: string): number {
  const { r, g, b } = getRGBofString(color);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

/** Returns a suitable text color related to the given background color of the object. Text color can be white or black  */
export function getTextColorClassForBackground(hexColor: string): Record<string, boolean> {
  const brightness = determineColorBrightness(hexColor);
  return { 'tw-text-white': brightness === 'dark', 'tw-text-black': brightness === 'light' };
}

function getRGBofString(color: string): { r: number; g: number; b: number } {
  return validateHexCode(color) ? getRGBofHex(color) : getRGBofRGB(color);
}

function getRGBofRGB(color: string): { r: number; g: number; b: number } {
  // extract RGB from rgb(a) color
  const rgbColor = color.match(/\d{1,3}/g) || [];

  if (rgbColor.length < 3) {
    return { r: 0, g: 0, b: 0 };
  }

  const r = parseInt(rgbColor[0]!, 10);
  const g = parseInt(rgbColor[1]!, 10);
  const b = parseInt(rgbColor[2]!, 10);

  return { r, g, b };
}

function getRGBofHex(color: string): { r: number; g: number; b: number } {
  // convert hex color to rgb color
  const rgbColor = color?.substring(1)?.match(/.{1,2}/g) || [];

  if (rgbColor.length < 3) {
    return { r: 0, g: 0, b: 0 };
  }

  const r = parseInt(rgbColor[0]!, 16);
  const g = parseInt(rgbColor[1]!, 16);
  const b = parseInt(rgbColor[2]!, 16);

  return { r, g, b };
}

export function validateHexCode(hexString: string): boolean {
  const regEx = /^#[0-9A-Fa-f]{6}$/;
  return regEx.test(hexString);
}

export const DISTINCT_COLORS = [
  '#AD1457',
  '#D81B60',
  '#D50000',
  '#E67C73',
  '#F4511E',
  '#EF6C00',
  '#F09300',
  '#F6BF26',
  '#E4C441',
  '#C0CA33',
  '#7CB342',
  '#33B679',
  '#0B8043',
  '#009688',
  '#039BE5',
  '#4285F4',
  '#3F51B5',
  '#7986CB',
  '#B39DDB',
  '#9E69AF',
  '#8E24AA',
  '#795548',
  '#616161',
  '#A79B8E',
];

/** Returns random color of a predefined color collection */
export function getRandomColor(): string {
  return DISTINCT_COLORS[Math.floor(Math.random() * DISTINCT_COLORS.length)];
}

// The default color for milestones.
export const DEFAULT_MILESTONE_COLOR = '#E40000';
