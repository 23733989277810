import { SchedulerEventModel } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { orderIsImmutable } from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { isPlaceholderOrDryingBreakPlaceholder } from '@/services/store/schedule/actions/placeholderEvent';
import { SchedulerEvent } from '@/services/store/schedule/types';
import { SchedulePopupStore } from '@/services/store/schedulePopup';
import eventBus from '@/utils/eventBus';

import { showImmutableHint } from '../utils';

export interface BeforeEventDragListener {
  beforeEventDrag: ReturnType<typeof useBeforeEventDragListener>;
}

export function useBeforeEventDragListener(
  store: ScheduleStore,
  schedulePopupStore: SchedulePopupStore,
  i18n: Composer,
): (data: { eventRecord: SchedulerEvent }) => boolean {
  return ({ eventRecord }) => {
    schedulePopupStore.closePopup();
    const scheduler = getScheduler();
    if (!scheduler) return false;

    if (
      store.utils.disableEventInteractions &&
      !isPlaceholderOrDryingBreakPlaceholder(eventRecord)
    ) {
      return false;
    }

    const orderStore = useOrderStore();
    const milestoneStore = useMilestoneStore();
    const featureAccessStore = useFeatureAccessStore();

    const order = orderStore.orders.get(eventRecord.id);
    const milestone = milestoneStore.milestones.get(eventRecord.id);

    if (order && !featureAccessStore.features.ORDER_PLANNING(order.id, true).value.write) {
      showImmutableHint(eventBus, i18n, 'assignment');
      return false;
    }

    if (
      (eventRecord.entity === NodeName.MILESTONE || eventRecord.entity === NodeName.PAUSE) &&
      !featureAccessStore.hasWriteAccessToPausesAndMilestones
    ) {
      showImmutableHint(eventBus, i18n, 'assignment');
      return false;
    }

    if (
      (order && orderIsImmutable(order)) ||
      (milestone && (milestone.isFixed || milestone.completedAt))
    ) {
      showImmutableHint(eventBus, i18n, 'completedFixed');
      return false;
    }

    const selected = scheduler.selectedEvents as SchedulerEvent[];
    if (scheduler.selectedEvents.length > 1) {
      const eventsToBeDeselected = selected.filter((event) => {
        const isImmutableOrder =
          event.entity === NodeName.ORDER && orderIsImmutable(orderStore.orders.get(event.id)!);
        const maybeImmutableMilestone = milestoneStore.milestones.get(event.id);
        const isImmutableMilestone =
          event.entity === NodeName.MILESTONE &&
          Boolean(maybeImmutableMilestone?.isFixed || maybeImmutableMilestone?.completedAt);

        return isImmutableOrder || isImmutableMilestone;
      });
      const movableEvents = selected.filter((event) => {
        const isMutableOrder =
          event.entity === NodeName.ORDER && !orderIsImmutable(orderStore.orders.get(event.id)!);

        const maybeMutableMilestone = milestoneStore.milestones.get(event.id);
        const isMutableMilestone =
          event.entity === NodeName.MILESTONE &&
          !maybeMutableMilestone?.isFixed &&
          !maybeMutableMilestone?.completedAt;

        return isMutableOrder || isMutableMilestone;
      });
      // deselect events if necessary, as e.g. immutable orders can't be moved
      if (eventsToBeDeselected.length) {
        store.utils.allowEventDeselection = true;
        scheduler.deselectEvents(eventsToBeDeselected as SchedulerEventModel[]);
        if (movableEvents.length) {
          showImmutableHint(eventBus, i18n, 'completedFixed', false);
        }
      }
    }
    return true;
  };
}
