import { PresetStore, SchedulerEventModel } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';
import { Router } from 'vue-router';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { useProjectStore } from '@/features/projects';
import { OwnTenantFragment } from '@/graphql/__generated__/graphql';
import { Routes } from '@/router/helpers/routeNames';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { PresetLoader } from '@/services/store/schedule/config/presetLoader';
import { updateLocale } from '@/services/store/schedule/utils/updateLocale';
import { getDefaultZoomPreset } from '@/services/store/schedule/utils/zoom';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { useTenantStore } from '@/services/store/tenant';
import { useUserStore } from '@/services/store/user';
import { getBrowserLanguage, Language } from '@/utils/languages';

import {
  refreshScheduleUI,
  scrollToEvent,
} from '../../../../../features/schedule/bryntum/schedulerInteractions';

export function setScheduleLocale(i18n: Composer) {
  const userStore = useUserStore();
  const scheduleAppearanceStore = useScheduleAppearanceStore();

  scheduleAppearanceStore.locale =
    (userStore.ownUser?.languageCode as Language) ?? getBrowserLanguage();

  const scheduler = getScheduler();
  if (!scheduler) {
    return;
  }

  /**
   * Bryntum initially always set the locale to the current browser language. There is no way to specify the initial locale.
   * If you try to set the locale, while bryntum is not ready, it will not work and still use the browser language.
   * Therefore we need to wait until the project is ready and even then it only works with a timeout.
   * The timeout amount is arbitrary, I wanted to use something that is not too long, but (hopefully) still long enough to work.
   */
  scheduler.whenProjectReady(() => {
    setTimeout(() => {
      updateLocale(i18n, scheduleAppearanceStore.locale);
    }, 500);
  });
}

export async function initializeTenant(tenantId: string): Promise<OwnTenantFragment | null> {
  const tenantStore = useTenantStore();
  return tenantStore.fetchOwn({ id: tenantId });
}

export function updatePresets(i18n: Composer, isHourlyProject: boolean): void {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const presets = new PresetLoader(ScheduleConstants.MINUTE_SNAP).registerPresets(
    i18n,
    isHourlyProject,
  );
  const scheduler = getScheduler();
  if (scheduler) {
    (scheduler?.presets as PresetStore).removeAll();
    (scheduler?.presets as PresetStore).add(presets);
  }

  scheduleAppearanceStore.zoomPresets = presets.map((preset) => preset.id as string);
  // Update to default preset, if old preset is not available anymore
  if (!presets.find((preset) => preset.id === scheduleAppearanceStore.currentZoomPreset)) {
    scheduler?.zoomTo({ preset: getDefaultZoomPreset() });
  }
}

export function watchProjectAddress(): void {
  const projectStore = useProjectStore();
  const currentProject = toRef(projectStore, 'currentProject');

  watch(
    () => currentProject.value?.address,
    () => {
      const scheduler = getScheduler();
      if (!scheduler) return;

      // Update main row on address change
      refreshScheduleUI(scheduler);
    },
  );
}

export function openObject(store: ScheduleStore, objectId: string, router?: Router): void {
  const scheduler = getScheduler();
  if (!objectId || !scheduler) return;

  scheduler.whenProjectReady(() => {
    const event = store.entities.events.get(objectId);
    const dependency = store.entities.dependencies.get(objectId);

    if (!event && !dependency && router) {
      const query = { ...router.currentRoute.value.query };
      const params = { ...router.currentRoute.value.params };
      delete params.objectId;
      router.replace({ name: Routes.Lean.Schedule, params, query }).catch(() => {});
    }

    store.openSidebar({ event, dependency });
    if (event) {
      scheduler.selectEvent(scheduler.eventStore.getById(objectId) as SchedulerEventModel);
      scrollToEvent(scheduler, objectId);
    }
    if (dependency) {
      scrollToEvent(scheduler, dependency.from);
    }
  });
}
