import {
  PresetManager,
  SchedulerPro,
  ViewPreset,
  ViewPresetHeaderRow,
} from '@bryntum/schedulerpro';
import { getISOWeek, getQuarter } from 'date-fns';
import { Composer } from 'vue-i18n';

import { basePresets } from '@/common/bryntum/basePresets';

export class PresetLoader {
  public constructor(private minuteSnap: number) {}

  public static isDayOrHourView(scheduler: SchedulerPro): boolean {
    const headers = (scheduler.viewPreset as ViewPreset).headers as ViewPresetHeaderRow[];

    return (
      headers[headers.length - 1].unit === 'day' || headers[headers.length - 1].unit === 'hour'
    );
  }

  public registerPresets(i18n: Composer, showHourlyPresets = true): Partial<ViewPreset>[] {
    const year = {
      ...basePresets['my-year'],
      headers: [
        {
          unit: 'year',
          renderer: (start: Date) => {
            return start.getFullYear();
          },
        },
        {
          unit: 'quarter',
          renderer: (start: Date) => {
            return `Q${getQuarter(start)}`;
          },
        },
      ],
    };

    const monthAndYear = {
      ...basePresets['my-monthAndYear'],
      headers: [
        {
          unit: 'year',
          renderer: (start: Date) => {
            return start.getFullYear();
          },
        },
        {
          unit: 'month',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'monthShort');
          },
        },
      ],
    };

    const dayRenderer = (date: Date) => {
      return `<div class="tw-ds-text-xs tw-text-grey-300">${i18n.d(
        new Date(date),
        'weekDay',
      )} </div> <div class="tw-ds-text-sm--medium">${date.getDate()}</div>`;
    };

    const monthRenderer = (date: Date) => {
      return `${i18n.d(new Date(date), 'monthLongYear')}`;
    };

    const weekDateAndMonth = {
      ...basePresets['my-weekDateAndMonth'],
      headers: [
        {
          unit: 'month',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'monthShortYear');
          },
        },
        {
          unit: 'week',
          renderer: (start: Date) => {
            return getISOWeek(start);
          },
          headerCellCls: 'calendar-week-row',
        },
      ],
    };

    const weekDateAndMonth2 = {
      ...basePresets['my-weekDateAndMonth-2'],
      headers: [
        {
          unit: 'month',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'monthLongYear');
          },
        },
        {
          unit: 'week',
          renderer: (start: Date) => {
            return getISOWeek(start);
          },
          headerCellCls: 'calendar-week-row',
        },
      ],
    };

    const weekAndMonthBase = {
      headers: [
        {
          unit: 'month',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'monthLongYear');
          },
        },
        {
          unit: 'week',
          renderer: (start: Date) => {
            return i18n.t('Calendar.calendarWeek', getISOWeek(start));
          },
          headerCellCls: 'calendar-week-row',
        },
      ],
    };

    const weekAndMonth = {
      ...basePresets['my-weekAndMonth'],
      ...weekAndMonthBase,
    };

    const weekAndMonth2 = {
      ...basePresets['my-weekAndMonth-2'],
      ...weekAndMonthBase,
    };

    const weekAndDayLetter = {
      ...basePresets['my-weekAndDayLetter'],
      headers: [
        {
          unit: 'month',
          renderer: monthRenderer,
        },
        {
          unit: 'week',
          renderer: (start: Date) => {
            return i18n.t('Calendar.calendarWeek', getISOWeek(start));
          },
          headerCellCls: 'calendar-week-row',
        },
        {
          unit: 'day',
          renderer: dayRenderer,
        },
      ],
    };

    const weekAndDayBase = {
      headers: [
        {
          unit: 'month',
          renderer: monthRenderer,
        },
        {
          unit: 'week',
          renderer: (start: Date) => {
            return i18n.t('Calendar.calendarWeek', getISOWeek(start));
          },
          headerCellCls: 'calendar-week-row',
        },
        {
          unit: 'day',
          renderer: dayRenderer,
        },
      ],
      timeResolution: {
        increment: 1,
        unit: 'day',
      },
    };

    const weekAndDay = {
      ...basePresets['my-weekAndDay'],
      ...weekAndDayBase,
    };

    const weekAndDay2 = {
      ...basePresets['my-weekAndDay-2'],
      ...weekAndDayBase,
    };

    const hourAndDay = {
      ...basePresets['my-hourAndDay'],
      headers: [
        {
          unit: 'day',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'dateDMYShortW');
          },
        },
        {
          unit: 'hour',
          renderer: (start: Date) => {
            return `${start.getHours()}`;
          },
        },
      ],
      timeResolution: {
        unit: 'minute',
        increment: this.minuteSnap,
      },
    };

    const hourAndDay2 = {
      ...basePresets['my-hourAndDay-2'],
      headers: [
        {
          unit: 'day',
          renderer: (start: Date) => {
            return i18n.d(new Date(start), 'dateDMYShortW');
          },
        },
        {
          unit: 'hour',
          renderer: (start: Date) => {
            return `${start.getHours()}:00`;
          },
        },
      ],
      timeResolution: {
        unit: 'minute',
        increment: this.minuteSnap,
      },
    };

    const customPresets = [
      year,
      monthAndYear,
      weekDateAndMonth,
      weekDateAndMonth2,
      weekAndMonth,
      weekAndMonth2,
      weekAndDayLetter,
      weekAndDay,
      weekAndDay2,
      hourAndDay,
      hourAndDay2,
    ].filter((preset) => {
      // filter out hourly presets for non hourly based projects
      if (showHourlyPresets) return true;
      return !(preset.id as string).toLowerCase().includes('hour');
    });

    const existingPresets = PresetManager.records;

    const filteredPresets = customPresets.filter((preset) => {
      return existingPresets.find((existingPreset) => existingPreset.id === preset.base);
    });

    return filteredPresets;
  }
}
