import { UseQueryOptions } from '@vue/apollo-composable';

import {
  OwnTenantFragment,
  TenantFragment,
  UpdateTenantMutationVariables,
} from '@/graphql/__generated__/graphql';
import { CRUDRepository, Entity, QueryResult } from '@/interfaces/repositories/base';

import { OmitAddress } from './utility';

export interface FindAllTenantsVariables {
  nameContains: string;
}

export enum TenantType {
  GeneralContractor = 'GENERAL_CONTRACTOR',
  Subcontractor = 'SUBCONTRACTOR',
  Standard = 'STANDARD',
}

export interface OwnTenant extends Omit<OmitAddress<OwnTenantFragment>, 'type'> {
  type: TenantType;
}

export interface UpdateTenantVariables extends Omit<UpdateTenantMutationVariables, 'id'> {
  previewUrl?: string | null;
}

export interface Tenant extends TenantFragment {}

export interface TenantRepository
  extends Omit<
    CRUDRepository<undefined, UpdateTenantVariables, OwnTenant, Tenant, FindAllTenantsVariables>,
    'useCreate' | 'useDelete'
  > {
  /** Fetch own tenant of currently active tenant id */
  fetchOwn(options?: UseQueryOptions): QueryResult<OwnTenant | null, Entity>;
}
