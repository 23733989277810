import { Locale, LocaleHelper, LocaleManager } from '@bryntum/schedulerpro';
import DeLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.De.js';
import EnLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.En.js';
import { Composer } from 'vue-i18n';

import { START_OF_WEEK } from '@/helpers/dates/config';
import { capitalize } from '@/helpers/utils/strings';
import { Language } from '@/utils/languages';

const getBryntumLocale = (language: Language) => {
  switch (language) {
    case Language.DE:
      return DeLocale;
    default:
      return EnLocale;
  }
};

export function updateLocale(i18n: Composer, language: Language): void {
  const locale: Locale = {
    localeName: capitalize(language),
    localeDesc: capitalize(language),
    localeCode: language,
    DateHelper: {
      weekStartDay: START_OF_WEEK,
      locale: language,
    },
    GridBase: {
      noRows: ' ',
    },
    Dependencies: {
      from: i18n.t('objects.orderDependency.from').toString(),
      to: i18n.t('objects.orderDependency.to').toString(),
      valid: i18n.t('objects.orderDependency.valid').toString(),
      invalid: i18n.t('objects.orderDependency.invalid').toString(),
    },
    PdfExport: {
      'Export failed': i18n.t('Calendar.export.failed').toString(),
      'Generating pages': i18n.t('Calendar.export.pdf.generating').toString(),
      'Server error:': i18n.t('Calendar.export.pdf.error').toString(),
      'Waiting for response from server': i18n.t('Calendar.export.pdf.waiting').toString(),
    },
  };

  const mergedLocale = LocaleHelper.mergeLocales(getBryntumLocale(language), locale) as Locale;
  // registers and applies new locale
  LocaleHelper.publishLocale(mergedLocale);

  LocaleManager.applyLocale(mergedLocale, true);
}
