import { SchedulingContext } from '@koppla-tech/scheduling-engine';

import { useUserStore } from '@/services/store/user';

import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  RemoteProjectChangeEvent,
} from '../realTimeCollaboration';

export function getCurrentSchedulingContext(): SchedulingContext {
  const userStore = useUserStore();

  if (!userStore.ownUser) {
    throw new Error('Tried to call schedule function with undefined user id');
  }

  return {
    user: { id: userStore.ownUser.id },
  };
}

export function getSchedulingContextFromEvent(
  event: LocalProjectChangeEvent | LocalProjectChangeEventTemplate | RemoteProjectChangeEvent,
): SchedulingContext {
  if ('user' in event) {
    if (!event.user) {
      throw new Error('Tried to call schedule function with undefined user id');
    }
    return {
      user: { id: event.user.id },
    };
  }
  return getCurrentSchedulingContext();
}
