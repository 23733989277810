import { useOrderStore } from '@/features/orders';
import { useProjectTradeStore } from '@/features/projectTrades';
import { isEqual } from '@/helpers/utils/objects';
import { NodeName } from '@/repositories/utils/cache';
import { SchedulerEvent } from '@/services/store/schedule/types';

const compareSameStartEvents = (a: SchedulerEvent, b: SchedulerEvent, endA: Date, endB: Date) => {
  const orderStore = useOrderStore();
  const projectTradeStore = useProjectTradeStore();

  // drying breaks and their orders should be on top of the row, as they should stay together
  const isDryingBreakOrOrderWithDryingBreak = (event: SchedulerEvent) =>
    event.entity === NodeName.DRYING_BREAK ||
    (event.entity === NodeName.ORDER && orderStore.orders.get(event.id)?.dryingBreak);

  if (isDryingBreakOrOrderWithDryingBreak(a)) {
    return -1;
  }
  if (isDryingBreakOrOrderWithDryingBreak(b)) {
    return 1;
  }
  if (isEqual(endA, endB)) {
    const tradeA =
      a.entity === NodeName.ORDER
        ? orderStore.orders.get(a.id)?.tenantTradeVariation?.id || ''
        : '';
    const tradeB =
      b.entity === NodeName.ORDER
        ? orderStore.orders.get(b.id)?.tenantTradeVariation?.id || ''
        : '';
    const tradeAName = projectTradeStore.trades.get(tradeA)?.name || '';
    const tradeBName = projectTradeStore.trades.get(tradeB)?.name || '';

    if (isEqual(tradeA, tradeB)) {
      return a.name.localeCompare(b.name);
    }
    return tradeAName.localeCompare(tradeBName);
  }
  // longer events are on top if they have the same start
  return endA > endB ? -1 : 1;
};

export const sortOverlappingEvents = (a: SchedulerEvent, b: SchedulerEvent): number => {
  const startA = a.startDate;
  const endA = a.endDate;
  const startB = b.startDate;
  const endB = b.endDate;

  const isSummaryEvent = (event: SchedulerEvent) => event.entity === NodeName.SECTION;
  const isBaseplanSummaryEvent = (event: SchedulerEvent) =>
    event.entity === NodeName.SECTION_BASEPLAN;

  if (isBaseplanSummaryEvent(a)) return -1;
  if (isBaseplanSummaryEvent(b)) return 1;

  if (isSummaryEvent(a)) return -1;
  if (isSummaryEvent(b)) return 1;

  const sameStart = isEqual(startA, startB);
  if (sameStart) {
    return compareSameStartEvents(a, b, endA, endB);
  }
  // earlier events are on top
  return startA < startB ? -1 : 1;
};
