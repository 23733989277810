import { defineStore } from 'pinia';

import { NodeName } from '@/repositories/utils/cache';

import { useMilestoneStore } from '../milestones';
import { useOrderDependencyStore } from '../orderDependencies';
import { useOrderStore } from '../orders';
import { useLocalOrderSchedulingEngine } from '../schedule';
import { transformDependenciesToCollisions } from './collisionUtils';
import { Collision, CollisionDetails } from './types';

export const useCollisionStore = defineStore('collision-store', () => {
  const collisions = ref<Map<string, Collision>>(new Map());
  const collisionsList = computed<Collision[]>(() => {
    return Array.from(collisions.value.values());
  });

  const orderStore = useOrderStore();
  const milestoneStore = useMilestoneStore();
  const orderDependencyStore = useOrderDependencyStore();

  watch(
    [
      () => orderDependencyStore.dependencies.values(),
      () => orderStore.orders.values(),
      () => milestoneStore.milestones.values(),
    ],
    ([orderDependencyMap]) => {
      const orderDependencies = Array.from(orderDependencyMap);
      collisions.value = new Map(
        transformDependenciesToCollisions(orderDependencies, {
          orderStore: () => orderStore,
          milestoneStore: () => milestoneStore,
          orderDependencyStore: () => orderDependencyStore,
        }).map((collision) => [collision.dependencyId, collision]),
      );
    },
    { immediate: true },
  );

  const getCollisionDetails = (collisionId: string): CollisionDetails => {
    const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
    const constraintDate =
      localOrderSchedulingEngine.utils.getDependencyConstraintDate(collisionId);
    const violationAmountInMinutes =
      localOrderSchedulingEngine.utils.getDependencyConstraintViolation(collisionId);

    const isCritical = collisions.value.get(collisionId)?.toType === NodeName.MILESTONE;

    return {
      constraintDate,
      isCritical,
      violationAmountInMinutes,
    };
  };

  return {
    collisions,
    collisionsList,
    getCollisionDetails,
  };
});

export type CollisionStore = ReturnType<typeof useCollisionStore>;
