/**
 * Returns absolute position in window of relative element
 * See: https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
 */
export function getAbsolutePosition(elem: HTMLElement): { x: number; y: number } {
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { x: Math.round(left), y: Math.round(top) };
}
