import { defineStore } from 'pinia';

import {
  Entity,
  InteractiveEntityChanges,
  MilestoneEntity,
  OrderEntity,
  PartialEntity,
  WbsSectionEntity,
  WrapRef,
} from '@/common/types';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { sectionsQuery } from '@/features/projectStructure/sectionGql';
import { ProjectChangeEventContext } from '@/features/realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '@/features/realTimeCollaboration/utils';
import { UndoRedoCommit } from '@/features/undoRedo';
import { OrderedTree } from '@/helpers/utils/orderedTree';
import { useApolloClient } from '@/plugins/apollo';

import { WbsSectionStore } from '../types';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoIndentCommit,
  createUndoRedoOutdentCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUndoOutdentCommit,
  createUndoRedoUpdateCommit,
} from './sectionCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeIndentEvent,
  createProjectChangeOutdentEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
} from './sectionEvents';

export const useWbsSectionStore = defineStore(
  'section-store',
  (): WrapRef<
    WbsSectionStore,
    'wbsSections' | 'wbsSectionsList' | 'fetchAllPromise' | 'isInitialized'
  > => {
    const wbsSections = ref(new Map<string, WbsSectionEntity>());
    const wbsSectionsList = computed(() => Array.from(wbsSections.value.values()));
    const isInitialized = ref(false);
    const deletedWbsSections = ref(
      new Map<
        string,
        {
          wbsSection: WbsSectionEntity;
          relatedSections: string[];
          orders: string[];
          milestones: string[];
        }
      >(),
    );

    let cachedOrderedWbsSectionsTree: OrderedTree<WbsSectionEntity> | null = null;

    const setWbsSections = (sections: Map<string, WbsSectionEntity>) => {
      wbsSections.value = sections;
      cachedOrderedWbsSectionsTree = null;
    };

    const getOrderedWbsSectionsTree = () => {
      if (cachedOrderedWbsSectionsTree === null) {
        cachedOrderedWbsSectionsTree = new OrderedTree<WbsSectionEntity>(wbsSectionsList.value);
      }
      return cachedOrderedWbsSectionsTree;
    };

    const create = async (
      vars: WbsSectionEntity[],
      root: WbsSectionEntity | null,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      if (createCommit && root === null) throw new Error('Root section is required for undo redo');
      const event = createProjectChangeCreateEvent(vars, context);
      const commit = createUndoRedoCreateCommit(vars, root);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const update = async (
      partialWbsSection: [PartialEntity<WbsSectionEntity>],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeUpdateEvent(partialWbsSection[0], context);
      const commit = createUndoRedoUpdateCommit(partialWbsSection, wbsSections.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const remove = async (
      vars: WbsSectionEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const restore = async (
      vars: WbsSectionEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const sectionsToRestore = vars.flatMap(
        (sectionToRestore) =>
          getSoftDeletedEntities(sectionToRestore.id)?.map(({ id }) => id) ?? [],
      );
      const dedupedSectionsToRestore = Array.from(new Set(sectionsToRestore));
      const ordersToRestore = dedupedSectionsToRestore.flatMap(
        (id) => deletedWbsSections.value.get(id)?.orders ?? [],
      );
      const milestonesToRestore = dedupedSectionsToRestore.flatMap(
        (id) => deletedWbsSections.value.get(id)?.milestones ?? [],
      );

      const event = createProjectChangeRestoreEvent(
        vars,
        {
          orders: ordersToRestore,
          milestones: milestonesToRestore,
        },
        context,
      );
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const indent = async (
      vars: WbsSectionEntity,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ) => {
      const { event, newSectionId } = createProjectChangeIndentEvent(vars, context);
      const commit = createUndoRedoIndentCommit(vars, newSectionId);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const outdent = async (
      vars: WbsSectionEntity,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ) => {
      const event = createProjectChangeOutdentEvent(vars, context);
      const commit = createUndoRedoOutdentCommit(vars, wbsSections.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const undoOutdent = async (
      originallyOutdentedSection: WbsSectionEntity,
      sectionsToRecreate: WbsSectionEntity[],
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const deletedSectionIds = new Set(sectionsToRecreate.map(({ id }) => id));

      const milestonesToRestore = Array.from(deletedSectionIds).flatMap(
        (id) => deletedWbsSections.value.get(id)?.milestones ?? [],
      );

      const event = createProjectChangeCreateEvent(
        sectionsToRecreate,
        context,
        milestonesToRestore,
      );
      const commit = createUndoRedoUndoOutdentCommit(
        originallyOutdentedSection,
        sectionsToRecreate,
      );
      return pushLocalChangeEventAndCommit(event, commit, false);
    };

    const setState = (
      state?: Map<string, WbsSectionEntity>,
      oldOrderState?: Map<string, OrderEntity>,
      oldMilestoneState?: Map<string, MilestoneEntity>,
    ) => {
      if (!state) return;

      cachedOrderedWbsSectionsTree = null;

      state.forEach((wbsSection) => {
        const existingDeletedSection = deletedWbsSections.value.get(wbsSection.id);
        if (existingDeletedSection) {
          deletedWbsSections.value.set(wbsSection.id, { ...existingDeletedSection, wbsSection });
        }
      });
      const deletedSectionBatch: WbsSectionEntity[] = [];
      wbsSections.value.forEach((wbsSection) => {
        if (!state.has(wbsSection.id)) deletedSectionBatch.push(wbsSection);
      });

      setSoftDeletedSectionsWithRelatedEntities(
        deletedSectionBatch,
        oldOrderState,
        oldMilestoneState,
      );

      setWbsSections(new Map(state));
    };

    const fetchAllPromise: Ref<Promise<WbsSectionEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<WbsSectionEntity[]> => {
      const client = useApolloClient();

      const result = await client.query({
        query: sectionsQuery,
        variables: {
          project: projectId,
        },
        fetchPolicy: 'no-cache',
      });

      const entities: WbsSectionEntity[] =
        result.data.wbsSections?.map((wbsSection) => ({
          id: wbsSection.id,
          name: wbsSection.name,
          position: wbsSection.position,
          parentId: wbsSection.parent?.id ?? null,
        })) ?? [];

      setWbsSections(new Map(entities.map((section) => [section.id, section])));
      // set initialize value after entities are set to prevent access to empty data
      isInitialized.value = true;
      return entities;
    };

    const reset = () => {
      setWbsSections(new Map());
      isInitialized.value = false;
      fetchAllPromise.value = null;
    };

    const applyChanges = (
      changes: {
        add?: WbsSectionEntity[];
        update?: PartialEntity<WbsSectionEntity>[];
        delete?: Entity[];
      },
      oldOrderState?: Map<string, OrderEntity>,
      oldMilestoneState?: Map<string, MilestoneEntity>,
    ): void => {
      cachedOrderedWbsSectionsTree = null;
      if (changes.add) {
        changes.add.forEach((section) => wbsSections.value.set(section.id, section));
      }

      if (changes.update) {
        changes.update.forEach((partialSection) => {
          const originalSection = wbsSections.value.get(partialSection.id);

          if (originalSection) {
            wbsSections.value.set(partialSection.id, {
              ...originalSection,
              ...partialSection,
            });
          }

          const deletedSection = deletedWbsSections.value.get(partialSection.id);
          if (deletedSection) {
            deletedWbsSections.value.set(partialSection.id, {
              ...deletedSection,
              wbsSection: {
                ...deletedSection.wbsSection,
                ...partialSection,
              },
            });
          }
        });
      }

      if (changes.delete) {
        const deletedSectionsBatch: WbsSectionEntity[] =
          (changes.delete
            ?.map(({ id }) => wbsSections.value.get(id))
            .filter(Boolean) as WbsSectionEntity[]) ?? [];
        setSoftDeletedSectionsWithRelatedEntities(
          deletedSectionsBatch,
          oldOrderState,
          oldMilestoneState,
        );
      }
    };

    const copyState = () => {
      return new Map(wbsSections.value);
    };

    const getSoftDeletedEntities = (id: string): WbsSectionEntity[] | undefined => {
      return deletedWbsSections.value
        .get(id)
        ?.relatedSections.map((sectionId) => deletedWbsSections.value.get(sectionId)?.wbsSection)
        .filter(Boolean) as WbsSectionEntity[] | undefined;
    };

    const setSoftDeletedSectionsWithRelatedEntities = (
      toBeDeletedSections: WbsSectionEntity[],
      oldOrderState: Map<string, OrderEntity> = new Map(),
      oldMilestoneState: Map<string, MilestoneEntity> = new Map(),
    ) => {
      const orderStore = useOrderStore();
      const milestoneStore = useMilestoneStore();

      toBeDeletedSections.forEach((wbsSection) => {
        const orders = Array.from(oldOrderState.values())
          .filter((order) => order.wbsSection.id === wbsSection.id)
          .map(({ id }) => id);
        orders.forEach((orderId) => orderStore.setSoftDeletedEntity(orderId));

        const milestones = Array.from(oldMilestoneState.values())
          .filter((milestone) => milestone.wbsSection?.id === wbsSection.id)
          .map(({ id }) => id);
        milestones.forEach((milestoneId) => milestoneStore.setSoftDeletedEntity(milestoneId));

        wbsSections.value.delete(wbsSection.id);
        deletedWbsSections.value.set(wbsSection.id, {
          wbsSection,
          relatedSections: toBeDeletedSections.map((section) => section.id),
          orders,
          milestones,
        });
      });
    };

    return {
      wbsSections,
      wbsSectionsList,
      isInitialized,
      getOrderedWbsSectionsTree,
      create,
      update,
      restore,
      delete: remove,
      indent,
      outdent,
      undoOutdent,
      applyChanges,
      setState,
      copyState,
      fetchAll,
      fetchAllPromise,
      reset,
      getSoftDeletedEntities,
    };
  },
);
